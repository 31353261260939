import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Row, Col, Container, Form } from 'react-bootstrap'
import { Button } from '../components/CustomStyling'
import { Divider } from '../components'
import { PlatformApi } from '../../api'
import { Formik } from 'formik'
import UserContext from '../contexts/userContext'
import { ModalError } from '../components'
import { errors } from '../utils'
import Logo from "../../assets/logo.png";

export default function Signin() {
	// right now it's necessary to set the profile type into localStorage for handling routes
	// TODO: This is something we have to improve and make it more centralized into a single object in localStorage or somewhere else

	const [signinResult, setSigninResult] = useState({})
	function submitSignin(data, setUser) {
		axios.post(`${PlatformApi}/auth/login`, {
			...data
		}).then(async res => {
			if (res.data.status !== 1) {
				// console.log({ ...res, ...errors.CREDENTIALS_ERROR })
				setSigninResult({ ...errors.CREDENTIALS_ERROR })
				return
			}
			// console.log({ ...res, ok: true })
			setSigninResult({ ...res, ok: true })
			localStorage.setItem("api_token", res.data.api_token)
			localStorage.setItem("profile_type", res.data.profile_type)
			setUser({ ...res.data, profile_type: res.data.profile_type })
			window.location.href = `${window.location.protocol}//${window.location.host}/volunteer/organisation/panel/home`
		}).catch(err => {
			setSigninResult({ ...errors.NETWORK_ERROR, ...err })
		})
	}
	return (
		<UserContext.Consumer>
			{({ setUser }) => (
				<React.Fragment>
					
					<Divider height='30px' />
					<Container>
						<Row className="align-items-center justify-content-center">
							<Col sm={12} className={"text-center"}>
							<h2 className="logo-volunteering">Pocketask</h2>
							</Col>
							<Col sm={12} className={"text-center"}>
							<img src={Logo} alt="" className='logo-img-volunteering'/>
							</Col>
							<Col sm={12}>
							<h5 className="sign-in-text-volunteering">
								Volunteer organisations - we connect you with local high school volunteers and help you track and verify their hours
							</h5>
							<h5 className="sign-in-text-volunteering">
								School supervisors - we streamline the process for you to track and verify their volunteer hours
							</h5>
							</Col>
							<Col xs lg="5">
								<OrganizationForm submitSignin={submitSignin} setUser={setUser} signinResult={signinResult} />
							</Col>
						</Row>
					</Container>
				</React.Fragment>
			)}
		</UserContext.Consumer>
	)

}


// components 
function OrganizationForm({ submitSignin, setUser, signinResult = { ok: true, code: 3000 } }) {
	return (
		<React.Fragment>
			<Divider height='50px' />
			<h3>Sign in</h3>
			<Formik
				initialValues={{ username: '', password: '', timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }}
				onSubmit={(values, { setSubmitting }) => {
					setTimeout(async () => {
						try {
							setSubmitting(false);
							submitSignin({ ...values }, setUser)
						} catch (err) {
							console.log("Error: ", err)
						}
					}, 400);
				}}
			>
				{({
					values,
					handleChange,
					handleSubmit,
				}) => (
					<React.Fragment>
						<Divider height='10px' />
						{signinResult.code === 3001 && <ModalError message={signinResult.message} />}
						{signinResult.code === 3002 && <ModalError message={signinResult.message} />}
						<Form onSubmit={handleSubmit}>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Username or email</Form.Label>
								<Form.Control onChange={handleChange} name="username" value={values.username} type="text" placeholder="Username" />
							</Form.Group>
							<Form.Group className="mb-3" controlId="formBasicEmail">
								<Form.Label>Password</Form.Label>
								<Form.Control onChange={handleChange} name='password' value={values.password} type="password" placeholder="Password" />
							</Form.Group>
							<Form.Text className="text-muted">
								Haven't signed up yet? <Link to="/volunteer/signup">Sign up here</Link>
							</Form.Text>
							<div>
								<Form.Text className="text-muted">
									<Link to="/volunteer/forgot-password">Forgot Password</Link>
								</Form.Text>
							</div>
							<Divider height='30px' />
							<Button variant="primary" type="submit">
								Sign in
							</Button>
						</Form>
					</React.Fragment>
				)}
			</Formik>
		</React.Fragment>
	)
}
