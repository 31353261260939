import React from 'react'
import styled from 'styled-components'
import { Modal as BootstrapModal, Container, Button } from 'react-bootstrap'

const StyledModal = styled(BootstrapModal)`
    & .modal-content{
        height: 80vh;
    }
`
export const Modal = ({ show = false, title = '', children, onHide, buttons = [] }) => {
    return (
        <StyledModal
            onEscapeKeyDown={onHide}
            scrollable
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            {title && (
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title>{title}</BootstrapModal.Title>
                </BootstrapModal.Header>
            )}
            <BootstrapModal.Body>
                <Container>
                    {children}
                </Container>
            </BootstrapModal.Body>
            {!!buttons.length && (
                <BootstrapModal.Footer>
                    {buttons.map((button, idx) => <Button key={`edit-modal-button-${idx}`} variant={button.variant} onClick={button.onClick}>{button.text}</Button>)}
                </BootstrapModal.Footer>
            )}
        </StyledModal>
    );
}