import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Switch, useRouteMatch, Route } from 'react-router'

import Home from './Home'
import Notifications from './Notifications'
import Profile from './Profile'
import Tasks from './Tasks'

/**
 * Supervisor Router
 */
export default function PanelRouterSupervisor({user, ...props}) {
    let { path } = useRouteMatch()
    return (
        <Row>
            <Col>
                <Switch>
                    <Route path={`${path}/home`}>
                        <Home {...props} />
                    </Route>
                    <Route path={`${path}/tasks`}>
                        <Tasks {...props} />
                    </Route>
                    <Route path={`${path}/profile`}>
                        <Profile {...props} />
                    </Route>
                    <Route path={`${path}/notifications`}>
                        <Notifications {...props} />
                    </Route>
                </Switch>
            </Col>
        </Row>
    )
}
