import React, { useContext } from 'react'
import { ColCustomed, CustomDiv, NavLink } from '../../components/CustomStyling'
import { Row, Container, Nav, NavDropdown, Col } from 'react-bootstrap'
import { Divider, SideNav } from '../../components'
import Logo from '../../../assets/logo.png'
//contexts
import UserContext from '../../contexts/userContext'


/**
 * Main Layout component that includes both header and sidebar
 * @param {*} children 
 * @returns Wrapper component that includes both header and sidebar
 */
export default function Layout({ children }) {
    return (
        <Row>
            <ColCustomed md={2}>
                <CustomDiv width='30%' margin='20px auto 10px auto'>
                    <img src={Logo} alt='logo' />
                </CustomDiv>
                <Divider height='20px' />
                <SideNav variant="ORGANISATION" />
            </ColCustomed>
            <ColCustomed md={10} background='white' padding='0'>
                <HeaderPanel />
                <Container>
                    <Divider height='20px' />
                    {children}
                </Container>
            </ColCustomed>
        </Row>
    )
}

// components



function HeaderPanel() {
    const { user } = useContext(UserContext)
    return (
        <CustomDiv width='100%' textAlign='left' background='#f0f0f0' padding='10px 0' fontSize='.9rem'>

            <Container>
                <Row>
                    <ColCustomed>
                        <Nav
                            className='justify-content-center'
                            activeKey="/home"
                            onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
                        >
                            <Col>
                                <CustomDiv width='100%' margin='auto' background='#f0f0f0'>
                                    <Nav.Item>
                                        <NavLink background='transparent' padding='0.5rem 0'>
                                            Welcome, {user.firstName}
                                            <div>
                                                <span>Organisation</span>
                                            </div>
                                        </NavLink>
                                    </Nav.Item>
                                </CustomDiv>
                            </Col>
                            <NavDropdown title={`${user.firstName || ''} ${user.lastName || ''}`} id="nav-dropdown">
                                <NavDropdown.Item onClick={() => {
                                    localStorage.setItem("api_token", "")
                                    window.location.href = '/volunteer/signin'
                                }}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </ColCustomed>
                </Row>
            </Container>
        </CustomDiv>
    )
}

