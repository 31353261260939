import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Fragment } from 'react';
import './App.scss';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from "./components/ScrollToTop";
//pages
import About from './pages/About';
import Account from './pages/Account';
import AppPage from './pages/AppPage';
import Contact from './pages/Contact';
import Create from './pages/Create';
import Home from './pages/Home';
import Login from './pages/Login';
import Privacy from './pages/Privacy';
import ForgotPassword from './pages/ForgotPassword'
import EmailSent from './pages/EmailSent'
import Activate from './pages/Activate'
import Signup from './pages/Signup';
import FAQ from './pages/FAQ';
import Babysitting from './pages/tasks/Babysitting';
import Dogwalking from './pages/tasks/Dogwalking';
import Petsitting from './pages/tasks/Petsitting';
import Pickup from './pages/tasks/Pickup';
import Tutoring from './pages/tasks/Tutoring';
import YardWork from './pages/tasks/YardWork';
import TaskTemplate from './pages/TaskTemplate';
import ResetPassword from './pages/ResetPassword';
import NotFoundPage from './pages/NotFoundPage';
import Admin from './pages/Admin/Admin';
import AdminMail from './pages/Admin/Mail';
import TermsAndConditions from './pages/TermsAndConditions'
import TermsAndConditionsDoc from './pages/TermsAndConditionsDoc';
import Safety from './pages/Safety'
import Volunteer from './volunteer'


function App() {

  return (
    <Router>
      <Fragment>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            {/* <Header /> */}
            <Home />
            {/* <Footer /> */}
          </Route>
          <Route path="/volunteer">
            {/* <Header /> */}
            <Volunteer/>
            {/* <Footer /> */}
          </Route>
          <Route exact path="/about">
            <Header />
            <About />
            <Footer />
          </Route>
          <Route exact path="/app">
            <Header />
            <AppPage />
            <Footer />
          </Route>
          <Route exact path="/account">
            <div className="everything">
              <Header />
              <Account />
              <Footer />
            </div>
          </Route>
          <Route exact path="/login">
            <div className="everything">
              <Header />
              <Login />
              <Footer />
            </div>
          </Route>
          <Route exact path="/privacy">
            <Header />
            <Privacy />
            <Footer />
          </Route>
          <Route exact path="/faq">
            <div className="everything">
              <Header />
              <FAQ />
              <Footer />
            </div>
          </Route>
          <Route exact path="/forgot-password">
            <div className="everything fp-container">
              <Header />
              <ForgotPassword />
              <Footer />
            </div>
          </Route>
          <Route exact path="/email-sent">
            <div className="everything fp-container">
              <Header />
              <EmailSent />
              <Footer />
            </div>
          </Route>
          <Route exact path="/activate/:token" component={Activate} />
          <Route exact path="/resetpassword/:resetlink" component={ResetPassword} />
          <Route exact path="/signup">
            <div className="everything">
              <Header />
              <Signup />
              <Footer />
            </div>
          </Route>
          <Route exact path="/create">
            <Header />
            <Create />
            <Footer />
          </Route>
          <Route exact path="/contact">
            <div className="everything">
              <Header />
              <Contact />
              <Footer />
            </div>
          </Route>
          <Route exact path="/create/babysitting">
            <Header />
            <Babysitting />
            <Footer />
          </Route>
          <Route exact path="/create/tutoring">
            <Header />
            <Tutoring />
            <Footer />
          </Route>
          <Route exact path="/create/pickup">
            <Header />
            <Pickup />
            <Footer />
          </Route>
          <Route exact path="/create/yardwork">
            <Header />
            <YardWork />
            <Footer />
          </Route>
          <Route exact path="/create/dogwalking">
            <Header />
            <Dogwalking />
            <Footer />
          </Route>
          <Route exact path="/create/Petsitting">
            <Header />
            <Petsitting />
            <Footer />
          </Route>
          <Route exact path="/admin">
            <div className="everything">
              <Header />
              <Admin />
              <Footer />
            </div>
          </Route>
          <Route exact path="/admin/mail">
            <div className="everything">
              <Header />
              <AdminMail />
              <Footer />
            </div>
          </Route>
          <Route path="/t/:taskId" component={TaskTemplate} />
          <Route path="/terms-and-conditions" >
            <div className='everything'>
              <Header/>
              <TermsAndConditions />
              <Footer />
            </div>
          </Route>
          <Route path="/terms-and-conditions-doc" >
            <div className='everything'>
              <Header/>
              <TermsAndConditionsDoc />
              <Footer />
            </div>
          </Route>
          <Route path="/safety">
            <div className='everything'>
              <Header/>
              <Safety />
              <Footer />
            </div>
          </Route>
          {/* <Route exact path="/:page" component={Home} /> */}
          <Route>
            <div className="everything">
              <Header />
              <NotFoundPage />
              <Footer />
            </div>
          </Route>
        </Switch>
      </Fragment>
    </Router>
  );
}

export default App;
