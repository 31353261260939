import React, { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { BiTachometer } from "react-icons/bi";

const DogTemp = ({task}) => {
    const [friendly, setFriendly] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [isAbout, setIsAbout] = useState(false);
    console.log(task);

    useEffect(() => {
        // if(task.dogwalking.dogs || task.dogwalking.sizes) {
        //     setIsAbout(true);
        // }
        if(task.dogwalking) {
            setIsAbout(true);
            if(task.dogwalking.friendly){
                setFriendly(checkSetter(task.dogwalking.friendly));
            }
            if(task.dogwalking.sizes){
                setSizes(checkSetter(task.dogwalking.sizes));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkSetter = (value) => {
        let checkValue = [];
        for (const key in value) {
            if(value[key]){
                checkValue.push(key);
            }
        }
        return checkValue;
    }

    const tConvert = (time) => {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
          time = time.slice (1);
          time[5] = +time[0] < 12 ? 'am' : 'pm';
          time[0] = +time[0] % 12 || 12;
        }
        return time.join ('');
    }

    const defFriendly = (t) => {
        if(t === 'kids'){
            return 'kids';
        } else if(t === 'strangers'){
            return 'strangers';
        } else if(t === 'other'){
            return 'other dogs';
        } else if(t === 'not'){
            return 'not friendly';
        }
    }
    const defSizes = (t) => {
        if(t === 'small'){
            return 'small';
        } else if(t === 'medium'){
            return 'medium';
        } else if(t === 'large'){
            return 'large';
        }
    }

    return (
        <>
        <div className="task-icon-body">
            <div className="icon-block">
                <AiOutlineClockCircle className="task-icon"/>
                <h4><strong>{task.date}</strong></h4>
                <h5><strong>{tConvert(task.startTime)}</strong> to <strong>{tConvert(task.endTime)}</strong></h5>
            </div>
            {task.location && (
                <div className="icon-block">
                    <FiMapPin className="task-icon"/>
                    <h4><strong>{task.location}</strong></h4>
                </div>
            )}
            {task.rate && (
                <div className="icon-block">
                    <BiTachometer className="task-icon"/>
                    <h4><strong>${task.rate}</strong></h4>
                    <h5>{task.rateType === 'hourly' ? 'per hour': 'flat rate'}</h5>
                </div>
            )}
            {isAbout && (
                <span>
                    {task.dogwalking.dogs && (
                    [
                        (task.dogwalking.ages ?
                            <div className="icon-block about-item top-padding">
                                <div>
                                <h4><span>I have </span><strong>{task.dogwalking.dogs}</strong><span> dogs,</span></h4>
                                <h4><span>ages </span><strong>{task.dogwalking.ages}</strong></h4>
                                </div>
                            </div>
                        :
                            <div className="icon-block about-item top-padding">
                                <div>
                                <h4>I have <strong>{task.dogwalking.dogs} dogs</strong></h4>
                                </div>
                            </div>
                        ),
                    ]
                )}
                {task.dogwalking.sizes && (
                    <div className="icon-block about-item top-padding">
                        <div>
                        <h4><strong>Sizes: </strong></h4>
                        <h5>{sizes.map((r, index) => (index + 1 === sizes.length) ? defSizes(r) : defSizes(r) + ", " )}</h5>  
                        </div>          
                    </div>
                )}
                {task.dogwalking.friendly && (
                    <div className="icon-block about-item top-padding">
                        <div>        
                        <h4><strong>Dogs are friendly with: </strong></h4>
                        <h5>{friendly.map((r, index) => (index + 1 === friendly.length) ? defFriendly(r) : defFriendly(r) + ", " )}</h5>
                        </div>
                    </div>
                )}

                </span>

            )}
                {task.additionalInfo && (
                <div className="icon-block about-item top-padding">
                    <div>
                    <h4><strong>Additional Info: </strong></h4>
                    <h5><strong>{task.additionalInfo}</strong></h5>
                    </div>
                </div>
                )}
        </div>
        {/*
        {isAbout && (
            <div>
            <div className="task-about">
                {task.dogwalking.dogs && (
                    [
                        (task.dogwalking.ages ?
                            <div className="about-item">
                                <h4><span>I have </span><strong>{task.dogwalking.dogs}</strong><span> dogs,</span></h4>
                                <h4><span>ages </span><strong>{task.dogwalking.ages}</strong></h4>
                            </div>
                        :
                            <div className="about-item">
                                <h4>I have <strong>{task.dogwalking.dogs} dogs</strong></h4>
                            </div>
                        ),
                    ]
                )}
                {task.dogwalking.sizes && (
                    <div className="about-item">
                        <h4><strong>Sizes: </strong></h4>
                        <h5>{sizes.map((r, index) => (index + 1 === sizes.length) ? defSizes(r) : defSizes(r) + ", " )}</h5>            
                    </div>
                )}
                {task.dogwalking.friendly && (
                    <div className="about-item">             
                        <h4><strong>Dogs are friendly with: </strong></h4>
                        <h5>{friendly.map((r, index) => (index + 1 === friendly.length) ? defFriendly(r) : defFriendly(r) + ", " )}</h5>
                    </div>
                )}
            </div>
        </div>
        )}
        */}
        </>
    )
}

export default DogTemp
