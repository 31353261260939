import React, { useState } from 'react'
import axios from 'axios'
import { Formik } from 'formik';
import { Link, useRouteMatch } from 'react-router-dom'
import { Row, Col, Container, Nav, Form } from 'react-bootstrap'
import { Button, NavLink } from '../components/CustomStyling'
import { Divider } from '../components'
import { PlatformApi } from '../../api'
import { setToken } from '../utils'
// import ReCAPTCHA from 'react-google-recaptcha';
import Reaptcha from 'reaptcha';

export default function Signup() {
    let { path } = useRouteMatch()
    const [currentTab, setCurrentTab] = useState()
    const [recaptchaCode, setRecaptchaCode] = useState()
    function verifyCaptcha(recaptchaResponse){
        setRecaptchaCode(recaptchaResponse)
    }
    function onSelect(selectedTab) {
        setCurrentTab(selectedTab)
    }

    function submitSignup(profileType, values) {
        const profileTypeParam = profileType === 2 ? "organization" : "supervisor"

        axios.post(`${PlatformApi}/auth/volunteering/register/${profileTypeParam}`, {
            ...values,
            captcha: recaptchaCode
        }).then(res => {
            if (res.data.status === 1) {
                setToken(res.data.api_token)
                localStorage.setItem('profile_type', profileType)
                window.location.href = `/volunteer/confirm`
            } else {
                alert(res.data.message)
            }
        })
            .catch(err => {
            })
    }

    return (
        <React.Fragment>
            <Divider height='30px' />
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="5">
                        <Nav fill variant="pills" defaultActiveKey="ORGANIZATION" onSelect={onSelect}>
                            <Nav.Item>
                                <NavLink eventKey="ORGANIZATION">Volunteer organization</NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="SUPERVISOR">School supervisor</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="5">
                        <TabSelector currentTab={currentTab} path={path} verifyCaptcha={verifyCaptcha} submitSignup={submitSignup} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}


function TabSelector({ currentTab, submitSignup, path, verifyCaptcha, ...otherProps }) {
    switch (currentTab) {
        case "ORGANIZATION":
            return <OrganizationForm submitSignup={submitSignup} verifyCaptcha={verifyCaptcha} {...otherProps} />
        case "SUPERVISOR":
            return <SupervisorForm submitSignup={submitSignup} verifyCaptcha={verifyCaptcha} {...otherProps} />
        default:
            return <OrganizationForm submitSignup={submitSignup} verifyCaptcha={verifyCaptcha} {...otherProps} />
    }
}
// components 
function OrganizationForm({ submitSignup, verifyCaptcha, values }) {
    return (
        <div>
            <Formik
                initialValues={{ name: '', firstName: '', lastName: '', email: '', website: '', phone: '', address: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(async () => {
                        try {
                            submitSignup(2, values)
                            setSubmitting(false);
                        } catch (err) {
                            alert("There was an error signing up, please check your internet conection")
                        }
                    }, 400);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    /* and other goodies */
                }) => (
                    <React.Fragment>
                        <Divider height='50px' />
                        <h3>Sign up as an Organization</h3>
                        <Divider height='10px' />
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Organisation Name</Form.Label>
                                        <Form.Control onChange={handleChange} name="name" value={values.name} type="text" placeholder="Organisation Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Representative First Name</Form.Label>
                                        <Form.Control onChange={handleChange} name="firstName" value={values.firstName} type="text" placeholder="First Name" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Representative Last name</Form.Label>
                                        <Form.Control onChange={handleChange} name="lastName" value={values.lastName} type="text" placeholder="Last Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control onChange={handleChange} name='email' value={values.email} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Website</Form.Label>
                                        <Form.Control onChange={handleChange} value={values.website} name='website' type="text" placeholder="Website" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control onChange={handleChange} value={values.phone} name='phone' type="phone" placeholder="Phone" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Address</Form.Label>
                                <Form.Control onChange={handleChange} value={values.address} name='address' type="text" placeholder="Address" />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control onChange={handleChange} name='password' value={values.password} type="password" placeholder="Password" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control onChange={handleChange} type="password" placeholder="Password" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Text className="text-muted">
                                Already signed up? <Link to="/volunteer/signin">Sign in here</Link>
                            </Form.Text>
                            <Divider height='30px' />
                            <Reaptcha sitekey="6LdJIkYdAAAAABeunly2QiTFlimK7hqJi_TCz5P0" onVerify={verifyCaptcha} />
                            <Button variant="primary" type="submit">
                                Sign up
                            </Button>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </div>
    )
}

function SupervisorForm({ submitSignup, verifyCaptcha, path }) {
    return (
        <div>
            <Formik
                initialValues={{ firstName: '', lastName: '', email: '', name: '', password: '', validatePassword: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(async () => {
                        try {
                            const valuesToSend = {
                                firstName: values.firstName, lastName: values.lastName, email: values.email, name: values.name, password: values.password
                            }
                            submitSignup(3, valuesToSend)
                            setSubmitting(false);
                        } catch (err) {
                            alert("There was an error signing up, please check your internet conection")
                        }
                    }, 400);
                }}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    /* and other goodies */
                }) => (
                    <React.Fragment>
                        <Divider height='50px' />
                        <h3>Sign up as a supervisor</h3>
                        <Divider height='10px' />
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control onChange={handleChange} name="firstName" value={values.firstName} type="text" placeholder="First Name" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control onChange={handleChange} name="lastName" value={values.lastName} type="text" placeholder="Last Name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control onChange={handleChange} name='email' value={values.email} type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>School Name</Form.Label>
                                        <Form.Control onChange={handleChange} value={values.name} name='name' type="text" placeholder="name" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type='password' onChange={handleChange} value={values.password} name='password' placeholder="password" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Confirm password</Form.Label>
                                        <Form.Control type='password' onChange={handleChange} value={values.validatePassword} name='validatePassword' placeholder="validatePassword" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Text className="text-muted">
                                Already signed up? <Link to="/volunteer/signin">Sign in here</Link>
                            </Form.Text>
                            <Divider height='30px' />
                            <Reaptcha sitekey="6LdJIkYdAAAAABeunly2QiTFlimK7hqJi_TCz5P0" onVerify={verifyCaptcha} />
                            <Button variant="primary" type="submit">
                                Sign up
                            </Button>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </div>
    )
}
