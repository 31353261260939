import React, { useEffect, useState } from 'react'
import { Row, Col, Nav, Button, Container, Table } from 'react-bootstrap'
import { Divider } from '.'
import { CustomDiv, NavLink } from './CustomStyling'
import { useRouteMatch } from 'react-router'
import axios from 'axios'
import { PlatformApi } from '../../api'

export default function TasksTabSupervisor() {
    const [currentPage, setCurrentPage] = useState(0)
    const [tasks, setTasks] = useState([])
    let { path } = useRouteMatch()
    const [currentTab, setCurrentTab] = useState()
    function onSelect(selectedTab) {
        setCurrentTab(selectedTab)
    }

    const fetchTasks = async () => {
        try {

            const requestTaskers = await axios.post(`${PlatformApi}/tasks/supervisor_taskers`, {
                pageNumber: 0
            }, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })

            setTasks(current => {
                return current.concat(requestTaskers.data.data)
            })
            return { requestTaskers }
        } catch (err) {
            console.log("Error: ", err)
        }
    }

    useEffect(() => {
        fetchTasks()
    }, [currentPage])
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h5 style={{ padding: '.5rem' }}>Your students</h5>
                </Col>
            </Row>
            <Divider background='#f0f0f0' height='1px' />
            <Divider height='30px' />
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Nav fill variant="pills" defaultActiveKey="STUDENTS" onSelect={onSelect}>
                        <Nav.Item>
                            <NavLink eventKey="STUDENTS">Students</NavLink>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Divider height='40px' />
                    <TabSelector currentTab={currentTab} path={path} tasks={tasks} setCurrentPage={setCurrentPage} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

function TabSelector({ currentTab, tasks = [], setCurrentPage, ...otherProps }) {
    switch (currentTab) {
        default:
            return <Students tasks={tasks} setCurrentPage={setCurrentPage} />
    }
}



function Students({ tasks = [], setCurrentPage }) {
    return (
        <Container>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Student name</th>
                        <th>Age</th>
                        <th>Total volunteer hours</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks.map((item) => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.age}</td>
                                <td>{item.hoursTotal}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <CustomDiv width='100%'>
                <Button onClick={() => setCurrentPage(current => current + 1)}>Load More</Button>
            </CustomDiv>
        </Container>
    )
}