import React, { useCallback, useEffect, useState } from 'react'
import {Card, Button, Nav, Row, Col, Table} from 'react-bootstrap'
import { isLoggedIn } from '../../utils'
import axios from 'axios'
import Layout from './Layout'
import { PlatformApi } from '../../../api'
import { ZoomedInTask } from '../../components'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

//icons
import { Divider, Flex, OrganisationTabs } from '../../components'
import styled from 'styled-components'
import {confirmAlert} from "react-confirm-alert";
import {CustomDiv} from "../../components/CustomStyling";


/**
 * Home -> Organisation main view with tasks waiting for acceptance feed
 */
export default function Home({ user, setUser }) {
    const [currentPage, setCurrentPage] = useState(0)
    const [showCreateTaskModal, setShowCreateTaskModal] = useState(false)
    const [showZoomedInTask, setShowZoomedInTask] = useState(false)
    const [currentTaskId, setCurrentTaskId] = useState("")
    const LoggedIn = useCallback(() => isLoggedIn(setUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])


    const [tasks, setTasks] = useState([])
    const fetchTasks = async () => {
        try {
            // filters only tasks that have not yet been accepted by a tasker
            const requestValues = {
                "pageNumber": currentPage,
                "search": "",
                "status": [0,1],
                "filters": {
                    taskTypes: [7, 0],
                    notEnded: true
                },
                "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            const request = await axios.post(`${PlatformApi}/task/my_tasks`, { ...requestValues }, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })
            setTasks(current => {
                return current.concat(request.data.data)
            })
            return request.data.data
        } catch (err) {
            console.log("Error: ", err)
        }
    }

    // delete task function
    const deleteTask = async (IdTask) => {
        confirmAlert({
            title: 'Are you sure to do this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const requestValues = {
                                "taskId": IdTask
                            }
                            await axios.post(`${PlatformApi}/task/delete`, { ...requestValues }, {
                                headers: {
                                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                                }
                            })
                            window.location.reload()
                        } catch (err) {
                            console.log("Error: ", err)
                        }
                    }
                }
            ]
        })
    }

    useEffect(() => fetchTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [showCreateTaskModal,currentPage])

    return (
        <Layout>
            <Row>
                <Col sm={4}>
                    <h5 style={{ padding: '.5rem' }}>Tasks waiting for acceptance</h5>
                </Col>
                <Col sm={2}>
                    <Nav className="justify-content-end" activeKey="/home">
                        <Button variant="success" onClick={() => setShowCreateTaskModal(true)}>Create a task</Button>
                    </Nav>
                </Col>
            </Row>
            <Divider background='#fff' height='10px' />
            <Divider background='#f0f0f0' height='1px' />
            <Divider background='#fff' height='15px' />
           {/* <Row>
                <Col>
                    <Flex flexWrap='wrap' className='space-around'>
                        {tasks.map((task, idx) => {
                            if (idx >= 10) return null
                            return (
                                <CustomCard
                                    title={task.title}
                                    links={[{
                                            text: 'Edit', onClick: () => {
                                                setCurrentTaskId(task.id)
                                                setShowCreateTaskModal(true)
                                            }},
                                        {text: 'Delete', onClick: () => {
                                                deleteTask(task.id)
                                            }},
                                        {text: 'Details', onClick: () => {
                                                setCurrentTaskId(task.id)
                                                setShowZoomedInTask(true)
                                            }}
                                            
                                    ]}
                                >
                                    <div><span>from <span className='text-muted'>{task.dateStart}</span >to <span className='text-muted'>{task.dateEnd}</span ></span></div>
                                    <div><span><span className='text-muted'>{task.time}</span ></span></div>
                                </CustomCard>
                            )
                        })}
                    </Flex>
                </Col>
            </Row>*/}
            <Table bordered hover>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    tasks.map((item, idx) => {
                        return (
                            <tr>
                                <td>{item.title}</td>
                                <td>{item.dateStart}</td>
                                <td>{item.dateEnd}</td>
                                <td>{item.timeStart}</td>
                                <td>{item.timeEnd}</td>
                                <td>
                                    <Flex className='center space-around'>
                                        <Button variant='success' onClick={() => {
                                            setCurrentTaskId(item.id)
                                            setShowCreateTaskModal(true)
                                        }
                                        }>
                                            Edit
                                        </Button>
                                        <Button variant='success' onClick={() => {
                                            deleteTask(item.id)
                                        }
                                        }>
                                            Delete
                                        </Button>
                                        <Button variant='success' onClick={() => {
                                            setCurrentTaskId(item.id)
                                            setShowZoomedInTask(true)
                                        }
                                        }>
                                            Details
                                        </Button>
                                    </Flex>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
            <CustomDiv width='100%'>
                <Button onClick={() => setCurrentPage(current => current + 1)}>Load More</Button>
            </CustomDiv>
            <OrganisationTabs show={showCreateTaskModal} onHide={() => {
                setShowCreateTaskModal(false);
                setCurrentTaskId("")
            }} taskId ={currentTaskId} />
            <ZoomedInTask show={showZoomedInTask} onHide={() => setShowZoomedInTask(false)} id={currentTaskId} />
        </Layout>
    )
}

// components 

function CustomCard({ title = '', subTitle = '', links = [{ text: '', link: '' }], children }) {
    const CursorDiv = styled.div`
        cursor: pointer;
        display: inline-flex;
        margin-right:12px;
    `
    return (
        <Card style={{ minWidth: '20%', marginRight: '10px', marginTop: '1em' }}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{subTitle}</Card.Subtitle>
                <Card.Text>
                    {children}
                </Card.Text>
                {links.map((item, idx) => (
                    <CursorDiv>
                        <Card.Link key={`card-${idx}`} href={item.link} onClick={item.onClick}>{item.text}</Card.Link>
                    </CursorDiv>
                ))}
            </Card.Body>
        </Card>
    )
}