import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { HiChevronDown } from "react-icons/hi";
import {AiOutlineInstagram, AiFillLinkedin, AiFillFacebook} from "react-icons/ai";

const Privacy = () => {
    return (
        <section className="faq-section section-padding">
            <div>
                <Accordion>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                        How does Pocketask implement safety?
                        <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <p>Our Platform is built upon trust. However, we took further safety measure to make Pocketask as safe as possible:</p>
                            <ul>
                                <li>Teenagers and parents form trust bonds through suggested connection and analysing profile reviews</li>
                                <li>Teenagers provide their parents/guardian phone number for parental consent to start accepting tasks</li>
                                <li>We have a verification process to ensure the phone number provided is indeed their parents/guardian</li>
                            </ul>
                            <p>Please feel free to reach out to us if more clarification is needed!</p>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="5">
                        Can parents monitor teenagers' activities on the app?
                        <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <p>Yes they can. It begins with the sign up process where we require teenagers to have the consent of their parents/guardian in order to perform a task. Every time a teenager accepts a task their parent/guardian receives a notification. The parent in turn confirms whether the teen can perform this task.</p>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="6">
                            How do we vet our users?
                            <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                            <Card.Body>
                                <p>We vet out users in two stages: the universal vetting which is applied to all of our users and the deeper background checks!
                                    <br/><br/><strong>Universal vetting:</strong>
                                    <br/><strong>1.</strong> We run a sexual offender check on all of our users using the API provided by family watch dog (<a href={"https://www.familywatchdog.us/"} rel="noreferrer" target={"_blank"}>https://www.familywatchdog.us</a>).
                                    <br/><strong>2.</strong> We also run an identity verification check on all of our users using the software provided by veriff (<a href={"https://www.veriff.com/"} rel="noreferrer" target={"_blank"}>https://www.veriff.com</a>).


                                <br/><br/>
                                <strong>Deeper background checks:</strong><br/>
                                <strong>1.</strong> We use one of the most reputable providers of background checks in the world called sterling (<a href={"https://www.sterlingcheck.com/"} rel="noreferrer" target={"_blank"}>https://www.sterlingcheck.com</a>).
                                <br/><strong>2.</strong> We incentivize parents to buy this additional background check package for which they will receive a background check badge on their profiles.
                                <br/><br/><strong>These background checks cost $18 dollars and include:</strong>
                                <br/><strong>1.</strong> SSN trace;
                                <br/><strong>2.</strong> County criminal search using the SSN trace;
                                <br/><strong>3.</strong> Enhanced nation wide criminal database search;
                                <br/><strong>4.</strong> DOJ sex offender search.

                                </p>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="8">
                            How does Pocketask keep minors safe?
                            <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="8">
                            <Card.Body>
                                <p>
                                    All minors require parental consent by email to start using the app and accepting tasks.
                                    <br/>For every task a teenager accepts on the app, we notify the parents by text message automatically.
                                    <br/>We provide rating and reviews for each profile to allow minors to make informed decision on who to trust on the app and receive tasks from.
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="9">
                            How Pocketask nurtures a trustworthy community?
                            <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="9">
                            <Card.Body>
                                <p>
                                    Along with vetting and parental consent for minors, we have designed Pocketask to ensure that trust is at the heart of all user activities.
                                    <br/>Using our search connections page adults and teens/students can trust each other by filtering through mutual connections, local community, and school.
                                    <br/><br/><strong>Users can trust each other in two ways:</strong>
                                    <br/><strong>1.</strong> Trust -> someone you have interacted with several times and can make an informed decision of character;
                                    <br/><strong>2.</strong> Acquaintance -> someone you still need to interact with before making an informed decision of character.
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                            When will the app be released?
                            <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <p>Our goal is to release the app on IOS and Android devices in Spring.</p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="3">
                            How can I get involved?
                            <HiChevronDown className="chevron-down" />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <p>
                                    Reach out to us via our social media platforms or just send us an email at team@mypocketask.com.
                                    <ul className="faq-socials">
                                        <li><a href="https://www.instagram.com/pocketask"><AiOutlineInstagram /></a></li>
                                        <li><a href="https://www.facebook.com/mypocketask"><AiFillFacebook /></a></li>
                                        {/*<li><a href="https://linkedin.com"><AiOutlineTwitter /></a></li> */}
                                        <li><a href="https://www.linkedin.com/company/pocketask/"><AiFillLinkedin /></a></li>
                                    </ul>
                                </p>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </section>
    )
}

export default Privacy
