import React from 'react'
import Uch from '../assets/uch.svg'
import Edge from '../assets/edge.png'
import Footer from '../components/Footer';
import Header from '../components/Header';
import Slide2 from '../assets/image4.png'
import Slide1 from '../assets/image5.png'
import QrCodeFromIOS from '../assets/qrcode.svg'
// hooks
import useWindowsDimensions from '../hooks/useWindowDimensions'

//utils
import breakpoints from '../utils/breakpoints'
import { Fragment } from 'react'

const Home = ({ match }) => {
    // if(match) {
    //     const page = match.params.page;
    //     if(page === "facebook" || page === "twitter" || page === "instagram") {

    //     } else {
    //         window.location.href = '/not-found';
    //     }
    // }

    const { width } = useWindowsDimensions()
    return (
        <>
            <Header />
            <section className="hero-area  home-section">
                {/* blue-overlay */}
                <ImgWithGradient className='left' src='https://images.unsplash.com/photo-1619593593542-0fe36df281eb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80' />
                <ImgWithGradient className='right' src='https://images.unsplash.com/photo-1543342384-1f1350e27861?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80' />
                <div className="row">
                    <div className="col-md-6 mt-2">
                        <h1><strong>Teens</strong></h1>
                        <p>Download the app to <span><strong>start making pocket-money</strong></span> within your community</p>
                    </div>
                    <div className="col-md-6 ">
                        <h1><strong>Parents</strong></h1>
                        <p>Download the app to <span><strong>free up your busy schedule</strong></span> by saving time on numerous tasks</p>
                    </div>
                    <div className="col-md-12 justify-content-md-center call-to-action">
                        <div className='divider'></div>
                        {width < breakpoints.md ? <a href="https://apps.apple.com/us/app/pocketask/id1569456001">Download the App</a> : (
                            <div className='flex centered-component center-text'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <img height='150' alt="" src={QrCodeFromIOS} />
                                    </div>
                                    <div className='col-md-12 py-4 '>
                                        <a href="https://apps.apple.com/us/app/pocketask/id1569456001">Download the App</a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </section>
            {
                /**
                <section className="task-feature section-padding home-section">
                <div className="container">
                    <div className="row sec-title home-div">
                        <h2>Create <span>Any Type of Task!</span></h2>
                    </div>
                    <div className="ftask-cont">
                        <div className="ftask-single">
                            <img src={Baby} alt="A job for Babysitting" />
                            <h4>Babysitting</h4>
                        </div>
                        <div className="ftask-single">
                            <img src={Dog} alt="A job for Dogwalking" />
                            <h4>Dogwalking</h4>
                        </div>
                        <div className="ftask-single">
                            <img src={Plant} alt="A job for Yard Word" />
                            <h4>Yard Work</h4>
                        </div>
                        <div className="ftask-single">
                            <img src={Pencil} alt="A job for Tutoring" />
                            <h4>Tutoring</h4>
                        </div>
                        <div className="ftask-single">
                            <img src={Paw} alt="A job for Petsitting" />
                            <h4>Petsitting</h4>
                        </div>
                        <div className="ftask-single">
                            <img src={Car} alt="A job for Errands" />
                            <h4>Errands</h4>
                        </div>
                    </div>
                </div>
            </section> 

             <section className="app-preview section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 my-auto home-app">
                            <h2>Our app is coming soon! <br /> <span>Get it first</span></h2>
                            <ul>
                                <li>Create tasks and get instant responses from teens you know.</li>
                                <li>Sign up now If you are a parent, grandparent, teen or student and receive rewards
                                    when we launch!
                                </li>
                            </ul>
                            <Link className="sign-up-button" to="/app#signup">Pre-register</Link>
                        </div>
                        <div className="col-md-5 text-center">
                            <img src={AppPreview}
                                alt="The Pocketask Mobile App to create jobs, hire trustworthy teenagers, and earn money" />
                        </div>
                    </div>
                </div>
            </section>
                 */
            }
            <section className="app-preview section-padding bgwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 my-auto">
                            <h2><span>Teens - Do a pocketask, make pocket money</span></h2>
                            <ol>
                                <li>Personalize and grow your network of trusted job-posters.</li>
                                <li>Choose from a variety of tasks.</li>
                                <li>Find tasks that work with your schedule.</li>
                                <li>Complete your task stress-free with our live task safety features.</li>
                            </ol>
                            <a href='https://apps.apple.com/us/app/pocketask/id1569456001'>Download the App</a>
                        </div>
                        <div className="col-md-5 text-center">
                            <img src={Slide1} alt="A mobile app for earning pocket money as a teenager" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="app-preview section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 text-center second-img">
                            <img src={Slide2} alt="A mobile app for hiring teenagers you trust" />
                        </div>
                        <div className="col-md-7 my-auto">
                            <h2>Adults - Save time by creating a pocket full of tasks</h2>
                            <ol>
                                <li>Personalize and grow your network of trusted teens.</li>
                                <li>Use our custom templates to centralize all your task details.</li>
                                <li>Get quick responses from teens via our unique trust algorithm.</li>
                                <li>Ensure your task is effectively and safely completed with our live task features.</li>
                            </ol>
                            <a href='https://apps.apple.com/us/app/pocketask/id1569456001'>Download the App</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="partners">
                <h2 className={"mb-3"}>Partners:</h2>
                <div className="images-partners">
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                            <img className="partners-image" src={Uch} alt="University of Chicago" />
                        </div>
                        <div className={"col-12 col-md-6"}>
                            <img className="partners-image larger-image" src={Edge} alt="Edge Launchpad" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Home
// ----- components -------
function ImgWithGradient({ src, className = '' }) {
    return (
        <div className={`col-background ` + className} style={{
            backgroundImage: `url(${src})`
        }}>
        </div>
    )
}