import styled from "styled-components";

import { Button as BootstrapButton, Nav, Col } from 'react-bootstrap'

export const Button = styled(BootstrapButton)`
    ${({ background = '#0d6efd', color = '#fff', borderColor = '#0d6efd' }) => `
        background: ${background};
        color: ${color};
        border-color: ${borderColor}
    `}
`

export const NavLink = styled(Nav.Link)`
    ${({ background = '#fff', activeBackground = 'var(--primary-color)', color = '#0d6efd', activeColor = '#fff', borderColor = '#0d6efd', padding = '' }) => `
        background: ${background};
        color: ${color};
        border-color: ${borderColor};
        padding: ${padding};
        &.active{
            color: ${activeColor} !important;
            background: ${activeBackground} !important;
        }
    `}
`

export const SideNavLink = styled(Nav.Link)`
    ${({ background = '#f0f0f0', activeBackground = 'transparent', color = '#0f0f0f', activeColor = 'var(--primary-color)', borderColor = 'var(--primary-color)' }) => `
        background: ${background};
        color: ${color};
        border-color: ${borderColor};
        width: 100%;
        &:hover{
            color: #575757;
        }
        &.active{
            color: ${activeColor} !important;
            background: ${activeBackground} !important;
            border-right: 4px solid;
        }
    `}
`

export const ColCustomed = styled(Col)`
    ${({ background = '#f0f0f0', padding = '', paddingLeft = '', paddingRight = '0', textAlign = 'left' }) => `
        background: ${background};
        padding: ${padding};
        padding-right: ${paddingRight};
        padding-left: ${paddingLeft};
        text-align: ${textAlign};
    `}
`

export const Flex = styled.div`
    ${({ flexWrap = 'nowrap', width = '' }) => `
        flex-wrap: ${flexWrap};
        width: ${width};
    `}
    &.center{
        margin: auto;
    }
    &.space-between{
        justify-content: space-between;
    }
    &.space-around{
        justify-content: space-around;
    }
    display: flex;
    .tab1{
        tab-size: 1;
    }
`

export const CustomDiv = styled.div`
    ${({ margin = '', height = 'auto', width = '30px', textAlign = 'center', background = '#fff', padding = '', fontSize = '.8rem' }) => (
        `
            margin: ${margin};
            width: ${width};
            height: ${height};
            text-align: ${textAlign};
            background: ${background};
            padding: ${padding};
            font-size: ${fontSize};
        `
    )}
`

export const CustomButton = styled(BootstrapButton)`
    ${({ fontSize = '1rem', background = '' }) => `
        font-size: ${fontSize};
        background: ${background};
    `}
`

// components grid
export const GridContainer = styled.div`
    ${({ columnGap = '0', rowGap = '0' }) => `
        grid-column-gap: ${columnGap};
        grid-row-gap: ${rowGap};
    `}
    display: grid;
    grid-template-columns: 3% 97%;
    grid-template-rows: 50% 50%;
`

export const GridItemFirst = styled.div`
${() => `
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    & .icon{
        margin: auto 10px auto auto;
        width: 17px;
        height: 17px;
    }
`}
`

export const GridItemLast = styled.div`
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 4;
`