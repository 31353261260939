import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { api } from '../api';
import { AiOutlineEye } from "react-icons/ai";
import Spinner from 'react-bootstrap/Spinner'

const adminEmails = ['mgouilliard@mypocketask.com', 'ddomenig@uchicago.edu', 'smostow@me.com','nkabbani@mypocketask.com','aneeshbafna2@gmail.com', 'team@mypocketask.com']

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState('');
    const [isPasswordShown, setPasswordShown] = useState(false);
    
    const login = (e) => {
        e.preventDefault();
        axios.post(api + '/signin', {
            email: email,
            password: password
        })
        .then(function (res) {
            if (adminEmails.includes(email)){
                localStorage.setItem('admin', true);
            }
            localStorage.setItem('login', true);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', res.data.user._id);
            window.location.href = '/account';
        })
        .catch(function (e) {
            if (e.response) {
                setError(e.response.data.error);
            } else {
                setError("Something went wrong");
            }
        });
    }
    const togglePasswordVisibility = () => {
        setPasswordShown(!isPasswordShown)
    }

    if(localStorage.getItem('login')){
        window.location.href = '/account';
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div className="login-section">
            <div className="row sec-title">
                <h2>Login</h2>
            </div>
            <div className="login-area">
                <Form onSubmit={login}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Group className="password-field">
                            <Form.Control type={(isPasswordShown) ? "text" : "password"} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            <button type="button" className="view-password" onClick={togglePasswordVisibility}><AiOutlineEye/></button>
                            <Link className="forgot-password" to="/forgot-password">Forgot Password</Link>
                        </Form.Group>
                    </Form.Group>
                    {error !== "" && (
                        <Alert variant={"danger"}>{error}</Alert>
                    )}
                    <button className="login-submit" type="submit">
                        Login
                    </button>
                    <Form.Group className="signup-link" controlId="formBasicCheckbox">
                        <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default Login
