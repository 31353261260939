import axios from 'axios';
import React, { useState } from 'react';
import { api } from '../../api'

const Admin = () => {
    const [title, setTitle] = useState();
    const [text, setText] = useState();
    const [mail, setMail] = useState();
    const [parents, setParents] = useState(false);
    const [taskers, setTaskers] = useState(false);
    const [users, setUsers] = useState(false);
    const [contacts, setContacts] = useState(false);
    const [singleMail, setSingleMail] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const sendMail = (e) => {
        e.preventDefault();
        const headers = {
            'authorization': 'Bearer ' + localStorage.getItem('token'),
            'content-type': 'application/json'
        }
        axios.post(api + '/sendMail/' + localStorage.getItem('user'), {
            title: title,
            text: text,
            email: mail,
            users: users,
            parents: parents,
            taskers: taskers,
            singleMail: singleMail,
            contacts: contacts
        }, {headers})
        .then(function (res) {
            setError(false);
            setSuccess(true);
        })
        .catch(function (e) {
            setSuccess(false);
            setError(true);
        });
    }

    function changeParents(e) {
        setSuccess(false);
        if(parents) {
            e.target.style.color = 'black';
            e.target.style.background = 'white';
        } else {
            e.target.style.color = 'white';
            e.target.style.background = '#1688dc';
        }
        setParents(!parents);
    }

    function chagneTaskers(e) {
        setSuccess(false);
        if(taskers) {
            e.target.style.color = 'black';
            e.target.style.background = 'white';
        } else {
            e.target.style.color = 'white';
            e.target.style.background = '#1688dc';
        }
        setTaskers(!taskers);
    }

    function changeUsers(e) {
        setSuccess(false);
        if(users) {
            e.target.style.color = 'black';
            e.target.style.background = 'white';
        } else {
            e.target.style.color = 'white';
            e.target.style.background = '#1688dc';
        }
        setUsers(!users);
    }

    function changeContacts(e) {
        setSuccess(false);
        if(contacts) {
            e.target.style.color = 'black';
            e.target.style.background = 'white';
        } else {
            e.target.style.color = 'white';
            e.target.style.background = '#1688dc';
        }
        setContacts(!contacts);
    }

    if(localStorage.getItem('login') && localStorage.getItem('admin')){
        return (
            <div className="admin-mail">
                <div className="admin-mail-content">
                    <textarea className="mail-html" rows="1" placeholder="Title" onChange={(e) => setTitle(e.target.value)}></textarea>
                </div>
                <div className="admin-mail-content">
                    <textarea className="mail-html" rows="10" placeholder="Text" onChange={(e) => setText(e.target.value)}></textarea>
                </div>
                <div className="admin-mail-content">
                    <textarea className="mail-html" rows="20" placeholder="HTML code" onChange={(e) => setMail(e.target.value)}></textarea>
                </div>
                <div className="admin-mail-display">
                    <div dangerouslySetInnerHTML={{ __html: mail}}></div>
                </div>
                <div>
                    <div className="Send-to">Send to</div>
                    <div className="Send-wrapper">
                        <div className="Send-item" onClick={changeParents}>Parents</div><div className="Send-item" onClick={chagneTaskers}>Taskers</div><div className="Send-item" onClick={changeUsers}>Users</div><div className="Send-item" onClick={changeContacts}>Contacts</div>
                    </div>
                    <hr />
                    <div className="Send-to">Or</div>
                    <input type="text" className="send-to-input" placeholder="Single email" onChange={(e) => setSingleMail(e.target.value)}></input>
                    {error && (
                        <div className="wrong">Something went wrong</div>
                    )}
                    {success && (
                        <div className="wrong success">Success</div>
                    )}
                    <button className="send-button" onClick={sendMail}>Send</button>
                </div>
            </div>
        )
    } else {
        return (
            <div className="container login-section not-found-section">
              <h1>Page Not Found</h1>
            </div>
        )
    }
}

export default Admin
