import React, { useState, useEffect } from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import { Divider } from '../components'
import { Flex, CustomDiv } from '../components/CustomStyling'
import axios from "axios";
import { PlatformApi } from "../../api";
import {Formik} from 'formik'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default function RecurringTasksForm({ onHide,task }) {
    const initialValues = {
        taskId: task !== "" ? task.id : '',
        title: task !== "" ? task.title : '',
        taskType: task !== "" ? task.taskType : 0,
        dateType: 2,
        timeStart: task !== "" && task.startTime ? task.startTime : '',
        timeEnd: task !== "" && task.endTime ? task.endTime : '',
        hourlyWage: task !== "" ? task.hourlyWage : '0',
        flatWage: task !== "" ? task.flatWage : '0',
        locationType: task !== "" ? task.locationType : 0,
        address: task !== "" && task.homeAddress ? task.homeAddress.address : "",
        state: task !== "" && task.homeAddress ? task.homeAddress.state : '',
        zipCode: task !== "" && task.homeAddress ? task.homeAddress.zipCode : '',
        info: task !== "" ? task.info : '',
        recurrence: task !== "" ? task.recurring.recurrenceCount : 1,
        type: "week",
        daysOfTheWeek: task !== "" ? task.recurring.daysOfTheWeek :[],
        endType: task !== "" ? task.recurring.endType : 0,
        endDate: task !== "" ? task.recurring.endDate : '',
        endCount: task !== "" ? task.recurring.endCount : 0,
    }
    const [daysOfWeek, setDaysOfWeek] = useState(task !== "" ? task.recurring.daysOfTheWeek :[])
    const [update, setUpdate] = useState(0)
    const [dateStart, setDateStart] = useState(task !== "" ? moment.utc(task.dateStart).format("YYYY-MM-DD") : '')
    const [timeStart, setTimeStart] = useState(task !== "" && task.startTime ? task.startTime : '')
    const [timeEnd, setTimeEnd] = useState(task !== "" && task.endTime ? task.endTime : '')
    

    var items = [];

    const days = [
        {
            label: "Mon",
            day: "monday",
            value: 1
        },
        {
            label: "Tue",
            day: "tuesday",
            value: 2
        },
        {
            label: "Wed",
            day: "wednesday",
            value: 3
        },
        {
            label: "Thu",
            day: "thursday",
            value: 4
        },
        {
            label: "Fri",
            day: "friday",
            value: 5
        },
        {
            label: "Sat",
            day: "saturday",
            value: 6
        },
        {
            label: "Sun",
            day: "sunday",
            value: 0
        },
    ]

    const setDayOfTheWeek = async () => {
       
        let date = new Date(dateStart)
        days.forEach((item, i) => {
            if (parseInt(item.value) === parseInt(date.getUTCDay())) {
                setDaysOfWeek([item.day])
            }
        })
    }

    useEffect(() => {
        setDayOfTheWeek()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart])
    useEffect(() => {
        setDayOfTheWeek()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                const {
                    taskId,
                    title,
                    taskType,
                    dateType,
                    hourlyWage,
                    flatWage,
                    locationType,
                    address,
                    state,
                    zipCode,
                    info,
                    recurrence,
                    type,
                    endType,
                    endCount,
                } = values

                let newEndDate = task !== "" ? task.dateEnd : '';
                if (daysOfWeek.length > 0) {
                    let date = new Date(dateStart)
                    let selectedDays = []
                    days.forEach((item, i) => {
                        if (daysOfWeek.indexOf(item.day) >= 0) {
                            if (days[i].value < date.getUTCDay()) {
                                selectedDays.push(days[i].value + 7)
                            } else {
                                selectedDays.push(days[i].value)
                            }
                        }
                    });
                    newEndDate = moment(dateStart).add(Math.max(...selectedDays) - date.getUTCDay(), 'days').hour(0).format('YYYY-MM-DD')

                    // Add recurrence count ( how many weeks )
                    if(recurrence > 1) {
                        newEndDate = moment(newEndDate).add(recurrence-1, 'weeks').hour(0).format('YYYY-MM-DD')
                    }

                }                
                
                const object = {
                    taskType,
                    dateType,
                    dateStart: `${dateStart}`,
                    dateEnd: `${newEndDate}`,
                    timeStart: `${dateStart} ${timeStart}:00`,
                    timeEnd: `${dateStart} ${timeEnd}:00`,
                    hourlyWage,
                    flatWage,
                    locationType,
                    homeAddress: {
                        address,
                        state,
                        zipCode
                    },
                    virtualAddress: 'dd',
                    customAddress: 'dd',
                    volunteering: true,
                    volunteers_needed: 3,
                    details: {
                        title,
                        info,
                    },
                    recurring: {
                        recurrence: 1,
                        type,
                        daysOfTheWeek: daysOfWeek,
                        endType,
                        endDate: `${newEndDate}`,
                        endCount,
                        dateStart,
                        recurrenceCount: recurrence,
                        timeStart: `${dateStart} ${timeStart}:00`,
                        timeEnd: `${dateStart} ${timeEnd}:00`,
                    }
                }
                task !== ""  ? 
                    object.taskId = taskId : console.log('test');
                
                axios.post(`${PlatformApi}/task/` + (task === "" ? 'add_task' : 'edit'), { ...object }, {
                    headers: {
                        "X-AUTH-TOKEN": localStorage.getItem("api_token")
                    }
                }).then(res => {
                    window.location.reload()
                })
                    .catch(err => {
                        alert("There was an error trying to create the task, please check your internet conection")
                        onHide()
                    })
            }}
        >
            {({
                values,
                handleChange,
                handleSubmit,
            }) => (
                <Container>
                    {
                        task === "" ?
                            <h3>Post a task</h3>
                            :
                            <h3>Edit a task</h3>
                    }
            <Form onSubmit={handleSubmit} key={update}>
                <Form.Group className="mb-3" controlId="formBasicId" style={{display:'none'}}>
                    <Form.Control maxlength="40" name='taskId' type="text"  value={values.taskId} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicDateStart">
                    <Form.Label>Title</Form.Label>
                    <Form.Control maxlength="40" name='title' type="text" placeholder="enter a title" value={values.title} onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicDateStart">
                    <Form.Label>Start Date</Form.Label>
                    <DatePicker type='date' className='form-control' value={dateStart} onChange={(date) => {
                        setDateStart(moment(date).format('YYYY-MM-DD'))
                    }} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicTimeStart">
                    <Form.Label>Start Time</Form.Label>
                    <DatePicker
                        value={timeStart}
                        className='form-control'
                        onChange={(date) => {
                            setTimeStart(moment(date).format('HH:mm'));
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicTimeEnd">
                    <Form.Label>End Time</Form.Label>
                    <DatePicker
                        value={timeEnd}
                        className='form-control'
                        onChange={(date) => {
                            setTimeEnd(moment(date).format('HH:mm'));
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                    />
                </Form.Group>

                        <Form.Group controlId="formBasicRecurrence">
                            <Flex>
                                <Form.Text className="text-muted">Repeats for </Form.Text>
                                <Form.Control  as="select"width='fit-content' size="sm" margin='0 15px' aria-label="Default select example" name="recurrence" value={values.recurrence} onChange={handleChange}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 30].map((item, idx) => <option value={item} key={`repeats-each-value-${idx}`}>{item}</option>)}
                                </Form.Control>
                                <span className="text-muted m-3">   {
                                    values.recurrence === 1 ?
                                        'week'
                                        :
                                        'weeks'
                                }</span>
                            </Flex>
                        </Form.Group>
                        <Divider height='10px' />

                {
                            values.type === 'week' &&
                            <Form.Group controlId="formBasicDaysOfTheWeek">
                                <Form.Label>It is repeated on the day of</Form.Label>
                                <Flex className="mb-2">
                                    {days.map((item, idx) =>
                                        <CustomDiv key={item.label}>
                                            <div>
                                                <span>{item.label}</span>
                                            </div>
                                                                                    
                                            <Form.Check
                                                // inline
                                                checked={daysOfWeek.indexOf(item.day) >= 0}
                                                name={item.day}
                                                type="checkbox"
                                                id={`inline-radio-${item.label}`}
                                                onChange={(e) =>
                                                    daysOfWeek.length > 0 && daysOfWeek.indexOf(e.target.name) >= 0 ?
                                                        (
                                                            items = daysOfWeek, 
                                                                setDaysOfWeek([]),
                                                            items.splice(daysOfWeek.indexOf(e.target.name), 1),
                                                            setDaysOfWeek(items),
                                                            setUpdate(update + 1),
                                                            values.daysOfTheWeek.splice(daysOfWeek.indexOf(e.target.name), 1)                                                       )
                                                        :
                                                        (
                                                            daysOfWeek.length > 0 ?
                                                                (
                                                                    items = daysOfWeek,
                                                                    setDaysOfWeek(items.concat(e.target.name)),
                                                                        setUpdate(update + 1),
                                                                    values.daysOfTheWeek = daysOfWeek
                                                                )
                                                                :
                                                                (
                                                                    setUpdate(update + 1),
                                                                    setDaysOfWeek([e.target.name]), values.daysOfTheWeek = daysOfWeek
                                                                )

                                                        )
                                                }
                                            />
                                        </CustomDiv>
                                    )}
                                    <div style={{fontSize: '14px',padding: '12px',color: 'red'}}>
                                        {
                                            daysOfWeek.length === 0 ?
                                                'Select a day!'
                                                :
                                                ''
                                        }
                                    </div>
                                </Flex>
                            </Form.Group>
                        }
                        <div key="radio" className="mb-3">
                            <Form.Group className="mb-3" controlId="formBasicAddress">
                                <Form.Label>Location</Form.Label>
                                <Form.Control name='address' type="text" placeholder="Enter the adress of the task" value={values.address} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicState">
                                <Form.Label>State</Form.Label>
                                <Form.Control name='state' type="text" placeholder="State" value={values.state} onChange={handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicZip">
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control name='zipCode' type="text" placeholder="Zip" value={values.zipCode} onChange={handleChange} />
                            </Form.Group>

                            <div>
                                <Divider height='15px' />
                                <Form.Group>
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Additional Info"
                                        name='info'
                                        value={values.info}
                                        onChange={handleChange}
                                        style={{ height: '100px' }}
                                    />
                                </Form.Group>
                                <Divider height='15px' />
                                {
                                    task === "" ?
                                        <Button variant="primary" type="submit">
                                            Post task
                                        </Button>
                                        :
                                        <Button variant="primary" type="submit">
                                            Edit task
                                        </Button>
                                }
                            </div>
                        </div>
                    </Form>

                </Container>
            )}
        </Formik>
    )
}