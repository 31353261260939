import React, { useState } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Signup from './views/Signup';
import Signin from './views/Signin';
import Panel from './views/Panel';
import UserContext from './contexts/userContext'
import styled from 'styled-components';
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import ConfirmEmail from './views/ConfirmEmail';

const FontContainer = styled.div`
    font-family: 'Roboto', sans-serif;
`

const Tasks = () => <h1>Hola</h1>
export default function Volunteer() {
    let { path } = useRouteMatch()

    const initState = {
        user: {}, setUser: (newUserData) => {
            setState({ ...state, user: newUserData })
        }
    }
    const [state, setState] = useState(initState)

    return (
        <FontContainer>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/signin`}/>
                </Route>
                <Route path={`${path}/signup`}>
                    <UserContext.Provider value={state}>
                        <Signup />
                    </UserContext.Provider>
                </Route>
                <Route path={`${path}/forgot-password`}>
                    <UserContext.Provider value={state}>
                        <ForgotPassword />
                    </UserContext.Provider>
                </Route>
                <Route path={`${path}/change-password`}>
                    <UserContext.Provider value={state}>
                        <ChangePassword />
                    </UserContext.Provider>
                </Route>
                <Route path={`${path}/signin`}>
                    <UserContext.Provider value={state}>
                        <Signin />
                    </UserContext.Provider>
                </Route>
                <Route path={`${path}/organisation/panel`}>
                    <UserContext.Provider value={state}>
                        <Panel />
                    </UserContext.Provider>
                </Route>
                <Route path={`${path}/organisation/tasks`}>
                    <UserContext.Provider value={state}>
                        <Tasks />
                    </UserContext.Provider>
                </Route>
                <Route path={`${path}/confirm`}>
                    <UserContext.Provider value={state}>
                        <ConfirmEmail/>
                    </UserContext.Provider>
                </Route>
            </Switch>
        </FontContainer>
    )
}
