import React from 'react'
import { Nav } from 'react-bootstrap'
import styled from 'styled-components'
import UserContext from '../contexts/userContext'
import { useRouteMatch } from 'react-router'
import { SideNavLink } from './CustomStyling'

const StyledSideNav = styled.div`
    height: 100vh;
`

function SideNav({ variant = "ORGANISATION", ...otherProps }) {

    switch (variant) {
        case 'ORGANISATION':
            return (
                <StyledSideNav>
                    <OrganisationNav {...otherProps} />
                </StyledSideNav>
            )
        case 'SUPERVISOR':
            return (
                <StyledSideNav>
                    <SupervisorNav {...otherProps} />
                </StyledSideNav>
            )
        default:
            <h1>Variant doesn't exist</h1>
    }
}


function OrganisationNav({ ...otherProps }) {
    let { path, url } = useRouteMatch()
    const currentPath = '/volunteer/organisation/panel'
    console.log(`the path is :${path} \n and the url: ${url}`)
    return (
        <UserContext.Consumer>
            {({ setUser }) => (
                <Nav defaultActiveKey="/home" className="flex-column sidenav">
                    <SideNavLink className={path === `${currentPath}/home` && 'active'} href={`${currentPath}/home`}>Home</SideNavLink>
                    <SideNavLink className={path === `${currentPath}/tasks` && 'active'} href={`${currentPath}/tasks`}>Tasks</SideNavLink>
                    <SideNavLink className={path === `${currentPath}/notifications` && 'active'} href={`${currentPath}/notifications`}>Notifications</SideNavLink>
                    <SideNavLink className={path === `${currentPath}/profile` && 'active'} href={`${currentPath}/profile`}>Profile</SideNavLink>
                    <SideNavLink className="btn-logout" eventKey="disabled" onClick={() => {
                        // now user context doesn't have any values
                        setUser({})
                        // remove the api token and go to signin page
                        localStorage.setItem("api_token", "")
                        window.location.href = `/volunteer/signin`
                    }
                    }>
                        Logout
                    </SideNavLink>
                </Nav>
            )}
        </UserContext.Consumer>
    )
}

function SupervisorNav({ ...otherProps }) {
    let { path, url } = useRouteMatch()
    const currentPath = '/volunteer/organisation/panel'
    console.log(`the path is :${path} \n and the url: ${url}`)
    return (
        <UserContext.Consumer>
            {({ setUser }) => (
                <Nav defaultActiveKey="/home" className="flex-column sidenav">
                    <SideNavLink className={path === `${currentPath}/home` && 'active'} href={`${currentPath}/home`}>Home</SideNavLink>
                    <SideNavLink className={path === `${currentPath}/tasks` && 'active'} href={`${currentPath}/tasks`}>Volunteer hours</SideNavLink>
                    <SideNavLink className={path === `${currentPath}/notifications` && 'active'} href={`${currentPath}/notifications`}>Notifications</SideNavLink>
                    <SideNavLink className={path === `${currentPath}/profile` && 'active'} href={`${currentPath}/profile`}>Profile</SideNavLink>
                    <SideNavLink className="btn-logout" eventKey="disabled" onClick={() => {
                        // now user context doesn't have any values
                        setUser({})
                        // remove the api token and go to signin page
                        localStorage.setItem("api_token", "")
                        window.location.href = `/volunteer/signin`
                    }
                    }>
                        Logout
                    </SideNavLink>
                </Nav>
            )}
        </UserContext.Consumer>
    )
}

export default SideNav