import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ColCustomed, Button, CustomDiv } from '../../components/CustomStyling'
import { Modal, InfoCard, InfoCardEdit } from '../../components'
import { Row, Col, Nav, Container, Form } from 'react-bootstrap'
import { editProfile, isLoggedIn } from '../../utils'
import Layout from './Layout'
import { Formik } from 'formik'
//icons
import { Envelope, LayoutTextWindowReverse, Person, Phone, PinMap } from 'react-bootstrap-icons'
//contexts
import UserContext from '../../contexts/userContext'
import { Divider } from '../../components'

/**
 * Modal for editing the profile of an organisation
 */
const EditProfileModal = ({ show, onHide }) => {
    const { user } = useContext(UserContext)
    return (
        <Modal show={show} onHide={onHide} title={`${user.firstName} ${user.lastName}`}
        >
            <Container>
                <Row>
                    <Col>
                        <Formik
                            initialValues={{ organisation_name: user.name, organisation_website: user.website, organisation_address: user.home }}
                            onSubmit={(values, { setSubmitting }) => {
                                editProfile(values)
                                onHide()
                            }}
                        >
                            {({
                                values,
                                handleChange,
                                handleSubmit,
                            }) => (
                                <React.Fragment>
                                    <Divider height='10px' />
                                    <Form onSubmit={handleSubmit}>
                                        <InfoCardEdit name='organisation_name' values={values} handleChange={handleChange} type='text' placeholder='Organisation Name' item='Organisation Name' data={user.organisation_name} Icon={() => <Person className='icon' />} />
                                        <InfoCardEdit name='organisation_website' values={values} handleChange={handleChange} type='text' placeholder='website' item='Website' data={user.organisation_website} Icon={() => <Envelope className='icon' />} />
                                        <InfoCardEdit name='firstName' values={values} handleChange={handleChange} type='text' placeholder='First name' item='First name' data={user.firstName} Icon={() => <Person className='icon' />} />
                                        <InfoCardEdit name='lastName' values={values} handleChange={handleChange} type='text' placeholder='Last name' item='Last name' data={user.lastName} Icon={() => <Person className='icon' />} />
                                        <InfoCardEdit name='email' values={values} handleChange={handleChange} type='text' placeholder='Email' item='Email' data={user.email} Icon={() => <Envelope className='icon' />} />
                                        <InfoCardEdit name='phone' values={values} handleChange={handleChange} type='text' placeholder='address' item='Phone' data={user.phone} Icon={() => <Phone className='icon' />} />
                                        <InfoCardEdit name='organisation_address' values={values} handleChange={handleChange} type='text' placeholder='Address' item='Address' data={user.organisation_address} Icon={() => <PinMap className='icon' />} />
                                        <Divider height='30px' />
                                        <CustomDiv width='100%'>
                                            <Button variant="primary" type="submit" style={{ marginLeft: '' }} >
                                                Save
                                            </Button>
                                        </CustomDiv>
                                    </Form>
                                </React.Fragment>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
}


/**
 * Profile layout handler
 */
export default function Profile({ user, setUser }) {
    // check if user is logged in only if props change 
    const LoggedIn = useCallback(() => isLoggedIn(setUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])

    return (
        <Layout>
            <ProfileMain />
        </Layout>
    )
}


/**
 * View for organisation user
 * @returns Main View for the profile details of an organisation user
 */
function ProfileMain() {
    const [editProfileModal, setEditProfileModal] = useState(false)
    const { user } = useContext(UserContext)

    return (
        <div>
            <ColCustomed background='transparent'>
                <div className='user-info'>
                    <Row>
                        <Col>
                            <h5 style={{ padding: '.5rem' }}>{user.organisation_name}</h5>
                        </Col>
                        <Col>
                            <Nav className="justify-content-end" activeKey="/home">
                                <Button variant="outline-dark" onClick={() => setEditProfileModal(true)}>Edit</Button>
                            </Nav>
                        </Col>
                    </Row>
                    <Divider height='1px' background='#f0f0f0' margin='10px auto' />
                    <InfoCard item='Organisation Name' data={user.organisation_name} Icon={() => <Person className='icon' />} />
                    <InfoCard item='Website' data={user.organisation_website} Icon={() => <LayoutTextWindowReverse className='icon' />} />
                    <InfoCard item='Representative Name' data={`${user.firstName} ${user.lastName}`} Icon={() => <Person className='icon' />} />
                    <InfoCard item='Email' data={user.email} Icon={() => <Envelope className='icon' />} />
                    <InfoCard item='Phone' data={user.phone} Icon={() => <Phone className='icon' />} />
                    <InfoCard item='Address' data={user.organisation_address} Icon={() => <PinMap className='icon' />} />
                </div>
            </ColCustomed>
            <EditProfileModal show={editProfileModal} onHide={() => setEditProfileModal(false)} />
        </div>
    )
}
