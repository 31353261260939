import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="container login-section not-found-section">
      <h1>Page Not Found</h1>
    </div>
  )
}

export default NotFoundPage;