import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { api } from '../api';
import Spinner from 'react-bootstrap/Spinner'

const ForgotPassword = () => {
    const [email, setEmail] = useState();
    const [error, setError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    
    const forgotPassword = (e) => {
        e.preventDefault();
        axios.post(api + '/forgot-password', {
            email: email,
        })
        .then(function (res) {
            setEmailSent(true);
        })
        .catch(function (error) {
            setError(true);
        });
    }

    if(localStorage.getItem('login')){
        window.location.href = '/account';
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div className="flex-snap">
            {emailSent && (
                <div className="row sec-title">
                    <h2>An email has been sent to {email}. Follow the instructions to reset your password.</h2>
                </div>
            )}
            {!emailSent && (
                <div className="login-section forgot-password-section">
                    <div className="row sec-title">
                        <h2>Recover Your Account</h2>
                    </div>
                    <div className="login-area">
                        <Form onSubmit={forgotPassword}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Please enter your email address here:</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            {error && (
                                <Alert variant={"danger"}>Something went wrong!</Alert>
                            )}
                            <button className="login-submit" type="submit">
                                Send Email
                            </button>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ForgotPassword
