import axios from 'axios';
import React, {useState} from 'react';
import {Alert, Form} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {api} from '../api'
import {AiOutlineEye} from "react-icons/ai";
import Spinner from 'react-bootstrap/Spinner';
import {countries, usStates} from "../const";

const Signup = () => {
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [email, setEmail] = useState();
    const [country, setCountry] = useState('US');
    const [state, setState] = useState('AL');
    const [password, setPassword] = useState();
    const [isPasswordShown, setPasswordShown] = useState(false);
    const [error, setError] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const signup = (e) => {
        e.preventDefault();
        axios.post(api + '/signup', {
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password,
            country: country,
            state: state
        })
            .then(function (res) {
                setEmailSent(true);
            })
            .catch(function (e) {
                setError(e.response.data.error);
            });
    }

    const togglePasswordVisibility = () => {
        setPasswordShown(!isPasswordShown)
    }

    if (localStorage.getItem('login')) {
        window.location.href = '/account';
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div className="flex-snap">
            {emailSent && (
                <div className="row sec-title">
                    <h2>An email has been sent to {email}. Kindly verify your accont by following the instructions.</h2>
                </div>
            )}
            {!emailSent && (
                <div className="login-section forgot-password-section">
                    <div className="row sec-title">
                        <h2>Sign Up</h2>
                    </div>
                    <div className="login-area">
                        <Form onSubmit={signup}>
                            <Form.Group controlId="formBasicFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Your first name"
                                              onChange={(e) => setFirstname(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Your last name"
                                              onChange={(e) => setLastname(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email"
                                              onChange={(e) => setEmail(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId="formBasicCountry">
                                <Form.Label>Country</Form.Label>
                                <Form.Control as="select" value={country} onChange={(e) => {
                                    setCountry(e.target.value)
                                    setState('')
                                }}>
                                    {
                                        countries.map((obj, i) => (
                                            <option value={obj['code']}>{obj['name']}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>

                            {
                                country === "US" ?
                                    <Form.Group controlId="formBasicState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control as="select" value={state}
                                                      onChange={(e) => setState(e.target.value)}>
                                            {
                                                usStates.map((obj, i) => (
                                                    <option value={obj['code']}>{obj['name']}</option>
                                                ))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    : null
                            }

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Group className="password-field">
                                    <Form.Control type={(isPasswordShown) ? "text" : "password"}
                                                  placeholder="Set password"
                                                  onChange={(e) => setPassword(e.target.value)}/>
                                    <button type="button" className="view-password" onClick={togglePasswordVisibility}>
                                        <AiOutlineEye/>
                                    </button>
                                </Form.Group>
                            </Form.Group>
                            {error !== "" && (
                                <Alert variant={"danger"}>{error}</Alert>
                            )}
                            <button className="login-submit" type="submit">
                                Sign Up
                            </button>
                            <Form.Group className="signup-link" controlId="formBasicCheckbox">
                                <p>Already have an account?
                                    <Link to="/login">Login</Link>
                                </p>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Signup
