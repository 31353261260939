import React  from 'react'
import UserContext from '../contexts/userContext'
import PanelRouterSupervisor from './supervisor/PanelRouterSupervisor'
import PanelRouterOrganisation from './organization/PanelRouterOrganisation'

export default function Panel() {
    // NOTE: temportal solution, this will be later changed when we have the actal profile types for volunteering
    const profileType = parseInt(localStorage.getItem("profile_type"))
    
    return (
        <UserContext.Consumer>
            {({ user, setUser }) => {
                // check whether a user is a supervisor or an organisation and if not then show a forbidden page
                if(profileType < 2 || profileType > 3) return <h3 style={{textAlign:'center'}}>You're not allowed to see this page</h3>
                if(profileType === 2) return <PanelRouterOrganisation user={user} setUser={setUser} />
                if(profileType === 3) return <PanelRouterSupervisor user={user} setUser={setUser} />
            }
            }
        </UserContext.Consumer>
    )
}


