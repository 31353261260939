import React from 'react';

const EmailSent = () => {
    return (
        <div className="container login-section">
            <div className="row sec-title">
                <h2>An email has been sent. Please check your email for further instructions.</h2>
            </div>
        </div>
    )
}

export default EmailSent
