import React from 'react'
import axios from 'axios'
import { Formik } from 'formik';


import { Link } from 'react-router-dom'
import { Row, Col, Container, Form } from 'react-bootstrap'
import { Button } from '../components/CustomStyling'
import { Divider } from '../components'
import { PlatformApi } from '../../api'

export default function ChangePassword() {
    function submitChangePassword(values) {

        axios.post(`${PlatformApi}/password_recover/change_password`, {
            ...values
        }).then(res => {
            if (res.data.status === 1) {
                window.location.href = `/volunteer/signin`
            } else {
                if (res.data.error_code === 2002) {
                    alert("Wrong code")
                } else {
                    alert("Wrong Email")
                }
            }
        })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <React.Fragment>
            <Divider height='30px' />
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="5">

                        <Formik
                            initialValues={{ email: '', code: '', password: '' }}
                            validate={values => {
                                const errors = {};
                                if (!values.email) {
                                    errors.email = 'Required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }
                                return errors;
                            }}
                            onSubmit={(values) => {
                                setTimeout(async () => {
                                    try {
                                        submitChangePassword(values)
                                    } catch (err) {
                                        alert("There was an error signing up, please check your internet conection")
                                    }
                                }, 400);
                            }}
                        >
                            {({
                                values,
                                handleChange,
                                handleSubmit,
                                /* and other goodies */
                            }) => (
                                <React.Fragment>
                                    <Divider height='50px' />
                                    <h3>Change password</h3>
                                    <Divider height='10px' />
                                    <Form onSubmit={handleSubmit}>

                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control onChange={handleChange} name='email' value={values.email} type="email" placeholder="Enter email" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Code</Form.Label>
                                            <Form.Control onChange={handleChange} name='code' value={values.code} type="text" placeholder="Enter code from email" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control onChange={handleChange} name='password' value={values.password} type="password" placeholder="Password" />
                                        </Form.Group>
                                        <Form.Text className="text-muted">
                                            <Link to="/volunteer/signin">Sign in here</Link>
                                        </Form.Text>
                                        <Divider height='30px' />
                                        <Button variant="primary" type="submit">
                                            Change Password
                                        </Button>
                                    </Form>
                                </React.Fragment>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}


