import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { api } from '../api';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineEye } from "react-icons/ai";
import Spinner from 'react-bootstrap/Spinner'

const ResetPassword = ({match}) => {
    const resetlink = match.params.resetlink
    const [newPassword, setPassword] = useState();
    const [isPasswordShown, setPasswordShown] = useState(false);
    const [error, setError] = useState('');
    const [isPasswordReset, setPasswordReset] = useState(false);
    
    const resetPassword = (e) => {
        e.preventDefault();
        axios.post(api + '/reset-password', {
            newPassword: newPassword,
            resetlink: resetlink
        })
        .then(function (res) {
            setPasswordReset(true);
            setTimeout(function(){window.location.href = '/login'}, 3000);
        })
        .catch(function (e) {
            setError(e.response.data.error);
        });
    }
    const togglePasswordVisibility = () => {
        setPasswordShown(!isPasswordShown)
    }
    if(localStorage.getItem('login')){
        window.location.href = '/account';
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div className="everything fp-container">
            <Header />
                <div className="container login-section">
                    {!isPasswordReset && (
                        <div className="row sec-title">
                            <h2>Reset your password</h2>
                        </div>
                    )}
                    <div className="row">
                        {!isPasswordReset && (
                            <div className="login-area">
                                    <Form onSubmit={resetPassword}>
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Group className="password-field">
                                                <Form.Control type={(isPasswordShown) ? "text" : "password"} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                                <button type="button" className="view-password" onClick={togglePasswordVisibility}><AiOutlineEye/></button>
                                            </Form.Group>
                                        </Form.Group>
                                        {error !== "" && (
                                            <Alert variant={"danger"}>{error}</Alert>
                                        )}
                                        <button className="login-submit" type="submit">
                                            Reset Password
                                        </button>
                                    </Form>
                            </div>
                        )}
                        {isPasswordReset && (
                            <h2 className="center-h2">Password has been reset. Redirecting to Login...</h2>
                        )}
                    </div>
                </div>
            <Footer />
        </div>
    )
}

export default ResetPassword
