import React, { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { BiTachometer } from "react-icons/bi";

const BabyTemp = ({task, transport}) => {
    const [ages, setAges] = useState([]);
    const [res, setRes]= useState([]);

    useEffect(() => {
        if(task.babysitting.responsibilities){
            setRes(checkSetter(task.babysitting.responsibilities));
        }
        if(task.babysitting.ages){
            setAges(checkSetter(task.babysitting.ages));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkSetter = (value) => {
        let checkValue = [];
        for (const key in value) {
            if(value[key]){
                checkValue.push(key);
            }
        }
        return checkValue;
    }

    const tConvert = (time) => {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
          time = time.slice (1);
          time[5] = +time[0] < 12 ? 'am' : 'pm';
          time[0] = +time[0] % 12 || 12;
        }
        return time.join ('');
    }

    const defAge = (age) => {
        if(age === 'infant'){
            return '0-2';
        } else if(age === 'juniorhigh'){
            return '11+';
        } else if(age === 'elementary'){
            return '6-10';
        } else if(age === 'preschool'){
            return '3-5';
        }
    }

    return (
        <>
        <div className="task-icon-body">
            <div className="icon-block">
                    <AiOutlineClockCircle className="task-icon"/>
                    <h4><strong>{task.date}</strong></h4>
                    <h5><strong>{tConvert(task.startTime)}</strong> to <strong>{tConvert(task.endTime)}</strong></h5>
            </div>
            {task.location && (
                <div className="icon-block">
                    <FiMapPin className="task-icon"/>
                    <h4><strong>{task.babysitting.type === 'virtual' ? 'Virtual' : task.location}</strong></h4>
                </div>
            )}
            {task.rate && (
                <div className="icon-block">
                    <BiTachometer className="task-icon"/>
                    <h4><strong>${task.rate}</strong></h4>
                    <h5>{task.rateType === 'hourly' ? 'per hour': 'flat rate'}</h5>
                </div>
            )}
            {res.length > 0 && (
                <div className="icon-block about-item top-padding">
                    <div>
                    <h4><strong>Responsibilities:</strong></h4>
                    <h5><strong>{res.map((r, index) => (index + 1 === res.length) ? r : r + ", " )}</strong></h5>
                    </div>
                </div>
            )}
            {task.babysitting.kids && (
                [
                    (ages.length > 0 ? 
                        <div className="icon-block about-item top-padding">
                            <div>
                            <h4><strong>Number of kids: <span>{task.babysitting.kids}</span></strong></h4>
                            <h4><strong>Ages: <span>{ages.map((r, index) => (index + 1 === ages.length) ? defAge(r) : defAge(r) + ", " )}</span></strong></h4>
                            </div>
                        </div>
                    : 
                        <div className="icon-block about-item top-padding">
                            <div>
                            <h4><strong>Number of kids: <span>{task.babysitting.kids}</span></strong></h4>
                            </div>
                        </div>
                    )
                ]
            )}
            <div className="icon-block top-padding">
                <div>
                <h4><strong><span>{transport}</span></strong></h4>
                </div>
            </div>
            {task.additionalInfo && (
                <div className="icon-block about-item top-padding">
                    <div>
                    <h4><strong>Additional Info: </strong></h4>
                    <h5><strong>{task.additionalInfo}</strong></h5>
                    </div>
                </div>
            )}

        </div>
        {/*
        <div className="task-icon-body">
                <div className="icon-block">
                    <AiOutlineClockCircle />
                    <h4><strong>{task.date}</strong></h4>
                    <h5><strong>{tConvert(task.startTime)}</strong> to <strong>{tConvert(task.endTime)}</strong></h5>
                </div>
                {task.location && (
                    <div className="icon-block">
                        <FiMapPin />
                        <h4><strong>{task.babysitting.type === 'virtual' ? 'Virtual' : task.location}</strong></h4>
                    </div>
                )}
                {task.rate && (
                    <div className="icon-block">
                        <BiTachometer />
                        <h4><strong>${task.rate}</strong></h4>
                        <h5>{task.rateType === 'hourly' ? 'per hour': 'flat rate'}</h5>
                    </div>
                )}
        </div>
            {isAbout && (
            <div>
                <div className="task-about">
                    {res.length > 0 && (
                        <div className="about-item">
                            <h4><strong>Responsibilities:</strong></h4>
                            <h5><strong>{res.map((r, index) => (index + 1 === res.length) ? r : r + ", " )}, cleaning, washing, watching, brushing</strong></h5>
                        </div>
                    )}
                    {task.babysitting.kids && (
                        [
                            (ages.length > 0 ? 
                                <div className="about-item">
                                    <h4><strong>Number of kids: <span>{task.babysitting.kids}</span></strong></h4>
                                    <h4><strong>Ages: <span>{ages.map((r, index) => (index + 1 === ages.length) ? defAge(r) : defAge(r) + ", " )}</span></strong></h4>
                                </div>
                            : 
                                <div className="about-item">
                                    <h4><strong>Number of kids: <span>{task.babysitting.kids}</span></strong></h4>
                                </div>
                            )
                        ]
                    )}
                    <div className="about-item">
                        <h4><strong><span>{transport}</span></strong></h4>
                    </div>
                </div>
            </div>
        )}
        */}
        </>
    )
}

export default BabyTemp
