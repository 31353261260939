import React, { useEffect, useState } from 'react';
import { AiOutlineClockCircle } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { BiTachometer } from "react-icons/bi";

const PetTemp = ({task, transport}) => {
    const [res, setRes]= useState([]);
    const [dogCatsOthers, setDogCatsOthers] = useState(false);
    const [showMiddle, setShowMiddle] = useState(false);
    const [showTaskRes, setTaskRes] = useState(false);

    useEffect(() => {
        if(task.petsitting.responsibilities){
            setRes(checkSetter(task.petsitting.responsibilities));
        }
        if(task.petsitting.dogs || task.petsitting.cats || task.petsitting.others) {
            setDogCatsOthers(true);
        }
        if(task.petsitting.dogs || task.petsitting.cats || task.petsitting.others || task.petsitting.breed) {
            setShowMiddle(true);
        }
        if(task.petsitting.fedDay || task.petsitting.walkedDay || task.petsitting.sittingType) {
            setTaskRes(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task.petsitting.responsibilities])

    const checkSetter = (value) => {
        let checkValue = [];
        for (const key in value) {
            if(value[key]){
                checkValue.push(key);
            }
        }
        return checkValue;
    }

    const tConvert = (time) => {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) {
          time = time.slice (1);
          time[5] = +time[0] < 12 ? 'am' : 'pm';
          time[0] = +time[0] % 12 || 12;
        }
        return time.join ('');
    }
    console.log(task)

    const defSitting = (sitting) => {
        if(sitting === 'overnight'){
            return 'overnight stay';
        } else if(sitting === 'regular'){
            return 'regular check-ins';
        } else if(sitting === 'sitting'){
            return 'sitting at sitter\'s home';
        }
    }

    return (
        <>
        <div className="task-icon-body">
            <div className="icon-block">
                <AiOutlineClockCircle />
                <h4><strong>{task.date}</strong></h4>
                <h5><strong>{tConvert(task.startTime)}</strong> to <strong>{tConvert(task.endTime)}</strong></h5>
            </div>
            {task.location && (
                <div className="icon-block">
                    <FiMapPin />
                    <h4><strong>{task.petsitting.type === 'virtual' ? 'Virtual' : task.location}</strong></h4>
                </div>
            )}
            {task.rate && (
                <div className="icon-block">
                    <BiTachometer />
                    <h4><strong>${task.rate}</strong></h4>
                    <h5>{task.rateType === 'hourly' ? 'per hour': 'flat rate'}</h5>
                </div>
            )}
            {res.length > 0 && (
                <div className="icon-block about-item top-padding">
                    <div>
                    <h4><strong>Responsibilities:</strong></h4>
                    <h5><strong>{res.map((r, index) => (index + 1 === res.length) ? r : r + ", " )}</strong></h5>
                    </div>
                </div>
            )}
            <div className={`${showMiddle ? "icon-block about-item task-item-small top-padding" : ""}`}>
                {dogCatsOthers && (
                    <div>
                    <h4><strong>I have </strong><span>{task.petsitting.dogs ? task.petsitting.dogs + ' dogs, ' : ''} {task.petsitting.cats ? task.petsitting.cats + ' cats, ' : ''} {task.petsitting.others ? task.petsitting.others : ''}</span></h4>
                    </div>
                )}
                {task.petsitting.breed && (
                    <div>
                    <h4><strong>Breeds: </strong><span>{task.petsitting.breed}</span></h4>
                    </div>
                )}
            </div>
            <div className={`${showTaskRes ? "icon-block about-item task-item-small top-padding" : ""}`}>
                <div>
                {task.petsitting.fedDay && (
                    <h4><strong>Feed: </strong><span>{task.petsitting.fedDay} times/day</span></h4>
                )}
                {task.petsitting.walkedDay && (
                    <h4><strong>Walk: </strong><span>{task.petsitting.walkedDay} times/day</span></h4>
                )}
                {task.petsitting.sittingType && (
                    <h4><strong>Requires </strong><span>{defSitting(task.petsitting.sittingType)}</span></h4>
                )}
                </div>
            </div>
            <div className="icon-block about-item top-padding">
                <div>
                <h4><strong><span>{transport}</span></strong></h4>
                </div>
            </div>
            {task.additionalInfo && (
                <div className="icon-block about-item top-padding">
                    <div>
                    <h4><strong>Additional Info: </strong></h4>
                    <h5><strong>{task.additionalInfo}</strong></h5>
                    </div>
                </div>
            )}
        </div>
        {/*
        {isAbout && (
            <div>
                <div className="task-about">
                    {res.length > 0 && (
                        <div className="about-item">
                            <h4><strong>Responsibilities:</strong></h4>
                            <h5><strong>{res.map((r, index) => (index + 1 === res.length) ? r : r + ", " )}</strong></h5>
                        </div>
                    )}
                    <div className={`${showMiddle ? "about-item task-item-small" : ""}`}>
                        {dogCatsOthers && (
                            <h4><strong>I have </strong><span>{task.petsitting.dogs ? task.petsitting.dogs + ' dogs, ' : ''} {task.petsitting.cats ? task.petsitting.cats + ' cats, ' : ''} {task.petsitting.others ? task.petsitting.others : ''}</span></h4>
                        )}
                        {task.petsitting.breed && (
                            <h4><strong>Breeds: </strong><span>{task.petsitting.breed}</span></h4>
                        )}
                        {task.petsitting.fedDay && (
                            <h4><strong>Feed: </strong><span>{task.petsitting.fedDay} times/day</span></h4>
                        )}
                        {task.petsitting.walkedDay && (
                            <h4><strong>Walk: </strong><span>{task.petsitting.walkedDay} times/day</span></h4>
                        )}
                        {task.petsitting.sittingType && (
                            <h4><strong>Requires </strong><span>{defSitting(task.petsitting.sittingType)}</span></h4>
                        )}
                    </div>
                    <div className="about-item">
                        <h4><strong><span>{transport}</span></strong></h4>
                    </div>
                </div>
            </div>
        )}
        */}
        </>
    )
}

export default PetTemp
