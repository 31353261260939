import React from 'react'
import PrivacyPolicyPdf from '../assets/PrivacyPolicy.pdf'

const Privacy = () => {
    return (
        <section className="privacy-section">
            <div>
                <h2>Privacy Policy</h2>
                <p>Last Updated: 06/17/21</p>
                <p>This Privacy Policy sets forth how Pocketask, Inc. (“Pocketask,” “we,” or “us”) will collect, use and share information in connection with our mobile applications, websites and any other online offerings that link to this Privacy Policy (collectively, the “Service”).</p>
                <p>By accessing or using the Service, you agree to this Privacy Policy and our Terms of Service and consent to our collection, use and sharing of your information as described below.</p>
                <p>Please note that this Privacy Policy applies only to information collected through the Service, and does not apply to information that may be collected by us or our affiliates in an offline context, such as, at in-person events.</p>
                <h3>1. FROM WHOM WE COLLECT INFORMATION.</h3>
                <p>We collect information from users of the Service, including the following individuals:</p>
                <ul>
                    <li>Creators -- Individuals over 18 years of age who are approved to post tasks on the Service.</li>
                    <li>Taskers -- Individuals between 14 and 23 years of age who create an account in order to browse and perform tasks for Creators through the Service.</li>
                    <li>Parents/Guardians of Taskers -- Individuals over 18 years of age who are asked to confirm a Tasker’s identity and approve the Tasker’s use of the Service</li>
                </ul>
                <h3>2. INFORMATION WE COLLECT.</h3>
                <p>We collect “Personal Information” in several ways when you access or use the Service. Personal Information is generally defined as information about you that can be used to contact or identify you, such as your name, e-mail address, home address, IP address and telephone number. Additionally, we may also collect demographic information, such as your age, gender and personal preferences. This demographic information may be directly associated with your Personal Information, in which case it will be treated as Personal Information. On the other hand, aggregate information about the characteristics and activities of users of the Service that is not connected to Personal Information (or from which Personal Information has been removed) will not be treated as Personal Information.</p>
                <ul>
                    <li>
                        Information You Provide to Us Directly. When you interact with the Service, we may collect, store, and use information that you voluntarily provide to us. For example, when you register with the Service or create an account with us, you will be required to provide certain identifying information, such as your name, address, age, ID number (e.g., driver’s license), e-mail and other information that helps us confirm that it is you accessing your account. In addition, you may voluntarily submit Personal Information when you make a purchase, sign up for newsletters, send us feedback, post on our Facebook page or submit User Generated Content (as defined in our Terms of Service). We may also offer you the ability to import your address book contacts or manually enter e-mail addresses so that you can locate your contacts on the Service.
                    </li>
                    <li>
                        Information Provided by Other Users (Ratings and Reviews). The Service allows users to submit ratings and reviews of other users of the Service which may be associated with their respective user profiles and published on the Service. In addition, Personal Information will be collected during the verification process between Taskers and Parents/Guardians of Taskers.
                    </li>
                    <li>
                        In-App Communications. When users communicate with each other through the Service (e.g., using the mobile app’s messaging features), we will collect and store the content of the messages and related data (including Personal Information) as part of the messaging functionality.
                    </li>
                    <li>
                        Information Received from a Third Party. When you use or interact with the Service through a social network (e.g., Google or Facebook) or other third party platform, app, or service (“Third Party Service”), you are authorizing Pocketask to collect, store, and use, in accordance with this Privacy Policy, any and all information that you agreed the Third Party Service could provide to Pocketask, including Personal Information. In addition, we will collect information from our safety and background check providers, which may include Personal Information about you. We may also obtain Personal Information about you from other sources, such as, our vendors, partners, and network advertising companies, and combine that information with information we have collected from or about you.
                    </li>
                    <li>
                        Payment Information. We use a third party provider (Stripe) to manage payments and purchases made on or through the Service. We do not have access to (and thus do not collect or store) your credit card details or billing information for such payment transactions. Please note that Taskers (or others who have been paid through the Service) may be required to submit some form of identification directly to our payment provider to withdraw their funds from Pocketask. Pocketask will not collect or use the form of identification that is submitted to our payment provider for this purpose. You acknowledge and agree that Pocketask will have no liability for or control over our payment provider’s use or protection of your payment information. You should check the company’s privacy policy to determine how it will handle any payment information it collects from you and/or processes. Please note, however, that we may collect, store, and use non-payment information relating to your purchase history, such as the products and services you have purchased and the frequency of those purchases
                    </li>
                    <li>
                        Customer Support Information. When you ask for assistance from our customer support team (either directly or through one of our service providers), we will collect and store the contact information you provide (generally your name and e-mail address) and information about your activity on the Service. We will also store and use the correspondence and any information contained within.
                    </li>
                    <li>
                        Log Data and Mobile Devices. When you use the Service, our servers record information that your browser sends whenever you visit a website (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type or the webpage you were visiting before you came to the Service, pages of the websites involved in the Service that you visit, the time spent on those pages, information you search for, access times and dates, and other information. If you access the Service from a mobile or other device, we may collect a unique device identifier assigned to that device or other transactional information for that device, such as your device type, geo-location, and operating system. Log Data is tied to your Personal Information in certain circumstances, such as if you have linked a Third Party Service account to your Pocketask account. Your browsing activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your laptop.
                    </li>
                </ul>
                <h3>3. COOKIES AND LOCAL STORAGE</h3>
                <p>We use cookies and other tracking technologies
                    (including but not limited to browser cookies, pixels, beacons, and Adobe flash technology),
                    which are comprised of small bits of data that often include an anonymous unique identifier.
                    Websites send this data to your browser when you first request a web page and then store the
                    data on your computer so the website can access information when you make subsequent
                    requests for pages from that site. These technologies may be used to collect and store
                    information about your use of Service, such as pages you have visited, search queries you have
                    run, and advertisements you have seen. Information contained in a cookie may also be linked to
                    your Personal Information for purposes such as improving the quality of the Service, tailoring
                    recommendations and ads to your interests, and making the Service easier to use. You can
                    disable cookies at any time, although, if you do so, you may not be able to access or use the
                    Service or certain of its features.</p>
                <p>
                    For more information about how third-party cookies are used for targeted advertising and how
                    to opt out of certain third-party advertising related cookies, you can visit
                    www.networkadvertising.org, www.aboutads.info/choices, youradchoices.ca, or
                    www.youronlinechoices.eu. Note that opting out does not mean you won’t see ads; rather, it
                    means that the advertising you see may not be as relevant to you.
                </p>
                <p>
                    Pocketask uses some or all of the following cookies on its Service for analytics and/or marketing
                    purposes. Please note that while this list is regularly updated, we do not guarantee that it is
                    fully up-to-date. In addition, other cookies and similar technologies may be used for various
                    purposes on other Pocketask platforms.
                </p>
                <ul>
                    <li> Google Analytics (analytics and ad targeting). For more information about Google Analytics
                        cookies, please see Google’s help pages and privacy policy. Google has developed the
                        Google Analytics opt-out browser add-on; if you want to opt out of Google Analytics, you
                        can download and install the add-on for your web browser here.
                    </li>
                    <li>
                        Facebook (analytics and ad targeting). For more information about Facebook cookies, please
                        see Facebook’s help pages and privacy policy.
                    </li>
                </ul>
                <h3>4. HOW WE USE YOUR INFORMATION</h3>
                <p>
                    Below is a summary of how we use the
                    information collected from and about you. We may combine Personal Information with other
                    information and use this combination to improve your experience or to aggregate it with
                    information collected from other users in connection with the Service. This Privacy Policy in no
                    way limits or restricts our collection and dissemination of aggregate information, User
                    Generated Content (as defined in the Terms of Service), or of non-personal information.
                </p>
                <ul>
                    <li>
                        Provide and Improve the Service. We use your information to provide the Service to you and
                        to perform essential business operations, such as, enabling and performing transactions
                        with other users, providing functionality of the Service, displaying user feedback, reviews
                        and ratings, and account management. We use information collected to continually improve
                        the Service, including system administration, system security, and adding new features or
                        capabilities. We use information collected to develop aggregate analyses and business
                        intelligence that enable us to operate, protect, make informed decisions, and report on the
                        performance of our business.
                    </li>
                    <li>
                        Verification of Taskers. If you are a Tasker or Parent/Guardian of a Tasker, we may use your
                        information to confirm the Tasker’s identity, to approve the Tasker’s use of the Service, and
                        to communicate with you about the Tasker’s use of the Service.
                    </li>
                    <li>
                        Conduct Safety and Background Checks. If you are a Creator or Tasker, we may use
                        information (including Personal Information) collected from or about you in connection with
                        our safety and background checks. This information may be used to verify whether a user is
                        eligible to use the Service and to publish badges (or similar features) based on information
                        collected from those safety and background checks.
                    </li>
                    <li>
                        Promotions. We may use your information to administer contests, promotions, surveys, or
                        other site features.
                    </li>
                    <li>
                        Improve Advertising Campaigns. We may use your information to improve our advertising
                        campaigns and to present you with advertisements that are relevant to you.
                    </li>
                    <li>
                        Communications. We may use your information to communicate with you and to
                        personalize our communications with you. For example, we may contact you to discuss your
                        account, to provide technical or customer support, to remind you about features of the
                        Service that are available for your use, or to invite you to participate in a survey.
                        Additionally, you can sign up for email newsletters, and choose whether you want to receive
                        marketing communications from us.
                    </li>
                    <li>
                        Referral Services. We may allow you to provide information about your friends and/or social
                        media contacts through our referral services. Our referral services may allow you to forward
                        or share certain content with a friend or colleague, such as an email inviting your friend to
                        use the Service, or by sending them a text message or a message through an over-the-top
                        messenger provider, such as WhatsApp.
                    </li>
                </ul>
                <h3>5. HOW WE SHARE YOUR INFORMATION.</h3>
                <p>Pocketask may disclose, share or transfer your
                    Personal Information as follows and we will obtain your consent to do so where required by
                    applicable law:
                </p>
                <ul>
                    <li>
                        Vendors and Providers. We may engage vendors, agents, service providers, and affiliated
                        entities to provide services to us or to Pocketask users on our behalf, including safety and
                        background checks, support for the internal operations of our apps and websites, payment
                        processing, cloud hosting, and off-site backups. In providing their services, they may access,
                        receive, maintain or otherwise process Personal Information on our behalf. These service
                        providers are expected to abide by our data privacy and security requirements and are not
                        allowed to use Personal Information they receive from us for any other purpose.
                    </li>
                    <li>
                        Advertising and Analytics Partners. We may engage third parties that provide advertising,
                        campaign measurement, online and mobile analytics, and related services to us (with your
                        consent, where required by applicable laws). They may use this information to help us
                        better reach individuals with relevant ads and to measure and improve our ad campaigns, or
                        to better understand how individuals interact with the Service. In addition, these third
                        parties may combine the information collected from us and our Service with information
                        collected about you from third-party sites, apps and services, in order to make inferences
                        about you and your interests, to better tailor advertising and content to you across multiple
                        sites, apps, and services, and to collect associated metrics
                    </li>
                    <li>
                        Legally Required. We may disclose your Personal Information if we believe we are required
                        to do so by law, or that doing so is reasonably necessary to comply with legal processes;
                        when we believe necessary or appropriate to disclose Personal Information to law
                        enforcement or other governmental or regulatory authorities or the courts, such as to
                        investigate actual or suspected fraud or violations of law, breaches of security, or breaches
                        of this Privacy Policy or Terms of Use; to respond to any claims against us; and, to protect
                        the rights, property, or personal safety of Pocketask, our customers, or the public.
                    </li>
                    <li>
                        Corporate Transactions. In addition, your Personal Information may be disclosed as part of
                        any proposed or actual merger, sale, and transfer of Pocketask assets, acquisition,
                        dissolution, bankruptcy, or similar event.
                    </li>
                    <li>
                        With Consent. We may also disclose your Personal Information to any other affiliated or
                        third parties where you have consented or requested that we do so.
                    </li>
                </ul>
                <p>
                    Notwithstanding anything else in this Policy, we may share aggregate or de-identified
                    information with third parties for research, marketing, analytics and other purposes, provided
                    such information does not identify a particular individual and the individual cannot be
                    re-identified.
                </p>
                <h3>6. POLICY REGARDING CHILDREN.</h3>
                <p>Although Pocketask’s Service is meant to provide a
                    platform to connect teens to work opportunities, the platform is not directed toward children
                    under 14 years of age. We do not knowingly collect Personal Information from children under
                    14. If you believe that we have collected Personal Information from a child under 14, please
                    contact us and we will act as necessary to securely delete such information.
                </p>
                <h3>7. USER CONTROL AND RETENTION OF DATA</h3>
                <p>
                    If you have created an account with us (not
                    through a Third Party Service), you may access and change your account information by visiting
                    your account page and making the desired changes. To manage the information we receive
                    about you from a Third Party Service, you will need to follow the instructions at that site for
                    updating your information and changing your privacy settings. Once we receive your
                    information from a Third Party Service, that information is stored and used by us in accordance
                    with this Privacy Policy.
                </p>
                <p>
                    If you created an account with us directly (not through a Third Party Service), you may cancel
                    the Service and close your account at any time. Please note that even after you cancel the
                    Service, copies of some information that is publicly viewable may remain viewable in
                    circumstances where, for example, your information has been transmitted through our
                    messaging service, you have commented on content on the Service, or if another person
                    copied, stored, or shared your information. Please note that deactivation of an account is not
                    immediate.
                </p>
                <p>
                    If you deactivate your account or remove the Service, we may still retain certain information
                    associated with your account for analytical, recordkeeping and integrity, as well as to prevent
                    fraud, resolve disputes, enforce our Terms of Use or other policies, take actions we deem
                    necessary due to technical and legal requirements and constraints related to the security,
                    integrity and operation of the Service. In addition, if certain information has already been
                    provided to third parties as described in this Privacy Policy, retention of that information will be
                    subject to those third parties’ policies.
                </p>
                <h3>8. SECURITY.</h3>
                <p>
                    We have put in place reasonable technical and organizational measures
                    designed to secure your Personal Information from accidental loss and from unauthorized
                    access, use, alteration, or disclosure. However, we do not guarantee, and explicitly disclaim any
                    implied representation, warranty, or other promise, that unauthorized third parties will never
                    be able to overcome those measures or use your Personal Information for improper purposes.
                    Also please note that e-mail and messaging systems are not considered secure, so we
                    discourage you from sending us Personal Information through these mediums.
                </p>
                <h3>9. THIRD PARTY WEBSITES.</h3>
                <p>
                    This Privacy Policy does not apply to third-party websites,
                    online properties, apps or systems to which our Service may link or through which our Service
                    may be accessed, and you accept that Pocketask is not responsible for the security or privacy
                    practices of any such third-party websites, properties or systems. In some instances, we may
                    direct you to a site that is operated by a vendor or partner. Even though the page where you
                    provide information may have the look and feel of the Service, a different privacy policy may
                    govern that information collection by our vendor or partner.
                </p>
                <h3>10. INTERNATIONAL TRANSFERS.</h3>
                <p>
                    We process and store your information in the United
                    States and your information may be stored on servers located outside your residential
                    jurisdiction. The Service is not intended for users outside the United States at this time.
                </p>
                <h3>11. CHANGES TO THIS AGREEMENT.</h3>
                <p>
                    Pocketask reserves the right, in its sole discretion, to
                    modify or revise this Privacy Policy at any time, and you agree to be bound by such
                    modifications or revisions. Any such change or modification will be effective immediately or,
                    where required by law, 30 days after its publication in connection with the Service. Pocketask
                    may notify you of any such changes by providing a link to the new Privacy Policy before you use
                    the Service, posting the notice of such changes on Pocketask’s website, or sending notice via
                    other means. Your continued use of the Service after any changes or modifications to this
                    Privacy Policy are posted will constitute your acceptance of, and agreement to, such changes or
                    modifications. If you object to any change or modification, your sole recourse shall be to cease
                    using the Service.
                </p>
                <h3>12. CONTACTING US.</h3>
                <p>
                    If you have any questions about this Privacy Policy, or the practices of
                    Pocketask or the Service, please contact us at team@mypocketask.com.
                </p>
                <a href={PrivacyPolicyPdf}> To download this document in pdf click here</a>
            </div>
        </section>
    )
}

export default Privacy
