import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { HiChevronDown } from "react-icons/hi";

//subicon
import Profile from '../assets/profile.png'
import BlackCheck from '../assets/black-check.png'
import DownArrow from '../assets/down-arrow.png'
import PawPrint from '../assets/paw-print.png'
import LookingScreen from '../assets/looking-screen.png'
import StretchingHands from '../assets/stretching-hands.png'
import Note from '../assets/note.png'
import Bell from '../assets/bell.png'
import TeenSafety from '../assets/teen-safety.png'
import FamilySafety from '../assets/family-safety.png'

const Safety = () => {
    return (
        <section className="safety-section">
            <div className='container flex wrap'>
                <Item img={Profile}>
                    <div className='item-content'>
                        <h2>Background Checks</h2>
                        <SubIconContainer subImg={BlackCheck}>
                            We run an identity verification check of all Taskers and Creators over 18 using software provided by a thirdparty called veriff.com.
                        </SubIconContainer>
                    </div>
                </Item>
                <div className='center-text arrow-container'>
                    <Arrow src={DownArrow} className='centered-component' />
                </div>
                <Item className='row-reverse'>
                    <div className='item-content'>
                        <SubIconContainer subImg={PawPrint} className='row-reverse'>
                            Required: We run a sex offender registry check on all Taskers and Creators over 18 by using the API of a thirdparty called familywatchdog.com.
                        </SubIconContainer>
                    </div>
                </Item>
                <div className='center-text arrow-container'>
                    <Arrow src={DownArrow} className='centered-component' />
                </div>
                <Item className='row-reverse'>
                    <div className='item-content'>
                        <SubIconContainer subImg={LookingScreen}>
                            Optional: You can earn a background check badge on your profile for receiving an optional “clean” criminal background check from a third-party background check company, Sterling (sterlingcheck.com).
                        </SubIconContainer>
                    </div>
                </Item>
                {/*  */}
                <div className='divider'></div>
                <Item img={StretchingHands} className='row-reverse'>
                    <div className='item-content'>
                        <h2>Parental approval and tracking</h2>
                        <SubIconContainer subImg={Note}>
                            We require parental/guardian consent before any users under 18 can sign into the app and start accepting tasks.
                        </SubIconContainer>
                    </div>
                </Item>
                <div className='center-text arrow-container'>
                    <Arrow src={DownArrow} className='centered-component' />
                </div>
                <Item className='row-reverse'>
                    <div className='item-content'>
                        <SubIconContainer subImg={Bell} className='row-reverse'>
                            Each time a user under 18 accepts a task we notify the parent/guardian through SMS or email depending on the parent/guardian preferences.
                        </SubIconContainer>
                    </div>
                </Item>
                <div className='divider'></div>
                <div className='item safety-category-container centered-component'>
                    <Accordion>
                        <Card className='term left-sided'>
                            <CardTitle title='Safety Tips' eventKey={1} className='small-img' img={TeenSafety} />
                            <CardContent eventKey={1} className='flex justify-center'>
                                <SubIconContainer className='small-img centered-component justify-center'>
                                    <div className='justified-text'>
                                        <h3 className='blue-color'>Teen safety tips</h3>
                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-text'>General Safety</h5>
                                            <ul>
                                                <li>Make sure your phone is fully charged.</li>
                                                <li>Remember the SOS button feature on iPhone.</li>
                                                <li>Conduct an on-line search of the Creator’s full name and check social media to ensure you are comfortable accepting the task.</li>
                                                <li>Before you go to a task, Share your location with trusted adults.</li>
                                                <li>Trust your instincts! If you feel unsafe when you arrive at the task location, leave the premise and report the situation to us right away!</li>
                                            </ul>
                                        </div>


                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>Babysitting</h5>
                                            <ul>
                                                <li>Babysitters should not leave kids unsupervised</li>

                                                <li>Parents should give the babysitter a list of emergency phone numbers. Always have your phone ready!</li>

                                                <li>Make sure you have a safe way of getting home, especially at night.</li>

                                                <li>Stay in the house unless otherwise given permission.</li>

                                                <li>Be aware of exits, first aid supplies, and fire alarms.</li>

                                                <li>Do not answer the door or let anyone into the house unless they are expected.</li>
                                            </ul>
                                        </div>

                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>Dog walking and Petsitting</h5>
                                            <ul>
                                                <li>Watch the path. Look out for any potential dangers on the path that a dog could step on, like broken glass.</li>

                                                <li>Do not take the dog off the leash when out</li>

                                                <li>Pay extra attention when crossing the road.</li>

                                                <li>Keep to main roads for your own safety.</li>

                                                <li>Make sure you are given clear instructions by the owner on proper care.</li>

                                                <li>Avoid contact with other dogs and people; you never know how dogs will react to each other or to people.</li>
                                            </ul>
                                        </div>

                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>Tutoring</h5>
                                            <ul>
                                                <li>Make sure the child doesn't look up anything inappropriate if using an electronic device and if any inappropriate ads somehow appear, be sure to tell the child’s parents.</li>
                                                <li>If the child’s parents are not in the house while the tutoring session is in place, make sure you know the basic house safety rules, like first aid and fire exits.</li>
                                            </ul>
                                        </div>

                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>Errands</h5>
                                            <ul>
                                                <li>If using a personal car, ensure you are always completely aware of the road, and always wear your  seatbelt</li>
                                                <li>Never use your phone when driving to make calls or to text.</li>
                                                <li>Make sure you feel safe on public transport. If you ever feel unsafe, have an emergency number ready to be called.</li>
                                                <li>Make sure that you have phone notifications on to respond quickly to any messages but do not check it or use it while driving.</li>
                                            </ul>
                                        </div>

                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>Yard Work</h5>
                                            <ul>
                                                <li>Wear protective clothes, like jeans and sneakers. Bring gloves if necessary.</li>

                                                <li>Bring water and sunscreen.</li>

                                                <li>Make sure you are comfortable using any equipment. Let the Creator know right away if you are not comfortable with any piece of equipment.</li>
                                            </ul>
                                        </div>

                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>COVID-19 safety</h5>
                                            <ul>


                                                <li>We recommend that you bring hand sanitizer with you and regularly sanitize your hands</li>
                                                    <li>Avoid touching your face</li>
                                                        <li>Take precaution when traveling to/from tasks. For example, if taking public transport, wear a mask, stay six feet away from others and avoid touching surfaces</li>
                                                            <li>If you or anyone you are staying with has had any COVID-19 symptoms, then please let the creator know as soon as possible to reschedule or cancel the task.</li>
                                                                <li>Check with creator about vaccination and testing requirements and fulfill any testing or vaccination requirement prior to task</li>

                                            </ul>
                                        </div>
                                    </div>
                                </SubIconContainer>
                            </CardContent>
                        </Card>
                    </Accordion>
                </div>
                <div className='item safety-category-container centered-component'>
                    <Accordion>
                        <Card className='term left-sided'>
                            <CardTitle title='Parent Safety' eventKey={1} className='small-img' img={FamilySafety} />
                            <CardContent eventKey={1} className='flex justify-center'>
                                <SubIconContainer className='small-img centered-component justify-center'>
                                    <div className='justified-text'>
                                        <h3 className='blue-color'>Parent Safety</h3>
                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <ul>
                                                <li>
                                                    We use a review system, which helps Creators make confident, informed choices in deciding which tasker to accept for their task.
                                                </li>
                                                <li>
                                                    For the safety of our Taskers, Creators are not permited to communicate with Taskers outside of Pocketask. If we find out this happens we may suspend you from our services, as the safety of our users is of paramount importance to us.
                                                </li>
                                                <li>
                                                    Conduct an on-line search of the Tasker’s full name and check social media to ensure you are comfortable with the Tasker.
                                                </li>
                                                <li>
                                                    Trust your instincts.  If you are not comfortable with something, talk about it with the Tasker or cancel the task.
                                                </li>
                                                <li>
                                                    Set ground rules for behavior and share them clearly with the Tasker before the Task.
                                                </li>
                                                <li>
                                                    Always have your phone on if you are not home and share an alternative emergency contact with your Tasker.
                                                </li>
                                                <li>
                                                    Make sure the Tasker provides you with an emergency contact just in case.
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='divider'></div>
                                        <div className='safety-category'>
                                            <h5 className='blue-color'>COVID-19 safety</h5>
                                            <ul>

                                                <li>Wear masks where necessary and try to stay as socially distanced as possible from taskers</li>
                                                    <li>Please ensure that taskers have a space to wash hands once they arrive.</li>
                                                        <li>If you or anyone you are staying with has had any COVID-19 symptoms, then please let the tasker know as soon as possible to reschedule or cancel the task</li>

                                            </ul>
                                        </div>

                                    </div>
                                </SubIconContainer>
                            </CardContent>
                        </Card>
                    </Accordion>
                </div>
                {/**? */}
            </div>
        </section >
    )
}

//------ components ----------
function Item({ img, children, className }) {
    return (
        <div className={'item flex centered-component ' + className}>
            {img && <img src={img} alt="" className='item-image' />}
            {children}
        </div>
    )
}

function SubIconContainer({ subImg, children, className }) {
    return (
        <div className={'flex sub-icon-container ' + className}>
            {subImg && <img src={subImg} alt="" className='icon' />}
            {children}
        </div>
    )
}

function Arrow({ src, className }) {
    return <img src={DownArrow} alt="" className={'arrow ' + className} />
}

// ------ components ---------

function CardTitle({ title, chevron = 'chevron-down', eventKey, img, className = '' }) {
    return (
        <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
            <div className={'flex ' + className}>
                <img src={img} alt="" className="img" />
                <h2>{title}</h2>
                <HiChevronDown className={chevron} />
            </div>
        </Accordion.Toggle>
    )
}

function CardContent({ children, eventKey, className = '' }) {
    return (
        <Accordion.Collapse eventKey={eventKey}>
            <Card.Body className={className}>
                {children}
            </Card.Body>
        </Accordion.Collapse>
    )
}


export default Safety
