import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap'
import { api } from '../api';
import Hero from '../assets/contact-bg.jpg'

const Contact = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [error, setError] = useState(false);
    
    const contact = (e) => {
        e.preventDefault();
        axios.post(api + '/contact', {
            name: name,
            email: email,
            message: message,
            isApp: false
        })
        .then(function (res) {
            window.location.href = '/';
        })
        .catch(function (error) {
            setError(true);
        });
    }

    return (
        <>
        <section className="blue-overlay app-form-area contact-section" style={{backgroundImage: 'url(' + Hero + ')'}}>
        {/* blue-overlay contact-section app-form-area section-padding*/}
            <Form onSubmit={contact}>
                <div className="form-module background-transparent">
                    <h2>Contact us</h2>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control className="form-control" placeholder="Name" onChange={(e) => setName(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control as="textarea" className="form-control" rows={6} placeholder="Any comments, questions, or feedback? We’d love to hear it - type it here!" onChange={(e) => setMessage(e.target.value)}/>
                    </Form.Group>
                    <p className="padding-bottom color-orange">By submitting this form you agree to Pocketask's <a href="/privacy">privacy policy.</a></p>
                    {error && (
                        <Alert variant={"danger"}>Something went wrong!</Alert>
                    )}
                    <button type="submit">Submit</button>
                </div>
            </Form>
        </section>
        </>
    )
}

export default Contact
