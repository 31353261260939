import React, { useCallback, useEffect } from 'react'
import { TasksTab } from '../../components'
import { isLoggedIn } from '../../utils'
import Layout from './Layout'
import { OrganisationTabs } from '../../components'

/**
 * Tasks view for supervisors
 */
export default function Tasks({ user, setUser }) {
    // check if user is logged in only if props change 
    const LoggedIn = useCallback(() => isLoggedIn(setUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])

    return (
        <Layout>
            <TasksTab />
            <OrganisationTabs/>
        </Layout>
    )
}