import React, { useEffect, useState } from 'react'
import { Row, Col, Nav, Button, Container, Table } from 'react-bootstrap'
import { Divider } from '.'
import { CustomDiv } from './CustomStyling'
import { useRouteMatch } from 'react-router'
import { Flex } from './CustomStyling'
import axios from 'axios'
import { PlatformApi } from '../../api'
import { acceptTasker, finishTask, taskDecline } from '../utils'
import { Modal } from './'

export default function TasksTabOrganisation() {
    const [currentPage, setCurrentPage] = useState(0)
    const [waitingConfirmationTasks, setWaitingConfirmationTasks] = useState([])
    const [acceptedTasks, setAcceptedTasks] = useState([])
    const [waitingVerificationTasks, setWaitingVerificationTasks] = useState([])
    const [verifiedTasks, setVerifiedTasks] = useState([])
    let { path } = useRouteMatch()
    const [currentTab, setCurrentTab] = useState()
    function onSelect(selectedTab) {
        setCurrentTab(selectedTab)
    }

    const fetchTasks = async () => {
        try {
            const requestValuesWaitingConfirmation = {
                "pageNumber": currentPage,
                "search": "",
                "status": [1],
                "filters": {},
            }

            const requestValuesAccepted = {
                "pageNumber": currentPage,
                "search": "",
                "status": [2, 3, 4],
                "filters": {},
            }

            const requestValuesWaitingVerification = {
                "pageNumber": currentPage,
                "search": "",
                "status": [5],
                "filters": {},
            }
            const requestValuesVerified = {
                "pageNumber": currentPage,
                "search": "",
                "status": [6],
                "filters": {},
            }

            const requestWaitingConfirmation = await axios.post(`${PlatformApi}/task/my_tasks`, { ...requestValuesWaitingConfirmation }, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })

            const requestAccepted = await axios.post(`${PlatformApi}/task/my_tasks`, { ...requestValuesAccepted }, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })

            const requestWaitingVerification = await axios.post(`${PlatformApi}/task/my_tasks`, { ...requestValuesWaitingVerification }, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })
            const requestVerified = await axios.post(`${PlatformApi}/task/my_tasks`, { ...requestValuesVerified }, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })

            setWaitingConfirmationTasks(current => {
                return current.concat(requestWaitingConfirmation.data.data)
            })

            setAcceptedTasks(current => {
                return current.concat(requestAccepted.data.data)
            })

            setWaitingVerificationTasks(current => {
                return current.concat(requestWaitingVerification.data.data)
            })

            setVerifiedTasks(current => {
                return current.concat(requestVerified.data.data)
            })
            console.log("requestWaitingConfirmation tasks: ", requestWaitingConfirmation.data.data)
            console.log("requestAccepted tasks: ", requestAccepted.data.data)
            console.log("requestWaitingVerification tasks: ", requestWaitingVerification.data.data)
            return {
                requestWaitingConfirmation,
                requestAccepted,
                requestWaitingVerification,
                requestVerified
            }
        } catch (err) {
            console.log("Error: ", err)
        }
    }

    useEffect(() => {
        fetchTasks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])
    return (
        <React.Fragment>
            <Divider height='30px' />
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Nav fill variant="pills" defaultActiveKey="WAITING_CONFIRMATION" onSelect={onSelect}>
                        <Nav.Item>
                            {/* {accept a tasker to do a task} */}
                            <Nav.Link eventKey="WAITING_CONFIRMATION">Waiting for confirmation ({waitingConfirmationTasks.length})</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            {/* current accepted tasks */}
                            <Nav.Link eventKey="ACCEPTED_TASKS">Accepted tasks ({acceptedTasks.length})</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            {/* {action to verify that the tasker did the task} */}
                            <Nav.Link eventKey="WAITING_VERIFICATION">Waiting for verification ({waitingVerificationTasks.length})</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            {/* {action to verify that the tasker did the task} */}
                            <Nav.Link eventKey="VERIFIED_TASKS">Verified tasks ({verifiedTasks.length})</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Divider height='40px' />
                    <TabSelector currentTab={currentTab} path={path} waitingConfirmationTasks={waitingConfirmationTasks} acceptedTasks={acceptedTasks} waitingVerificationTasks={waitingVerificationTasks} verifiedTasks={verifiedTasks} setCurrentPage={setCurrentPage} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

function TabSelector({ currentTab, waitingConfirmationTasks = [], acceptedTasks = [], waitingVerificationTasks = [],verifiedTasks = [], setCurrentPage, ...otherProps }) {
    switch (currentTab) {
        case "WAITING_CONFIRMATION":
            return <WaitingConfirmation tasks={waitingConfirmationTasks} setCurrentPage={setCurrentPage} />
        case "ACCEPTED_TASKS":
            return <AcceptedTasks tasks={acceptedTasks} setCurrentPage={setCurrentPage} />
        case "WAITING_VERIFICATION":
            return <WaitingForVerification tasks={waitingVerificationTasks} setCurrentPage={setCurrentPage} />
        case "VERIFIED_TASKS":
            return <VerifiedTasks tasks={verifiedTasks} setCurrentPage={setCurrentPage} />
        default:
            return <WaitingConfirmation tasks={waitingConfirmationTasks} setCurrentPage={setCurrentPage} />
    }
}


function AcceptedTasks({ tasks = [], setCurrentPage }) {
    return (
        <Container>
            <h5 style={{padding: '0.5rem 0'}}>Fully accepted tasks waiting to start</h5>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Tasker</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks.map((item, idx) => {
                            console.log('accepted task: ', item)
                            return (
                                <tr key={`accepted-tasker-item-${idx}`}>
                                    <td>{item.title}</td>
                                    <td>{item.tasker?.firstName} {item.tasker?.lastName}</td>
                                    <td>{item.dateStart}</td>
                                    <td>{item.dateEnd}</td>
                                    <td>{item.timeStart}</td>
                                    <td>{item.timeEnd}</td>
                                    {/* <td>
                                        <Flex className='center space-around'>
                                            <Button variant='transparent'>
                                                <Trash />
                                            </Button>
                                            <Button variant='transparent'>
                                                <Pen />
                                            </Button>
                                        </Flex>
                                    </td> */}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <CustomDiv width='100%'>
                <Button onClick={() => setCurrentPage(current => current + 1)}>Load More</Button>
            </CustomDiv>
        </Container>
    )
}

function WaitingForVerification({ tasks = [], setCurrentPage }) {
    return (
        <Container>
            <h5 style={{padding: "0.5rem 0"}}>Please verify that the following hours were completed by the teenagers</h5>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <td>Tasker</td>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks.map((item, idx) => {
                            return (
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{item.tasker.username}</td>
                                    <td>{item.dateStart}</td>
                                    <td>{item.dateStart}</td>
                                    <td>{item.timeStart}</td>
                                    <td>{item.timeEnd}</td>
                                    <td>
                                        <Flex className='center space-around'>
                                            <Button variant='success' onClick={() => finishTask(item.id)}>
                                                Verify
                                            </Button>
                                        </Flex>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <CustomDiv width='100%'>
                <Button onClick={() => setCurrentPage(current => current + 1)}>Load More</Button>
            </CustomDiv>
        </Container>
    )
}

function VerifiedTasks({ tasks = [], setCurrentPage }) {
    return (
        <Container>
            <Divider height='15px'/>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <td>Tasker</td>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks.map((item, idx) => {
                            return (
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{item.tasker.username}</td>
                                    <td>{item.dateStart}</td>
                                    <td>{item.dateStart}</td>
                                    <td>{item.timeStart}</td>
                                    <td>{item.timeEnd}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <CustomDiv width='100%'>
                <Button onClick={() => setCurrentPage(current => current + 1)}>Load More</Button>
            </CustomDiv>
        </Container>
    )
}

function WaitingConfirmation({ tasks = [], setCurrentPage }) {
    const [acceptTaskerModal, setAcceptTaskerModal] = useState(false)
    const [currentTask, setCurrentTask] = useState("")
    return (
        <Container>
            <h5 style={{padding: '0.5rem 0'}}>This is where you accept the teeangers that have accepted your volunteering tasks</h5>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Tasker</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasks.map((item, idx) => {
                            return (
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{item.tasker?.username}</td>
                                    <td>{item.dateStart}</td>
                                    <td>{item.dateEnd}</td>
                                    <td>{item.timeStart}</td>
                                    <td>{item.timeEnd}</td>
                                    <td>
                                        <Flex className='center space-around'>
                                            <Button variant='success' onClick={() => {
                                                setAcceptTaskerModal(true)
                                                setCurrentTask(item)
                                            }
                                            }>
                                                View Tasker
                                            </Button>
                                        </Flex>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            <CustomDiv width='100%'>
                <Button onClick={() => setCurrentPage(current => current + 1)}>Load More</Button>
            </CustomDiv>
            <AcceptATaskerModal task={currentTask} show={acceptTaskerModal} onHide={() => setAcceptTaskerModal(false)} />

        </Container>
    )
}

function AcceptATaskerModal({ show, onHide, task }) {

    return (
        <Modal title={task.title} show={show} onHide={onHide} buttons={[
            {
                variant: 'danger',
                text: 'Decline',
                onClick: () => taskDecline(task.id)
            },
            {
                variant: 'success',
                text: 'Accept',
                onClick: () => acceptTasker(task.id)
            }
        ]}>
            <Divider height='30px' />
            <Row>
                <p>Description: {task.details?.info}</p>
                <p>Start date: {task.dateStart}</p>
                <p>End date: {task.dateEnd}</p>
                <p>Time: {task.time}</p>
                <p>Tasker</p>
                <img alt="" src={task.tasker?.image} style={{height:'50px',width:'74px',borderRadius:'50%'}}/>
                <p>Username: {task.tasker?.username}</p>
                <p>Name: {task.tasker && task.tasker.firstName + ' ' + task.tasker.lastName}</p>
                <p>Age: {task.tasker?.age }</p>
                <p>Rating: {task.tasker?.rating }</p>
            </Row>
        </Modal>
    )
}