import React, { useCallback, useEffect } from 'react'
import { isLoggedIn } from '../../utils'
import Layout from './Layout'
import { OrganisationTabs, TasksTabOrganisation } from '../../components'


/**
 * Tasks view for organisations, this lists all of the waiting for confirmation, accepted, waiting for verification and verified tasks
 */
export default function Tasks({ user, setUser }) {
    // check if user is logged in only if props change 
    const LoggedIn = useCallback(() => isLoggedIn(setUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])

    return (
        <Layout>
            <TasksTabOrganisation />
            <OrganisationTabs/>
        </Layout>
    )
}