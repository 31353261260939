import { Switch, useRouteMatch, Route } from 'react-router'
import { Row, Col } from 'react-bootstrap'
import Home from './Home'
import Profile from './Profile'
import Tasks from './Tasks'
import Notifications from './Notifications'

export default function PanelRouterOrganisation({user, ...props}) {
  let { path } = useRouteMatch()
  return (
      <Row>
          <Col>
              <Switch>
                  <Route path={`${path}/home`}>
                      <Home {...props} />
                  </Route>
                  <Route path={`${path}/tasks`}>
                      <Tasks {...props} />
                  </Route>
                  <Route path={`${path}/profile`}>
                      <Profile {...props} />
                  </Route>
                  <Route path={`${path}/notifications`}>
                      <Notifications {...props} />
                  </Route>
              </Switch>
          </Col>
      </Row>
  )
}
