import { CustomDiv, GridContainer, GridItemFirst, GridItemLast } from './CustomStyling'
import { Phone } from 'react-bootstrap-icons'
import { Col, Form } from 'react-bootstrap'

export function InfoCard({ item = '', title = '', data = '', image = ''}) {
    return (
        <CustomDiv width='100%' textAlign='left'>
            <GridContainer>
                <GridItemFirst>
                    <img src={image} alt=""/>
                </GridItemFirst>
                <GridItemLast>
                    <h5>{item}</h5>
                    <h6>{title}</h6>
                    <p>{data}</p>
                </GridItemLast>
            </GridContainer>
        </CustomDiv>
    )
}

export function InfoCardEdit({ handleChange, name='', type = '', placeholder = '', item = '', data = '', Icon = () => <Phone /> }) {
    return (
        <CustomDiv width='90%' margin='10px 0' textAlign='left'>
            <GridContainer columnGap='1em' growGap='1em'>
                <GridItemFirst>
                    <Icon className='icon' />
                </GridItemFirst>
                <GridItemLast>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{item}</Form.Label>
                        <Form.Control onChange={handleChange} name={name} size="sm" type={type} placeholder={placeholder} defaultValue={data} />
                    </Form.Group>
                </GridItemLast>
            </GridContainer>
        </CustomDiv>
    )
}
export function InfoCardSelect({ handleChange, name='', type = '', placeholder = '', item = '', data = '', Icon = () => <Phone /> }) {
    return (
        <CustomDiv width='90%' margin='10px 0' textAlign='left'>
            <GridContainer columnGap='1em' growGap='1em'>
                <GridItemFirst>
                    <Icon className='icon' />
                </GridItemFirst>
                <GridItemLast>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{item}</Form.Label>
                        <Form.Control as="select" onChange={handleChange} name={name} size="sm" type={type} placeholder={placeholder} defaultValue={data} >
                            <option value={0}>Male</option>
                          <option value={1}>Female</option>
                        </Form.Control>
                    </Form.Group>
                </GridItemLast>
            </GridContainer>
        </CustomDiv>
    )
}