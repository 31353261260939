import axios from 'axios'
import React, { useState } from 'react'
import { Form, Alert } from 'react-bootstrap'
import { api } from '../../api'

const Babysitting = () => {
    const [date, setDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [userAddress, setUserAddress] = useState();
    const [userState, setUserState] = useState();
    const [userZipCode, setUserZipCode] = useState();
    const [rateType, setRateType] = useState('flat');
    const [rate, setRate] = useState();
    const [additionalInfo, setAdditionalInfo] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const user = localStorage.getItem('user');
    const [kids, setKids] = useState();
    const [error, setError] = useState(false);
    const [errorDesc, setErrorDesc] = useState('Something went wrong');
    const [babysitType, setBabysitType] = useState('inperson');
    const [transport, setTransport] = useState('');
    const [checkedItems, setCheckedItems] = useState({});
    const [checkedAges, setCheckedAges] = useState({});

    const checkboxes = [
        {
            name: 'cleaning',
            label: 'Cleaning',
        },
        {
            name: 'cooking',
            label: 'Cooking',
        },
        {
            name: 'playing',
            label: 'Playing',
        },
        {
            name: 'driving',
            label: 'Driving',
        },
        {
            name: 'homework',
            label: 'Homework',
        }
    ];

    const ageRanges = [
        {
            name: 'infant',
            label: 'Infant (0-2)',
        },
        {
            name: 'juniorhigh',
            label: 'Junior High (11+)',
        },
        {
            name: 'elementary',
            label: 'Elementary (6-10)',
        },
        {
            name: 'preschool',
            label: 'Pre-school (3-5)',
        }
    ];

    const getLocation = (address, zipCode, state) => {
        if(address) {
            if(zipCode) {
                if(state) {
                    return address + ', ' + zipCode + ', ' + state;
                }
                return address + ', ' + zipCode;
            } else {
                if(state) {
                    return address + ', ' + state;
                }
                return address;
            }
        } else {
            if(zipCode) {
                if(state) {
                    return zipCode + ', ' + state;
                }
                return zipCode;
            } else {
                if(state) {
                    return state
                }
                return address;
            }
        }
    }

    const SubmitTask = (e) => {
        e.preventDefault();
        const headers = {
            'authorization': 'Bearer ' + localStorage.getItem('token'),
            'content-type': 'application/json'
        }
        axios.post(api + '/task/create', {
            taskType: 'babysitting',
            date: date,
            startTime: startTime,
            endTime: endTime,
            location: getLocation(userAddress, userZipCode, userState),
            rateType: rateType,
            rate: rate,
            additionalInfo: additionalInfo,
            email: email,
            phone: phone,
            user: user,
            babysitting: {
                responsibilities: checkedItems,
                type: babysitType,
                kids: kids,
                ages: checkedAges
            },
            transportation: transport
        }, {headers})
        .then(function (res) {
            window.location.href = "/t/" + res.data.task._id;
        })
        .catch(function (e) {
            if (e.response) {
                setErrorDesc(e.response.data.error)
            }
            setError(true);
            console.log(errorDesc);
        });
    }

    const checkChange = (e) => {
        setCheckedItems({...checkedItems, [e.target.name] : e.target.checked });
    }

    const checkAges = (e) => {
        setCheckedAges({...checkedAges, [e.target.name] : e.target.checked });
    }

    return (
        <div className="container login-section container-task specific-task">
            <div className="task-create">
                <h2>Create <span>Babysitting</span> task</h2>
                <hr />
                <Form onSubmit={SubmitTask}>
                <div className="task-create-when">
                    <h4>When</h4>
                    <div className="container row">
                        <div className="col-lg-4 col-sm-12 col-12">
                            <div className="inline-label">
                                <p>Date</p>
                            </div>
                            <div className='required-form'>
                                <Form.Control type="date" onChange={(e) => setDate(e.target.value)} />
                                <span>*</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-6">
                            <div className="inline-label">
                                <p>from</p>
                            </div>
                            <div className='required-form'>
                                <Form.Control type="time" onChange={(e) => setStartTime(e.target.value)} />
                                <span>*</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-6">
                            <div className="inline-label">
                                <p>to</p>
                            </div>
                            <div className='required-form'>
                                <Form.Control type="time" onChange={(e) => setEndTime(e.target.value)} />
                                <span>*</span>
                            </div>
                        </div> 
                    </div>
                </div>
                    <hr/>
                    <div className="task-create-where">
                        <h4>Where</h4>
                        <div className="container row">
                            <div className="col-lg-3 col-6">
                                <Form.Control as="select" onChange={(e) => setBabysitType(e.target.value)}>
                                    <option value="inperson">In Person</option>
                                    <option value="virtual">Virtual</option>
                                </Form.Control>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Form.Control type="text" placeholder="Address" onChange={(e) => setUserAddress(e.target.value)} />
                            </div>
                            <div className="col-lg-3 col-6">
                                <Form.Control type="text" placeholder="Zip Code" onChange={(e) => setUserZipCode(e.target.value)} />
                            </div>
                            <div className="col-lg-3 col-6">
                                <Form.Control type="text" as="select" placeholder="State" onChange={(e) => setUserState(e.target.value)}>
                                    <option value="Alabama">Alabama</option><option value="Alaska">Alaska</option><option value="Arizona">Arizona</option>
                                    <option value="Arkansas">Arkansas</option><option value="California">California</option><option value="Colorado">Colorado</option>
                                    <option value="Connecticut">Connecticut</option><option value="Delaware">Delaware</option><option value="Florida">Florida</option>
                                    <option value="Georgia">Georgia</option><option value="Hawaii">Hawaii</option><option value="Idaho">Idaho</option>
                                    <option value="Illinois">Illinois</option><option value="Indiana">Indiana</option><option value="Iowa">Iowa</option>
                                    <option value="Kansas">Kansas</option><option value="Kentucky">Kentucky</option><option value="Louisiana">Louisiana</option>
                                    <option value="Maine">Maine</option><option value="Maryland">Maryland</option><option value="Massachusets">Massachusets</option>
                                    <option value="Michigan">Michigan</option><option value="Minnesota">Minnesota</option><option value="Mississippi">Mississippi</option>
                                    <option value="Missouri">Missouri</option><option value="Montana">Montana</option><option value="Nebraska">Nebraska</option>
                                    <option value="Nevada">Nevada</option><option value="New Hampshire">New Hampshire</option><option value="New Jersey">New Jersey</option>
                                    <option value="New Mexico">New Mexico</option><option value="New York">New York</option><option value="North Carolina">North Carolina</option>
                                    <option value="North Dakota">North Dakota</option><option value="Ohio">Ohio</option><option value="Oklahoma">Oklahoma</option>
                                    <option value="Oregon">Oregon</option><option value="Pennsylvania">Pennsylvania</option><option value="Rhode Island">Rhode Island</option>
                                    <option value="South Carolina">South Carolina</option><option value="South Dakota">South Dakota</option><option value="Tennessee">Tennessee</option>
                                    <option value="Texas">Texas</option><option value="Utah">Utah</option><option value="Vermont">Vermont</option>
                                    <option value="Virginia">Virginia</option><option value="Washington">Washington</option><option value="West Virginia">West Virginia</option>
                                    <option value="Wisconsin">Wisconsin</option><option value="Wyoming">Wyoming</option>
                                </Form.Control>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="task-create-rate">
                        <h4>Rate</h4>
                        <div className="container row">
                            <div className="col-4">
                                <div className="dollar-sign padding-left">
                                    <div>$</div>
                                    <Form.Control className="prepend-control" placeholder="Amount" onChange={(e) => setRate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="dollar-sign">
                                    <Form.Control as="select" onChange={(e) => setRateType(e.target.value)}>
                                        <option value="hourly">/hr</option>
                                        <option value="flat">total</option>
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="col-4"></div>
                        </div>
                    </div>
                    <hr />
                    <div className="task-create-details">
                        <h4>Details</h4>
                        <div className="details-row">
                            <div className="details-small">
                                <div className="label-field"># of kids</div>
                                <div className="details-col">
                                    <Form.Control as="select" onChange={(e) => setKids(e.target.value)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="5+">5+</option>
                                    </Form.Control>
                                </div>
                            </div>
                        </div>
                        <div className="details-row margin-top">
                            <div className="details-large">
                                <div className="label-field">Ages</div>
                                <div className="details-col res-col row-four">
                                    {
                                    ageRanges.map(item => (
                                        <label className="custom-input" key={item.name}>
                                            <input name={item.name} checked={checkedAges[item.name]} type="checkbox" onChange={checkAges} />
                                            <div className="box">
                                            {item.label}
                                            </div>
                                        </label>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="details-row margin-top">
                            <div className="details-large">
                                <div className="label-field">Responsibilities</div>
                                <div className="details-col res-col resp-col">
                                    {
                                    checkboxes.map(item => (
                                        <label className="custom-input" key={item.name}>
                                            <input name={item.name} checked={checkedItems[item.name]} type="checkbox" onChange={checkChange} />
                                            <div className="box small-font">
                                            {item.label}
                                            </div>
                                        </label>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="details-row margin-top">
                            <div className="details-large">
                                <div className="label-field">I can provide transportation</div>
                                <div className="details-col res-col trans-col row-four">
                                <label className="custom-input">
                                    <input type="radio" name="transport" value="beforejob" onChange={(e) => setTransport(e.target.value)} />
                                    <div className="box">
                                    Before Job
                                    </div>
                                </label>
                                <label className="custom-input">
                                    <input type="radio" name="transport" value="afterjob" onChange={(e) => setTransport(e.target.value)} />
                                    <div className="box">
                                    After Job
                                    </div>
                                </label>
                                <label className="custom-input">
                                    <input type="radio" name="transport" value="beforeandafter" onChange={(e) => setTransport(e.target.value)} />
                                    <div className="box">
                                    Before and After
                                    </div>
                                </label>
                                <label className="custom-input">
                                    <input type="radio" name="transport" value="cantprovide" onChange={(e) => setTransport(e.target.value)} />
                                    <div className="box">
                                    Can't Provide
                                    </div>
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="task-create-info">
                        <h4>Additional Info</h4>
                        <Form.Group>
                            <textarea name="" id="" rows="3" placeholder="Anything else your tasker may need to know to perform your task as safely and as efficiently as possible?" className="form-control" onChange={(e) => setAdditionalInfo(e.target.value)}></textarea>
                        </Form.Group>
                    </div>
                    <hr />
                    <div className="task-create-contact">
                        <h4>Contact</h4>
                        <div className="container row">
                            <div className="col-6">
                                <div className="task-email">Email</div>
                                <Form.Control type="email" placeholder="johndoe@contact.com"  onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="col-6">
                                <div className="task-phone">Phone</div>
                                <Form.Control type="text" placeholder="(XXX) XXX - XXX" onChange={(e) => setPhone(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <p className="padding-bottom">By creating this task you agree to Pocketask's <a href="/privacy">privacy policy.</a></p>
                    {error && (
                        <Alert variant={"danger"} className="task-alert">{errorDesc}</Alert>
                    )}
                    <button className="login-submit" type="submit">
                        Create
                    </button>
                </Form>
            </div>
        </div>
    )
}

export default Babysitting
