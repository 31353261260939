import React, {useEffect, useCallback, useState} from 'react'
import axios from 'axios'
import { PlatformApi } from '../../api'
import {Modal} from '../components'
import { InfoCard } from './InfoCards'
import { Archive, ArchiveFill, Clock, ClockFill, ClockHistory, Map, MapFill, Pin } from 'react-bootstrap-icons'
import Divider from './Divider'
export default function ZoomedInTask({show = '', onHide='', id}){
    const [task, setTask] = useState([])
    async function taskRequest(){
        const request = await axios.post(`${PlatformApi}/task/task_details`, {taskId: id}, {
            headers: {
                "X-AUTH-TOKEN": localStorage.getItem("api_token")
            }
        })
        setTask(request.data.data)
        console.log(request.data.data)
        return request.data.data
    }
    const tasksHook = useCallback(() => taskRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [id])

    useEffect(() => {
        if (id !== "")
            tasksHook()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])


    return (
        <Modal show={show} onHide={onHide} title={`${task.title} - Details`}>
            <Divider height='20px'/>
            <InfoCard item="title" data={task.title} Icon={() => <ArchiveFill className='icon'/>}/>
            <InfoCard item="Start date" data={task.dateStart} Icon={() => <Clock className='icon'/>}/>
            <InfoCard item="End date" data={task.dateEnd} Icon={() => <ClockFill className='icon'/>}/>
            <InfoCard item="Total hours" data={task.hoursTotal} Icon={() => <ClockHistory className='icon'/>}/>
            <InfoCard item="Task Address" data={task.homeAddress?.address} Icon={() => <Pin className='icon'/>}/>
            <InfoCard item="Task state" data={task.homeAddress?.state} Icon={() => <Map className='icon'/>}/>
            <InfoCard item="Zip Code" data={task.homeAddress?.zipCode} Icon={() => <MapFill className='icon'/>}/>
            <InfoCard item="Details" data={task.details?.info} Icon={() => <Archive className='icon'/>}/>
        </Modal>
    )
}