import React, { useState } from 'react'
import axios from 'axios';
import { api } from '../api';
import { Alert, Form } from 'react-bootstrap'
import Hero from '../assets/app-bg.jpg'
import { usStates } from "../const";


const AppPage = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const country = 'US' ;
    const age = '';
    const [state, setState] = useState('AL');
    const [isTasker, setIsTasker] = useState(false);
    const [hiresTasker, setHiresTasker] = useState(false);
    const [message, setMessage] = useState();
    // const [timerDays, setTimerDays] = useState('00');
    // const [timerHours, setTimerHours] = useState('00');
    // const [timerMinutes, setTimerMinutes] = useState('00');
    // const [timerSeconds, setTimerSeconds] = useState('00');
    const [heard, setHeard] = useState('');
    const [error, setError] = useState(false);

    const contact = (e) => {
        e.preventDefault();
        let role = '';
        if (isTasker) {
            role = 'tasker'
        } else if (hiresTasker) {
            role = 'parent'
        }
        axios.post(api + '/contact', {
            name: name,
            email: email,
            age: age,
            country: country,
            state: state,
            role: role,
            heard: heard,
            message: message,
            isApp: true
        })
            .then(function (res) {
                window.location.href = '/app';
            })
            .catch(function (error) {
                setError(true);
            });
    }

    // const startTimer = () => {

    //     interval = setInterval(() => {
    //         const now = new Date().getTime();
    //         const difference = countdownDate - now;

    //         const days = Math.floor(difference / 86400000);
    //         const hours = Math.floor(difference % 86400000 / 3600000);
    //         const minutes = Math.floor(difference % 3600000 / 60000);
    //         const seconds = Math.floor(difference % 60000 / 1000);

    //         if (difference < 0) {
    //             // stop timer
    //             clearInterval(interval.current)
    //         } else {
    //             setTimerDays(days);
    //             setTimerHours(convertNum(hours));
    //             setTimerMinutes(convertNum(minutes));
    //             setTimerSeconds(convertNum(seconds));
    //         }
    //     }, 1000)
    // }
    // useEffect(() => {
    //     startTimer();
    //     return () => {
    //         clearInterval(interval.current);
    //     }
    // })

    return (
        <>
            <section className="section-padding blue-overlay hero-area-app countdown-section" style={{ backgroundImage: 'url(' + Hero + ')' }}>
                {/* blue-overlay */}
                <h1>The <span>Pocketask App</span> is already here in Hyde Park Chicago!</h1>
                <h4>We will be launching in other Chicago communities soon: <span><strong>Kenwood, Grand Boulevard, Douglas.</strong></span></h4>
                {
                    /*
                    <div className="countdown-container">
                    <span><h1><b>{timerDays}</b></h1><h5>Days</h5></span>
                    <span className="colon"><h2>:</h2></span>
                    <span><h1><b>{timerHours}</b></h1><h5>Hours</h5></span>
                    <span className="colon"><h2>:</h2></span>
                    <span><h1><b>{timerMinutes}</b></h1><h5>Minutes</h5></span>
                    <span className="colon"><h2>:</h2></span>
                    <span><h1><b>{timerSeconds}</b></h1><h5>Seconds</h5></span>
                </div>   
                    */
                }
                <section className='section-padding'>
                    <a href="https://apps.apple.com/us/app/pocketask/id1569456001" spy={true} smooth={true}><button>Download the App</button></a>
                </section>
            </section>
            {/* <section className="app-preview section-padding bgwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 my-auto">
                            <h2><span>Teens - Do a pocketask, make pocket money</span></h2>
                            <ol>
                                <li>Personalize and grow your network of trusted job-posters.</li>
                                <li>Choose from a variety of tasks.</li>
                                <li>Find tasks that work with your schedule.</li>
                                <li>Complete your task stress-free with our live task safety features.</li>
                            </ol>
                        </div>
                        <div className="col-md-5 text-center">
                            <img src={Slide1} alt="A mobile app for earning pocket money as a teenager" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="app-preview section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 text-center second-img">
                            <img src={Slide2} alt="A mobile app for hiring teenagers you trust" />
                        </div>
                        <div className="col-md-7 my-auto">
                            <h2>Adults - Save time by creating a pocket full of tasks</h2>
                            <ol>
                                <li>Personalize and grow your network of trusted teens.</li>
                                <li>Use our custom templates to centralize all your task details.</li>
                                <li>Get quick responses from teens via our unique trust algorithm.</li>
                                <li>Ensure your task is effectively and safely completed with our live task features.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="app-form-area section-padding app-contact" id="signup">
                <Form onSubmit={contact}>
                    <div className="form-module pre-register-form">
                        <h2>Be the first to get the app in your community</h2>

                        <fieldset>


                            <div className="required-form">
                                <input type="text" className="form-control" placeholder="Name" onChange={(e) => setName(e.target.value)} required />
                                <span>*</span></div>
                            <div className="required-form">
                                <input type="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
                                <span>*</span></div>

                            {/* <div className="required-form">
                                <Form.Control className="form-control" required as="select" value={age} onChange={(e) => {
                                    setAge(e.target.value)
                                    setState('')
                                }}>
                                    <option value="" disabled={true}>Please select</option>
                                    {
                                        Array.from(Array(89).keys()).map((obj, i) => (
                                            <option value={i + 1}>{i + 1}</option>
                                        ))
                                    }
                                </Form.Control>
                                <span>*</span></div>
                            */}
                            {/* <div className="required-form">
                                <Form.Control className="form-control" as="select" value={country} onChange={(e) => {
                                    setCountry(e.target.value)
                                    setState('')
                                }}>
                                    {
                                        countries.map((obj, i) => (
                                            <option value={obj['code']}>{obj['name']}</option>
                                        ))
                                    }
                                </Form.Control>
                                <span>*</span></div> */}

                            {
                                country === "US" ?
                                    <div className="required-form">
                                        <Form.Control className="form-control" as="select" value={state}
                                            onChange={(e) => setState(e.target.value)}>
                                            {
                                                usStates.map((obj, i) => (
                                                    <option value={obj['code']}>{obj['name']}</option>
                                                ))
                                            }
                                        </Form.Control>
                                        <span>*</span></div>
                                    : null
                            }

                        </fieldset>
                        <fieldset>
                            <div className="required-form">
                                <Form.Group>
                                    <Form.Label>I want to...</Form.Label>
                                    <Form.Check type="radio" label="Become a tasker" name="become-hire-tasker" id="becomeTasker" onChange={(e) => setIsTasker(!isTasker)} required />
                                    <Form.Check type="radio" label="Hire a tasker" name="become-hire-tasker" id="hireTasker" onChange={(e) => setHiresTasker(!hiresTasker)} required />
                                </Form.Group>
                                <span>*</span></div>
                        </fieldset>
                        <fieldset>
                            <textarea className="form-control" rows="3" placeholder="Any comments, questions, or feedback? We’d love to hear it - type it here!" onChange={(e) => setMessage(e.target.value)} />
                        </fieldset>
                        <fieldset>
                            <Form.Group>
                                <Form.Label>How did you hear about us?</Form.Label>
                                <Form.Control className="form-control" as="select" value={heard} onChange={(e) => setHeard(e.target.value)}>
                                    <option disabled={true} value="">-- Select --</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Email">Email</option>
                                    <option value="WebSearch">Web Search</option>
                                    <option value="Friend">Friend</option>
                                    <option value="Other">Other</option>
                                </Form.Control>
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <Form.Group className="mailing-list">
                                <Form.Check type="checkbox" className="mailing-check" label="I want to get updates from Pocketask" name="mailing-list" id="mailingList" checked />
                            </Form.Group>
                        </fieldset>
                        {/* <input type="checkbox" className="mailing-list" name="mailing-list" checked/>
                    <label for="mailing-list" className="mailing-label">I want to get updates from Pocketask about the state of the app</label> */}
                        <p className="color-orange">By submitting this form you agree to Pocketask's <a href="/privacy">privacy policy.</a></p>
                        {error && (
                            <Alert variant={"danger"}>Something went wrong!</Alert>
                        )}
                        <button type="submit">Pre-register</button>
                    </div>
                </Form>
            </section>
        </>
    )
}

export default AppPage
