import React from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineInstagram, AiFillLinkedin, AiFillFacebook } from "react-icons/ai";

const Footer = () => {
    return (
        <footer>
            <ul className="footer-menu footer-socials">
                <li><a href="https://www.instagram.com/pocketask"><AiOutlineInstagram /></a></li>
                <li><a href="https://www.facebook.com/mypocketask"><AiFillFacebook /></a></li>
                {/*<li><a href="https://linkedin.com"><AiOutlineTwitter /></a></li> */}
                <li><a href="https://www.linkedin.com/company/pocketask/"><AiFillLinkedin /></a></li>
            </ul>
            <ul className="footer-menu">
                <li><Link to="/privacy">Privacy</Link></li>
                <li><p> | </p></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><p> | </p></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><p> | </p></li>
                <li><Link to="/terms-and-conditions">T & C</Link></li>
            </ul>
            <p>&copy; Pocketask {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer
