import { InfoCard, Divider, Flex } from "../../components";
import {getNotifications, isLoggedIn} from "../../utils";
import {Row, Col, Container, Card, Accordion, Button} from "react-bootstrap";
import Layout from "./Layout";
import {useCallback, useEffect, useState} from "react";

/**
 * Notifications layout for organisation
 * @returns 
 */
export default function Notifications({user, setUser}) {
    const [notifications, setNotifications] = useState([])
    useEffect(() => getNotifications(setNotifications), [])

    const LoggedIn = useCallback(() => isLoggedIn(setUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])

    return (
        <Layout>
            <Row>
                <Col>
                    <h5 style={{ padding: '.5rem' }}>Notifications</h5>
                </Col>
            </Row>
            <Divider background='#fff' height='10px' />
            <Divider background='#f0f0f0' height='1px' />
            <Divider background='#fff' height='15px' />
            <Row>
                <Col>
                    <Container>
                        <Accordion defaultActiveKey="0">
                            {
                                notifications.map(({ extra, ...notification }, idx) => (
                                    <Card key={idx}>
                                        <Accordion.Toggle as={Button} variant='link' eventKey={idx}>
                                            <Flex width='100%' className='space-between'>
                                                <div>
                                                    <span>{notification.title}</span>
                                                </div>
                                                <div>
                                                    <span style={{margin: "auto 20px"}}className='text-muted'>{notification.timeSpent}</span>
                                                </div>
                                            </Flex>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={idx}>
                                            <InfoCard 
                                                item={notification.username} 
                                                title={notification.title} 
                                                data={notification.timeSpent} 
                                                image={notification.image} />
                                        </Accordion.Collapse>
                                    </Card>
                                ))
                            }
                        </Accordion>
                        {notifications.length < 1 && <h5 style={{textAlign: 'center'}}>All clear! You have no new notifications</h5>}
                    </Container>
                </Col>
            </Row>
        </Layout>
    )
}