import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ColCustomed, CustomDiv, Button } from '../../components/CustomStyling'
import { Modal, InfoCard, InfoCardEdit } from '../../components'
import { Row, Col, Nav, Container, Form } from 'react-bootstrap'
import { isLoggedIn, editProfile } from '../../utils'
import Layout from './Layout'
import { Formik } from 'formik'
//icons
import { Code, Envelope, Person } from 'react-bootstrap-icons'
//contexts
import UserContext from '../../contexts/userContext'
import { Divider } from '../../components'


/**
 * Modal for profile edit for Supervisors
 */
const EditProfileModal = ({ show, onHide }) => {
    const { user } = useContext(UserContext)
    return (
        <Modal show={show} onHide={onHide} title={`${user.firstName} ${user.lastName}`}
        >
            <Container>
                <Row>
                    <Formik
                        initialValues={{ school_name: user.school_name, school_email: user.school_email, code: user.code }}
                        onSubmit={(values, { setSubmitting }) => {
                            editProfile(values)
                            onHide()
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleSubmit,
                        }) => (
                            <React.Fragment>
                                <Divider height='10px' />
                                <Form onSubmit={handleSubmit}>
                                    <InfoCardEdit values={values} name='email' handleChange={handleChange} type='text' placeholder='Email' item='Email' data={user.email} Icon={() => <Envelope className='icon' />} />
                                    <InfoCardEdit values={values} name='school_name' handleChange={handleChange} type='text' placeholder='School Name' item='School Name' data={user.school_name} Icon={() => <Person className='icon' />} />
                                    <InfoCardEdit values={values} name='school_email' handleChange={handleChange} type='text' placeholder='School Email' item='Email' data={user.school_email} Icon={() => <Envelope className='icon' />} />
                                    <Divider height='30px' />
                                    <CustomDiv width='100%'>
                                        <Button variant="primary" type="submit" style={{ marginLeft: '' }} >
                                            Save
                                        </Button>
                                    </CustomDiv>
                                </Form>
                            </React.Fragment>
                        )}
                    </Formik>
                </Row>
            </Container>
        </Modal>
    )
}


/**
 * Layout Wrapper for profile main view
 */
export default function Profile({ user, setUser }) {
    // check if user is logged in only if props change 
    const LoggedIn = useCallback(() => isLoggedIn(setUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])

    return (
        <Layout>
            <ProfileMain />
        </Layout>
    )
}


/**
 * Main View for supervisors profile
 */
function ProfileMain() {
    const [editProfileModal, setEditProfileModal] = useState(false)
    const { user } = useContext(UserContext)

    return (
        <>
            <ColCustomed background='transparent'>
                <div className='user-info'>
                    <Row>
                        <Col>
                            <h5 style={{ padding: '.5rem' }}>{user.firstName} {user.lastName}</h5>
                        </Col>
                        <Col>
                            <Nav className="justify-content-end" activeKey="/home">
                                <Button variant="outline-dark" onClick={() => setEditProfileModal(true)}>Edit</Button>
                            </Nav>
                        </Col>
                    </Row>
                    <Divider height='1px' background='#f0f0f0' margin='10px auto' />
                    <InfoCard item="username" data={user.username} Icon={() => <Person className='icon' />} />
                    <InfoCard item='Email' data={user.email} Icon={() => <Envelope className='icon' />} />
                    <InfoCard item='School Name' data={user.school_name} Icon={() => <Person className='icon' />} />
                    <InfoCard item='School Code' data={user.code} Icon={() => <Code className='icon' />} />
                </div>
            </ColCustomed>
            <EditProfileModal show={editProfileModal} onHide={() => setEditProfileModal(false)} />
        </>
    )
}
