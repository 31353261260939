import React, { useCallback, useEffect, useState } from 'react'
import { Button, Nav, Row, Col, Table, Container, Modal as BootstrapModal } from 'react-bootstrap'
import { confirmVolunteeringHours, isLoggedIn, unconfirmVolunteeringHours, supervisorTasks } from '../../utils'
import Layout from './Layout'
import { CustomDiv, NavLink } from '../../components/CustomStyling'

//icons
import { Divider, Flex } from '../../components'

export default function Home({ user, setUser }) {
    const [currentTaskId, setCurrentTaskId] = useState(1)
    const [currentIntervalIndex, setCurrentIntervalIndex] = useState(1)
    const [sureToVerifyModal, setSureToVerifyModal] = useState(false)
    const [sureToUnverifyModal, setSureToUnverifyModal] = useState(false)

    const LoggedIn = useCallback(() => isLoggedIn(setUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [user?.name])
    useEffect(() => {
        LoggedIn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.name])


    const [verifiedTasks, setVerifiedTasks] = useState([])
    const [unverifiedTasks, setUnverifiedTasks] = useState([])
    const [currentPageVerifiedTasks, setCurrentPageVerifiedTasks] = useState(0)
    const [currentPageUnverifiedTasks, setCurrentPageUnverifiedTasks] = useState(0)

    useEffect(() => {
        supervisorTasks(false, currentPageUnverifiedTasks, unverifiedTasks, setUnverifiedTasks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageUnverifiedTasks])
    useEffect(() => {
        supervisorTasks(true, currentPageVerifiedTasks, verifiedTasks, setVerifiedTasks)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageVerifiedTasks])


    return (
        <Layout>
            <Row>
                <Col>
                    <h5 style={{ padding: '.5rem' }}>Volunteer Hours</h5>
                </Col>
            </Row>
            <Divider background='#f0f0f0' height='1px' />
            <MainTabs verifiedTasks={verifiedTasks} unverifiedTasks={unverifiedTasks}
                setCurrentPageVerifiedTasks={setCurrentPageVerifiedTasks} setCurrentPageUnverifiedTasks={setCurrentPageUnverifiedTasks}
                setSureToVerifyModal={setSureToVerifyModal} setCurrentTaskId={setCurrentTaskId} setCurrentIntervalIndex={setCurrentIntervalIndex} setSureToUnverifyModal={setSureToUnverifyModal} />
            <ActionModal
                action={() => unconfirmVolunteeringHours(currentTaskId, currentIntervalIndex)}
                setCurrentTaskId={setCurrentTaskId}
                show={sureToUnverifyModal}
                setShow={setSureToUnverifyModal}
                currentTaskId={currentTaskId}
                modalContent={{ title: '¿Unverify Task?', buttonLabel: 'Unverify' }}
            />
            <ActionModal
                action={() => confirmVolunteeringHours(currentTaskId, currentIntervalIndex)}
                setCurrentTaskId={setCurrentTaskId}
                show={sureToVerifyModal}
                setShow={setSureToVerifyModal}
                currentTaskId={currentTaskId}
                modalContent={{ title: 'Verify Task?', buttonLabel: 'Verify' }}
            />
        </Layout>
    )
}


/**
 * Selector for the tab that a user clicks on
 */
function TabSelector({ currentTab, verifiedTasks = [], unverifiedTasks = [], setCurrentTaskId, setCurrentIntervalIndex, ...otherProps }) {
    switch (currentTab) {
        case "VERIFIED":
            return <ConfirmedStudents currentTab={currentTab} students={verifiedTasks} setCurrentTaskId={setCurrentTaskId} {...otherProps} />
        case "NOT_VERIFIED":
            return <UnconfirmedStudents currentTab={currentTab} students={unverifiedTasks} setCurrentTaskId={setCurrentTaskId} setCurrentIntervalIndex={setCurrentIntervalIndex} {...otherProps} />
        default:
            return <UnconfirmedStudents currentTab={currentTab} students={unverifiedTasks} setCurrentTaskId={setCurrentTaskId} setCurrentIntervalIndex={setCurrentIntervalIndex} {...otherProps} />
    }
}


function MainTabs(props) {
    const [currentTab, setCurrentTab] = useState()
    function onSelect(selectedTab) {
        setCurrentTab(selectedTab)
    }
    return (
        <React.Fragment>
            <Divider height='30px' />
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Nav fill variant="pills" defaultActiveKey="NOT_VERIFIED" onSelect={onSelect}>
                        <Nav.Item>
                            <Nav.Link eventKey="NOT_VERIFIED">Waiting for verification ({props.unverifiedTasks.length})</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink eventKey="VERIFIED">Verified ({props.verifiedTasks.length})</NavLink>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col xs lg="12">
                    <Divider height='40px' />
                    <TabSelector currentTab={currentTab} {...props} />
                </Col>
            </Row>
        </React.Fragment>
    )
}
// components 
function UnconfirmedStudents({ students = [], setSureToUnverifyModal, setSureToVerifyModal, setCurrentTaskId, setCurrentIntervalIndex, setCurrentPageUnverifiedTasks }) {
    return (
        <Row>
            <Col>
                <Container>
                    <h5 style={{ padding: '0.5rem 0' }}>Please verify your students following volunteer hours</h5>

                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Student name</th>
                                <th>Task title</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Verification button</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                students.map((item, idx) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.tasker.firstName} {item.tasker.lastName}</td>
                                            <td>{item.title}</td>
                                            <td>{item.organisation}</td>
                                            <td>{item.hoursTotal}</td>
                                            <td>
                                                <Flex className='center space-around'>
                                                    <Button variant='success' onClick={() => {
                                                        setSureToVerifyModal(true)
                                                        setSureToUnverifyModal(false)
                                                        setCurrentTaskId(item.id)
                                                        setCurrentIntervalIndex(item.intervalIndex)
                                                    }}>
                                                        Verify
                                                    </Button>
                                                </Flex>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <CustomDiv width='100%'>
                        <Button onClick={() => setCurrentPageUnverifiedTasks(current => current + 1)}>Load More</Button>
                    </CustomDiv>
                </Container>
            </Col>
        </Row>
    )
}

function ConfirmedStudents({ students = [], setCurrentPageVerifiedTasks }) {
    return (
        <Row>
            <Col>
                <Container>
                    <h5 style={{ padding: '0.5rem 0' }}>Here are the following verified hours of your students</h5>

                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Student name</th>
                                <th>Volunteer task title</th>
                                <th>Volunteer organisation</th>
                                <th>Volunteer hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                students.map((item, idx) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.tasker.firstName} {item.tasker.lastName}</td>
                                            <td>{item.title}</td>
                                            <td>{item.organisation}</td>
                                            <td>{item.hoursTotal}</td>
                                        </tr>
                                    )

                                })
                            }
                        </tbody>
                    </Table>
                    <CustomDiv width='100%'>
                        <Button onClick={() => setCurrentPageVerifiedTasks(current => current + 1)}>Load More</Button>
                    </CustomDiv>
                </Container>
            </Col>
        </Row>
    )
}


function ActionModal({ action, show, setShow, modalContent = { title: "", buttonLabel: '' } }) {
    return (
        <BootstrapModal show={show} onHide={() => setShow(false)}>
            <BootstrapModal.Body>
                <Divider />
                <h4 style={{ textAlign: 'center' }}>{modalContent.title}</h4>
                <Divider />
                <CustomDiv width='100%' style={{ textAlign: 'center' }}>
                    <Button onClick={() => {
                        action()
                        setShow(false)
                    }}>{modalContent.buttonLabel}</Button>
                </CustomDiv>
            </BootstrapModal.Body>
            <Divider />
        </BootstrapModal>
    )
}