import React, {useEffect, useState} from 'react'
import { Row, Col, Form, Button, Container } from 'react-bootstrap'
import { Divider } from '../components'
import { useRouteMatch } from 'react-router'
import RecurringTasksForm from './RecurringTasksForm'
import { Formik } from 'formik'
import { Modal } from '../components'
import axios from 'axios'
import { PlatformApi } from '../../api'
import moment from "moment";
import DatePicker from "react-datepicker";


/**
 * OrganisationTabs is a component that has to be renamed to AddTaskOrganisation as it is a modal that lets an Organisation user to create 
 * both recurring and one time tasks
 * 
 * THE TITLE CHARACTER LIMIT IS VERY IMPORTANT, IF A TITLE IS MORE THAN 40 CHARACTERS LONG THE BACKEND WILL NOT LET THE USER POST A TASK
 */
export default function OrganisationTabs({ show = false, onHide, taskId = ""}) {
    let { path } = useRouteMatch()
    const [currentTab, setCurrentTab] = useState('RECURRING_TASKS')
    const [task, setTask] = useState("")
/*    function onSelect(selectedTab) {
        setCurrentTab(selectedTab)
    }*/

    useEffect(() => {
        async function taskRequest(){
            const request = await axios.post(`${PlatformApi}/task/task_details`, {taskId: taskId}, {
                headers: {
                    "X-AUTH-TOKEN": localStorage.getItem("api_token")
                }
            })
            if (request.data.data.recurring !== "") {
                setCurrentTab('RECURRING_TASKS')
            }else{
                setCurrentTab('ONE_TIME_TASKS')
            }
            setTask(request.data.data)
            
            return request.data.data
        }
        if (taskId !== "") {
            taskRequest()
        }else {
            setTask("")
            setCurrentTab('RECURRING_TASKS')
        }
        
    }, [taskId])
    
    return (
        <React.Fragment>
            <Modal show={show} onHide={onHide}>
                <Divider height='30px' />
                <Row className="justify-content-md-center">
                    <Col xs lg="12">
                        {
                            (taskId === "" || task !== "") &&
                            <TabSelector  currentTab={currentTab} path={path} onHide={onHide} task={task}/>
                        }
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

function TabSelector({ currentTab, ...otherProps }) {
    switch (currentTab) {
        case "RECURRING_TASKS":
            return <RecurringTasksForm {...otherProps}/>
        case "ONE_TIME_TASKS":
            return <OneTimeTaskForm {...otherProps}/>
        default:
            return <OneTimeTaskForm {...otherProps}/>
    }
}


function OneTimeTaskForm({onHide,task}) {

    const [dateStart, setDateStart] = useState(task !== "" ? moment.utc(task.dateStart).format("YYYY-MM-DD") : '')
    const [timeStart, setTimeStart] = useState(task !== "" && task.startTime ? task.startTime : '')
    const [timeEnd, setTimeEnd] = useState(task !== "" && task.endTime ? task.endTime : '')
    
    const initialValues = {
        taskId: task !== "" ? task.id : '',
        title: task !== "" ? task.title : '',
        taskType: task !== "" ? task.taskType : 0,
        dateType: 2,
        dateEnd: task !== "" ? task.dateEnd : '',
        hourlyWage: task !== "" ? task.hourlyWage : '0',
        flatWage: task !== "" ? task.flatWage : '0',
        locationType: task !== "" ? task.locationType : 0,
        address: task !== "" && task.homeAddress ? task.homeAddress.address : "",
        state: task !== "" && task.homeAddress ? task.homeAddress.state : '',
        zipCode: task !== "" && task.homeAddress ? task.homeAddress.zipCode : '',
        info: task !== "" ? task.info : ''
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                const {
                    taskId,
                    title,
                    taskType,
                    dateType,
                    hourlyWage,
                    flatWage,
                    locationType,
                    address,
                    state,
                    zipCode,
                    info
                } = values

                const object = {
                    taskId,
                    taskType,
                    dateType,
                    dateStart: dateStart,
                    dateEnd: dateStart,
                    timeStart: `${dateStart} ${timeStart}:00`,
                    timeEnd: `${dateStart} ${timeEnd}:00`,
                    hourlyWage,
                    flatWage,
                    locationType,
                    homeAddress: {
                        address,
                        state,
                        zipCode
                    },
                    virtualAddress: 'dd',
                    customAddress: 'dd',
                    volunteering: true,
                    volunteers_needed: 3,
                    details: {
                        title,
                        info,
                    }
                }

                axios.post(`${PlatformApi}/task/` + (task === "" ? 'add_task' : 'edit'), { ...object }, {
                    headers: {
                        "X-AUTH-TOKEN": localStorage.getItem("api_token")
                    }
                }).then(res => {
                    console.log("Values ok:", object)
                    console.log("Response: ", res.data)
                    window.location.reload()
                })
                    .catch(err => {
                        console.log("Error: ", err)
                        console.log("Values err:", object)
                        alert("There was an error trying to create the task, please check your internet conection")
                        onHide()
                    })
            }}
        >
            {({
                values,
                handleChange,
                handleSubmit,
            }) => (
                <Container>
                    <Divider height='50px' />
                    {
                        task === "" ?
                            <h3>Post a one time task</h3>
                            :
                            <h3>Edit a one time task</h3>
                    }
                
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicId" style={{display:'none'}}>
                            <Form.Control maxlength="40" name='taskId' type="text"  value={values.taskId} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicDateStart">
                            <Form.Label>Title</Form.Label>
                            <Form.Control maxlength="40" name='title' type="text" placeholder="enter a title" value={values.title} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicDateStart">
                            <Form.Label>Start Date</Form.Label>
                            <DatePicker type='date' className='form-control' value={dateStart} onChange={(date) => {
                                setDateStart(moment(date).format('YYYY-MM-DD'))
                            }} />                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicTimeStart">
                            <Form.Label>Start Time</Form.Label>
                            <DatePicker
                                value={timeStart}
                                className='form-control'
                                onChange={(date) => {
                                    setTimeStart(moment(date).format('HH:mm'));
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicTimeEnd">
                            <Form.Label>End Time</Form.Label>
                            <DatePicker
                                value={timeEnd}
                                className='form-control'
                                onChange={(date) => {
                                    setTimeEnd(moment(date).format('HH:mm'));
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicAddress">
                            <Form.Label>Location</Form.Label>
                            <Form.Control name='address' type="text" placeholder="Enter the adress of the task" value={values.address} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicState">
                            <Form.Label>State</Form.Label>
                            <Form.Control name='state' type="text" placeholder="State" value={values.state} onChange={handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicZip">
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control name='zipCode' type="text" placeholder="Zip" value={values.zipCode} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                as="textarea"
                                placeholder="Additional Info"
                                name='info'
                                value={values.info}
                                onChange={handleChange}
                                style={{ height: '100px' }}
                            />
                        </Form.Group>
                        <Divider height='15px' />
                        {
                            task === "" ?
                                <Button variant="primary" type="submit">
                                    Post task
                                </Button>
                                :
                                <Button variant="primary" type="submit">
                                    Edit task
                                </Button>
                        }
                    </Form>
                </Container>
            )}
        </Formik>
    )
}