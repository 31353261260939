import React from 'react'
import { CustomDiv } from '../components/CustomStyling'

/**
 * View for when the user has just signed up to make them verify their account
 * If a user has not verified their account yet they wont be able to signin
 */
export default function ConfirmEmail() {
  return (
    <CustomDiv height='100vh' width='100%' background='#f0f0f0'>
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ padding: '100px' }}>We now need you to go verify your email account.</h1>
        <p style={{ fontSize: '16px', color: '#0f0f0f' }}>We have sent you an email to your inbox</p>
        <p style={{ fontSize: '16px', color: '#000' }}>
          Please go to your inbox to confirm your email!</p>
          <p style={{ fontSize: '16px'}}>Remember to check your spam/junk mail in case
          And then sign in <a href='/volunteer/signin'>here</a>
        </p>
      </div>
    </CustomDiv>
  )
}