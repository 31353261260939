import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { HiChevronDown } from "react-icons/hi";
//images
import Book from '../assets/book.png'
import Brochure from '../assets/brochure.png'
import Check from '../assets/check.png'
import Computer from '../assets/computer.png'
import Court from '../assets/court.png'
import Cube from '../assets/cube.png'
import Download from '../assets/download.png'
import Light from '../assets/light.png'
import Like from '../assets/like.png'
import Mail from '../assets/mail.png'
import MoneyFlow from '../assets/money-flow.png'
import Phone from '../assets/phone.png'
import Profile from '../assets/profile.png'
import Screen from '../assets/screen.png'
import SettingsScreen from '../assets/settings-screen.png'
import Settings from '../assets/settings.png'
import StretchingHands from '../assets/stretching-hands.png'
import X from '../assets/x.png'
import TermsAndConditionsDoc from '../assets/terms-of-service.pdf'

import { Fragment } from 'react';

const TermsAndConditions = () => {
	return (
		<Fragment>
		<section className="terms-and-conditions-section section-padding">
			<div className='container'>
			<h5 className='center-text'>To read the full terms and conditions document click <a href='/terms-and-conditions-doc'>here</a></h5>
			<div className='divider'></div>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='What Pocketask Does' eventKey={1} img={Check}/>
						<CardContent eventKey={1} >
							<ul>
								<li>Pocketask connects teenagers with local opportunities posted by people in the area, called ‘Creators.’</li>

								<li>The types of jobs include yard work, pet care, tutoring, babysitting and more.</li>

								<li>To ensure safety when performing these jobs, Pocketask performs background and sex offender checks on the Creators using family watchdog.</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title="What Pocketask Doesn't do" eventKey={1} img={X}/>
						<CardContent eventKey={1} >
							<li>Pocketask is not an employer and is not responsible for the conduct of any users on our platform. Creators are responsible for following the employment laws for whatever job they are hiring a teen for.</li>

							<li>We allow for optional background checks. You should also remember to be careful with information given to you by a user that is not covered in any background checks. Use your judgement and proceed with caution!</li>

							<li>Pocketask is not responsible for any Tasker and Creator produced content, and we do not guarantee reliability or accuracy of their content.</li>

							<li>Pocketask does not recommend any users for any particular task, nor does it assure reliability or competency of users. We also do not endorse any reviews.</li>

							<li>Pocketask is not an employment agency.</li>

							<li>Pocketask does not monitor the legality, quality, pay, or timing of the Tasks. We also do not require Task requests to be accepted.</li>

							<li>Pocketask offers the opportunity to verify certain information, such as an email address or phone number, however we do not guarantee this is accurate.</li>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='Creator Responsabilities' eventKey={1} img={Cube}/>
						<CardContent eventKey={1} >
							<ul>
								<li>Creators are responsible for interviewing, verifying, and choosing Taskers who they think will do a good job.</li>
								<li>Creators are also for establishing an appropriate work environment. </li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='How the Service Works and Fees ' eventKey={1} img={MoneyFlow}/>
						<CardContent eventKey={1} >
							<ol>
								<li>Trusted Networks</li>
							</ol>
							<ul>
								<li>
									Users build a ‘Trusted Network’ by requesting other users on the app. Creators can either open tasks up to people only in their Trusted Networks or to their local community. Similarly, Taskers can choose to accept tasks only from their Trusted Networks or in their local community.
								</li>
							</ul>
							<ol>
								<li>Creating a booking, performing a task, payment</li>
							</ol>
							<ul>
								<li>A Creator starts the process by uploading a Task, which appears on the feeds of Taskers.</li>
								<li>A Booking happens when a Tasker accepts the Task and the Creator accepts that Tasker. Neither user is obliged to accept. If the booking is not successful, the Task goes back to being open for anyone. </li>
								<li>Once the booking is completed, the Creator and Tasker can agree on the payment and other terms of the booking. As soon as the Tasker indicates that a Task is complete, the Creator will be prompted for payment.</li>
								<li>Payments are made through a third party payment processor, and they go through to the Tasker’s on-line wallet as “Pocketask Cash.” This “Pocketask Cash” can be withdrawn by the Tasker after verifying themself for the payment processor. </li>
								<li>The Task transaction is between the Tasker and the Creator. Pocketask only facilitates the transaction. </li>
							</ul>
							<ol>
								<li>Fees</li>
							</ol>
							<ul>
								<li>We charge a small service fee for the Pocketask service.</li>
								<li>By participating in a monetary transaction on Pocketask, you are agreeing to the payment processor’s terms of service and privacy policy.</li>
								<li>Any payment disputes are between the Creator and Tasker. Pocketask does not have to help solve the dispute.</li>
								<li>If you are a Tasker, Pocketask has the right to hold on to money that is owed to you if there is a reasonable suspicion of fraudulent activity in your account.</li>
								<li>Except for where Pocketask is required to collect taxes, you acknowledge that you are solely responsible to pay any taxes that come as a result of your use of Tasks on Pocketask.</li>
							</ul>
							<ol>
								<li>Reviews</li>
							</ol>
							<ul>
								<li>The Pocketask Service may provide the ability to leave public and/or private reviews of Registered Users. You acknowledge that even private reviews may be shared with third parties in accordance with applicable law and our Privacy Policy. </li>
								<li>Pocketask has no obligation to store any reviews.</li>
								<li>We reserve the right to screen, edit, or remove reviews from the Pocketask Service at any time.</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='Elegibility' eventKey={1} img={Brochure}/>
						<CardContent eventKey={1} >
							You cannot use Pocketask if:
							<ul>
								<li>You are under 14</li>
								<li>You are under 18 without a parent or guardian's approval</li>
								<ul>
									<li>If either of these rules are broken we can terminate your account</li>
								</ul>
								<li>We have previously disabled your account for violations of this Agreement</li>
								<li>
									You are a Tasker and have had legal action against you for any criminal offense involving:
									<ul>
										<li>violence, emotional abuse, theft, drugs or any offense, that involves endangering the safety of others, fraud, dishonesty, or reckless or negligent conduct or any sex offenses.</li>
									</ul>
								</li>
								<li>
									You are a Creator and you or anyone in your house has had legal action against them for any criminal offence involving:
									<ul>
										<li>violence, emotional abuse, theft, drugs or any offense, that involves endangering the safety of others, fraud, dishonesty, or reckless or negligent conduct or any sex offenses.</li>
									</ul>
								</li>
								<li>
									Taskers MUST be between 14-23
								</li>

							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Our Rules of Conduct ' eventKey={1} img={Court}/>
						<CardContent eventKey={1} >
							<p>If you use Pocketask, you must: </p>
							<ul>
								<li>Create only one account (your own)</li>
								<li>YUse the same name you use in everyday life</li>
								<li>Provide accurate, and current information about yourself, your skills, and your family</li>
								<li>Use the Service only to find jobs relating to basic chores </li>
								<li>Follow all applicable local, state, and federal laws and regulations</li>
								<li>
									Fulfill the commitments you make to other registered users, such as:
									<ul>
										<li>Paying and receiving money through the Pocketask Service </li>
										<li>Communicating clearly and promptly with other registered users</li>
										<li>Being present and available at the time you agree upon with your Creator or Tasker</li>
									</ul>
								</li>
								<li>As a Tasker, provide timely, high-quality services to Creators, only offer and provide services for which you have the necessary skills and expertise, and perform the Tasks safely.</li>
								<li>As a Creator, only use Taskers for age-appropriate and safe chores, and provide an appropriate and safe environment for Taskers to undertake the Tasks. </li>
							</ul>
							You also agree not to use (or enable others to use) the Service to engage in any of the following conduct:
							<ul>
								<li>Create a false identity for the purpose of misleading others</li>
								<li>Do, share, or promote anything that is illegal or unlawful, misleading, discriminatory, or fraudulent </li>
								<li>Contact anyone who has asked not to be contacted; “stalk” or otherwise harass anyone</li>
								<li>Make any statements or take any actions that are: defamatory; vulgar, indecent, or obscene; pornographic or sexually explicit; racially, culturally, or ethnically offensive; harmful, harassing, intimidating, threatening, or abusive; or impersonating or appearing to impersonate someone else</li>
								<li>Post reviews about Taskers and Creators that aren’t based on your personal experience, that are intentionally inaccurate or misleading, or that violate this Agreement </li>
								<li>Affect Pocketask adversely or reflect negatively on us, our goodwill, name, or reputation or cause duress, distress, or discomfort to us or anyone else </li>
								<li>Discourage any person, firm, or enterprise from using all or any portion, features, or functions of the Service, or from advertising, linking, or becoming a supplier to us in connection with the Service;</li>
								<li>Access or collect data or information, including email addresses or other contact information, from the Service using automated means (without our prior permission) or attempt to access data or information you do not have permission to access </li>
								<li>Send junk e-mail, chain letters, duplicative or unsolicited messages, surveys, contests, pyramid schemes, or so-called “spamming” and “phishing”</li>
								<li>Unless approved by us, use the Service for commercial purposes, like advertising, marketing, or offering goods or services. This rule stands whether or not you acquire compensation. This rule also applies for linking with any other website or web pages</li>
								<li>Use cheats, exploits, automation software, bots, hacks, mods, or any unauthorized third-party software designed to modify or interfere with the Service</li>
								<li>Transmit, distribute, or upload programs or material that contain malicious code, such as viruses, timebombs, cancelbots, worms, trojan horses, spyware, or other potentially harmful programs or other material or information</li>
								<li>Violate any laws, regulations (including, but not limited to, laws regarding the transmission of technical data or software exported from the United States), judicial or governmental orders or any treaties, or violate or infringe upon any intellectual property rights, rights of publicity or privacy, or any other rights of ours or of any other person, firm, or enterprise</li>
								<li>Gain unauthorized access to the Service, other users’ accounts, names, passwords, personally identifiable information, or other computers, websites, or pages, connected or linked to the Service</li>
								<li>Disrupt, impair, alter, or interfere with the use, features, functions, operation, or maintenance of the Service or the rights of use and enjoyment of the Service by any other person, firm, or enterprise</li>
								<li>Use the Service in any manner which violates or is inconsistent with the terms and conditions of this Agreement.</li>
							</ul>
							<p>We can remove or restrict access to content that violates these rules.</p>
							<p>Remember that you alone are responsible for all activity that occurs through your account. Do not share your password, give access to your account to others, use your account on behalf of someone else, or transfer your account to anyone else.</p>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='User Disputes' eventKey={1} img={Computer}/>
						<CardContent eventKey={1} >
							<ul>
								<li>You are responsible for your interactions with other users and other parties on Pocketask. Pocketask is not responsible for you regarding how you use the Service.</li>
								<li>Pocketask reserves the right, but has no obligation, to manage disputes between you and other users of the Service.</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Identity and Sex Offender Registry Check' eventKey={1} img={Profile}/>
						<CardContent eventKey={1} >
							<ul>
								<li>Pocketask reserves the right to run adult Registered Users through sex offender registries.</li>
								<li>Pocketask reserves the right, but not the obligation, to verify adult Registered Users’ identities.</li>
								<li>You understand and agree that Pocketask may use information from the Offender Registries and Identity Verification in deciding whether to suspend or terminate a Registered User.</li>
								<li>By using Pocketask, you agree to allow Pocketask to perform the checks (mentioned above).</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='Optional Background Checks' eventKey={1} img={SettingsScreen}/>
						<CardContent eventKey={1} >
							<p>1. Optional Background Checks</p>
							<ul>
								<li>Pocketask offers access to optional background check services. Optional Background Checks can be purchased by Creators and Taskers and require payment of a separate fee and consent of a parent or guardian if the Registered User is a minor. </li>
								<li>Optional Background Checks are regulated by the Fair Credit Reporting Act ("FCRA"), and the reports from these services are called "consumer reports.” Consumer reports may contain information on your character, general reputation, personal characteristics, and mode of living, including but not limited to consumer credit, criminal history, workers’ compensation, driving, employment, military, civil, and educational data and reports.</li>
								<li>Pocketask will receive a copy of each Optional Background Check. You are responsible for making sure that the email address you provide to Pocketask is correct and appropriate given that sensitive information will be sent to it. </li>
								<li>If you have ordered an Optional Background Check on yourself through Pocketask, we may indicate in your profile that you have completed that check. While you may choose to share the results with another Registered User, Pocketask will not share them. </li>
								<li>We don’t provide, and are not liable for, any background checks, including Optional Background Checks. We don’t affirm the reliability, accuracy, timeliness, or completeness of information in any background checks, including Optional Background Checks. We do not independently verify information in the Optional Background Checks. </li>
								<li>
									Be aware of the following limitations in all background checks, including Optional Background Checks:
									<ul>
										<li>Pocketask does not automatically run background checks on all Registered Users. </li>
										<li>Background checks may vary by type and depth. </li>
										<li>Records not available to the background check agencies will not be included in the results. </li>
										<li>Not all arrest logs and records, conviction and correction records, and sex offender registries are available in all places. In many places, there is a delay before arrest logs and records, conviction and correction records, and sex offender registries are included in background checks.</li>
										<li>Juvenile records and offenses for minors may not appear in the public record and are therefore not included in the results.</li>
										<li>Dismissed cases, arrests not resulting in convictions, arrests or convictions in foreign countries, and nolle prosequi (abandonment of prosecution) may not be reported. </li>
										<li>Traffic violations are not included unless reported as a criminal offense. If a traffic violation is considered a criminal offense, it will appear in the results as a misdemeanor or felony.</li>
									</ul>
								</li>
							</ul>
							<p>2.  FCRA Responsibilities of Optional Background Checks</p>
							<ul>
								<li>The use of any Optional Background Check reports is controlled by the FCRA and certain state laws.</li>
								<li>If you request access to an Optional Background Check, you must agree to a statement called the “End User Certification” before the background check is processed. This End User Certification asks that you will comply with the FCRA and any applicable state laws.</li>
								<li>A summary of your responsibilities in using the information contained in the Optional Background Check can be found at <a href='http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know'>http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know</a></li>
								<li> If there is negative data in an Optional Background Check you receive, and you choose to take "adverse action" (i.e., if you choose to decline a Tasker for a Task) on the basis of this negative data, you must agree to take certain steps, which can be found at <a href='http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know'>http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know</a>. One step you must take is to contact the Tasker who is the subject of the negative data you received to tell them of your decision to take “adverse action.” Tasker’s have the right to contest the accuracy or completeness of the report. </li>
								<li>If the Tasker contests the report with the reporting agency, you are required to suspend the Task while the agency researches the accuracy and completeness of the report. </li>
							</ul>
							<p>3. We May Review Optional Background Checks That You Order</p>
							<ul>
								<li>If you choose to order an Optional Background Check on yourself, you acknowledge and agree that Pocketask may review and use any Optional Background Checks you have ordered for the purpose of protecting the safety and integrity of our App and its users. </li>
								<li>The background check results may cause certain Taskers to be reevaluated as employable on our App. Pocketask reserves the right to terminate your account based on the information contained in an Optional Background Check, even if such information was previously dismissed. </li>
								<li>If we terminate your account due to information in an Optional Background Check, we will notify you and provide you with the name and contact information of the agency that created the report. We will also provide you a copy of the report unless already given by the reporting agency.</li>
								<li>You hereby understand and agree that Pocketask does not control or assume any responsibility for the quality, accuracy, or reliability of the information included in these Optional Background Checks.</li>
								<li>Furthermore, any inaccuracies in the report must be addressed with the reporting agency that issued it and not with Pocketask. </li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Disclaimers; Waivers; Indemnification' eventKey={1} img={Book}/>
						<CardContent eventKey={1} >
							<ol>
								<li>Disclaimer of Warranty</li>
								<ul>
									<li>Your use of Pocketask is at your own risk. Pocketask is not responsible for the completeness, accuracy, availability, timeliness, security, or reliability of the Service or any Content.</li>
									<li>Pocketask will not be responsible or liable for any harm to your computer system, mobile device, loss of data, or other harm that results from your access to or use of the Service or any Content.</li>
									<li>You also agree that Pocketask has no responsibility for the failure to store or to transmit any Content. We make no guarantee that the Service will be uninterrupted, secure, or error-free.</li>
									<li>No information obtained from Pocketask will create any guarantee not made in this document.</li>
								</ul>
								<li>Assumption of Risk</li>
								<ul>
									<li>While Pocketask takes certain steps to help promote the safety of the Service, finding jobs through an online platform like ours comes with certain inherent risks.</li>
									<li>We don’t provide any training, supervision, or monitoring of Creators or Taskers, and we can’t guarantee that all of your interactions with other Registered Users, site visitors, their families, or others associated with them, will always be 100% safe and respectful.</li>
									<li>You agree to assume all risks when using the Service, including but not limited to injury, illness, death, and all other risks associated with any interactions with users of the Service. You also agree not to rely solely on steps Pocketask may take to vet or screen Creators or Taskers.</li>
									<li>You further agree to take all necessary precautions, including those set forth in this Agreement, when interacting with other users, their families, and others associated with them. </li>
								</ul>
								<li>Limitation of Liability</li>
								<p>Pocketask is not liable for any damages, including, but not limited to, profits, data, use, goodwill, or other intangible losses. These damages may result from:</p>
								<ul>
									<li>Access and use of the Service</li>
									<li>Defamatory, offensive, or illegal conduct by third parties</li>
									<li>Content obtained from the Service</li>
									<li>Unauthorized use of your UGC (User Generated Content)</li>
								</ul>
								<li>Indemnification</li>
								<ul>
									<li>Upon Pocketask’s request, you agree to defend, indemnify (meaning “reimburse”), and hold Pocketask harmless from all liabilities and expenses, like attorneys’ fees, that arise from this Agreement due to UGC distribution (see the section titled "User Generated Content" under the "Intellectual Property" tab to learn more about what UGC means). You agree to indemnify and hold Pocketask harmless from any improper or illegal use of your account.</li>
									<li>You are personally responsible for your use of the Service, including any UGC you contribute. You will indemnify and hold Pocketask harmless from any liability arising from your conduct on the Service, including any UGC you contribute.</li>
									<li>Pocketask may assume control of any matter that results in a situation where you owe money. In that event, you will have no further obligation to provide reimbursement to Pocketask.</li>
								</ul>
							</ol>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='Agreement to Arbitrate' eventKey={1} img={StretchingHands}/>
						<CardContent eventKey={1} >
							<p>This Section is referred to in this Agreement as the “Arbitration Agreement.” You can opt-out of this section using the procedures by looking at the steps laid out under #6 in this section. If you don’t opt-out, you agree that all conflict between you and Pocketask will be resolved through arbitration-- a way to settle a dispute that is less costly and time consuming than a court. However, even under this agreement, if you have claims that qualify for small claims court rather than regular court, you can still go to small claims court (small claims court is a local court where claims are made quickly, cheaply, and without representation). But for the most part, disputes will be settled by arbitration. In arbitrations, the dispute is mediated by a neutral arbitrator (someone sort of like an ‘umpire’ or ‘referee’) rather than a judge or jury. The Federal Arbitration Act allows for this Arbitration Agreement.</p>
							<ol>
								<li>Prohibition of Class and Representative Actions and Non-Individualized Relief</li>
								<ul>
									<li>Both you and Pocketask agree that claims against each other are brought to each other directly, and not through a court.</li>
									<li>The arbitrator for your case cannot be someone who has already worked on a case against Pocketask.</li>
									<li>The arbitrator may only reward relief to the person who made the claim and has control over how much relief that person gets.</li>
									<li>Any relief award cannot affect another Pocketask user. </li>
									<li>If any court or arbitrator decides that an arbitration is not suited for your case or that court is a better option, then the arbitration will be invalid and both you and Pocketask will not be said to have agreed to settling the case through an arbitrator. </li>
								</ul>
								<li>Pre-Arbitration Dispute Resolution</li>
								<ul>
									<li>Pocketask is always interested in resolving any disputes in a friendly and efficient way. Before you start arbitration, we encourage you to contact us to explain your complaint, as we may be able to resolve it without the need for arbitration. You may contact us at [email address]. </li>
								</ul>
								<li>Arbitration Procedures</li>
								<ul>
									<li>If we can’t resolve a claim informally, any claim either of us asserts will be resolved through an arbitrator (again, someone like an ‘umpire’ or ‘referee’) and not through a judge in court.</li>
									<li>Arbitration will be conducted by one neutral arbitrator in accordance with the rules of JAMS that are in effect when the arbitration is initiated (referred to as the “JAMS Rules”). For information on JAMS, please visit its website, <a href='https://www.jamsadr.com'>https://www.jamsadr.com/</a>. Information about JAMS’s Rules and fees for any conflict can be found at the JAMS consumer arbitration page, <a href='https://www.jamsadr.com/rules-comprehensive-arbitration/'>https://www.jamsadr.com/rules-comprehensive-arbitration/</a>.</li>
									<li>If there is any difference between the JAMS Rules and this Arbitration Agreement, the terms of this Arbitration Agreement will control the situation unless the arbitrator does not find that fair.</li>
									<li>The arbitrator must also follow Pocketask’s Terms and Conditions like a court would. Although arbitration proceedings are usually simpler than trials, the arbitrator can award the same damages and relief that a court can award. Decisions by the arbitrator are enforceable in court and cannot often be changed in court. </li>
									<li>To start an arbitration against Pocketask, you must write a demand for arbitration that includes a description of the dispute and the amount of damages you wish to recover. You can find a copy of a Demand for Arbitration at www.jamsadr.com. You should send two copies of the Demand, plus the appropriate filing fee, to JAMS at 71 South Wacker Drive, Suite 2400, Chicago IL 60606, and send one copy to Pocketask, Attn: Legal Department, [address]. For more information, see the JAMS arbitration rules and forms, <a href='https://www.jamsadr.com/rules-download/'>https://www.jamsadr.com/rules-download/</a>.</li>
									<li>You may represent yourself in the arbitration or be represented by an attorney or another representative.</li>
									<li>Once we receive your arbitration claim, we may assert any counterclaims we may have against you. </li>
									<li>The arbitration shall be held in the county in which you reside or at another mutually agreed location.</li>
									<li>If the value of the relief sought is $10,000 or less you or Pocketask can choose to have the arbitration done by telephone or through writing/email. Whatever is chosen will be binding on you and Pocketask unless the arbitrator thinks that an in-person hearing is best. Attendance at any in-person hearing may be made by telephone by you and/or Pocketask, unless the arbitrator requires otherwise</li>
									<li>The arbitrator, and not any government authority, shall have exclusive control of the resolution for the dispute arising out of this Agreement. This includes any claim that all or any part of the Agreement is valid or invalid, or whether a dispute can be settled by an arbitrator. The arbitrator is allowed to grant whatever relief would be available in a court under law.</li>
									<li>The arbitrator’s award must be written and binding on the parties and is valid in any court.</li>
									<li>The arbitrator will base his opinions of the dispute in accordance with laws of the State of Illinois, and will honor all claims to not publicize a dispute recognized by law.</li>
									<li>The arbitrator is not bound to rule similarly to other arbitrations involving a different Pocketask user, but is required to take into consideration any rulings involving the same Pocketask user.</li>
								</ul>
								<li>Costs of Arbitration</li>
								<ul>
									<li>The amount owed for Arbitration Fees are determined by JAMS rules, unless the amount is determined in this Agreement. </li>
									<li>In certain instances, if you are unable to pay your fees due to economic or any other reason,  Pocketask will pay your portion of such fees. In addition, if the fees for Arbitration are higher than what they would have been in court, Pocketask will pay as much of the fee as the Arbitrator deems necessary. Each party will be responsible for all other fees in connection with the arbitration, including, but not limited to, all attorney fees. If an arbitrator determines that your claim is insignificant, you agree to reimburse Pocketask for all fees paid on your behalf for the arbitration. </li>
								</ul>
								<li>Confidentiality </li>
								<ul>
									<li>All aspects of the arbitration proceeding, and any ruling, decision or award by the arbitrator, will be strictly confidential for the benefit of all parties. </li>
								</ul>
								<li>Opt-Out Procedure </li>
								<ul>
									<li>You can choose to reject this Arbitration Agreement by mailing us a written opt-out notice (“Opt-Out Notice”) in accordance with the terms of this Section.</li>
									<li>The Opt-Out Notice must be postmarked no later than 30 days after the date you use the Service for the first time.</li>
									<li>You must mail the Opt-Out Notice to Pocketask, Attn: Legal Department, [address].</li>
									<li>The Opt-Out Notice must state that you do not agree to the Arbitration Agreement and must include your name, address, phone number, and the email address used to log in to the Pocketask account to which the opt-out applies. You must sign the Opt-Out Notice for it to be effective. This procedure is the only way you can opt out of the Arbitration Agreement.</li>
									<li>If you opt out of the Arbitration Agreement, Pocketask will also not be bound by this Arbitration Agreement. Everything else in these Terms will continue to apply.</li>
									<li>Opting out of the Arbitration Agreement has no effect on any previous, other, or future arbitration agreements that you may have with us. </li>
								</ul>
								<li>Future Changes to this Arbitration Agreement </li>
								<ul>
									<li>You agree that if Pocketask changes this Arbitration Agreement, the changes are not in effect until at least 60 days after posting, and will not apply to any claims made before that 60 days. </li>
									<li>Furthermore, if we get rid of this Arbitration Agreement, such termination will not be effective until 30 days after the Agreement is deleted and not seen on our site, and will not apply to any claims made before that 30 days.</li>
								</ul>
								<li>Governing Law and Jurisdiction </li>
								<ul>
									<li>All claims arising out of this contract will be governed by the laws of the State of Illinois, including Illinois’s statutes of limitations (the amount of time you have to initiate an arbitration), provided that the Federal Arbitration Act will allow for our Arbitration Agreement. </li>
									<li>In the event that the Arbitration Agreement does not apply to your claim (except for small-claims court actions), you agree that  such claim must be resolved in a court located in the State of Illinois.</li>
									<li>You and Pocketask both agree to the laws of the court in the State of Illinois.</li>
								</ul>
							</ol>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Export Controls' eventKey={1} img={Download}/>
						<CardContent eventKey={1} >
							<ul>
								<li>Software available for Pocketask is subject to United States export controls.</li>
								<li>No Software may be downloaded or otherwise exported or re-exported in violation of U.S. export laws.</li>
								<li>Downloading or using the Software is at your sole risk.</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='Third Party and External Sites' eventKey={1} img={Screen}/>
						<CardContent eventKey={1} >
							<ol>
								<li>App Store Terms</li>
								<ul>
									<li>The Pocketask App may be available for download from platforms such as Apple iTunes or Google Play (“App Stores”).  Your download, installation, and access to the App is also bound by the terms and conditions and privacy policies of the applicable App Store. </li>
									<li>If there is any conflict between these Terms and the App Store Terms, then these Terms will prevail.  </li>
									<li>The Pocketask App is not linked in any way to any App Store. The entered Terms are between you and Pocketask only, and not with any App Store. Pocketask, and not the App Store, is solely responsible for the App.</li>
									<li>
										Pocketask, not the relevant App Store, is responsible for addressing any claims related to the App, including, but not limited to
										<ul>
											<li>Any claim that the App fails to follow any applicable requirement</li>
											<li>Claims arising under consumer protection </li>
										</ul>
									</li>
									<p>Upon your acceptance of these Terms, that App Store will have the right to enforce these Terms against you.</p>
								</ul>
								<li> Access To The Service  </li>
								<ul>
									<li>We may allow you to access the Service through a network like Facebook, for example. If so, you may be required to have an account with the network (in this example, a Facebook account) in order to connect to our Service. You must also agree to the that network's terms. You also agree that Pocketask has no liability for any act, error, or omission of any network.</li>
									<li>The collection and use of your information is subject to our Privacy Policy. You agree that Pocketask may use your information as provided in our Privacy Policy.</li>
								</ul>
								<li>Links and Third-Party Websites</li>
								<ul>
									<li>The Pocketask Service may contain links to third-party websites or resources. Pocketask is not responsible or liable for the availability, accuracy, or content of the websites.</li>
									<li>Pocketask does not endorse any website link on our App, or any content, products, or services such links provide.</li>
									<li>You assume all responsibility for all use of any such websites or resources. </li>
									<li>Occasionally on Pocketask, you may interact with third parties. These third parties are advertisers, sponsors, and promotional partners. All interactions are between you and the Advertisers, and we are not liable for any interactions you may have with them. Examples of interactions include: any representations, warranties, covenants, contracts, or other terms or conditions that may exist between you and the Advertiser or any goods or services you may purchase or obtain from any Advertiser.</li>
								</ul>

							</ol>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Release of Liability' eventKey={1} img={Like}/>
						<CardContent eventKey={1} >
							<p>You must leave Pocketask rid of any claim or controversy that may arise out of:</p>
							<ul>
								<li>The actions or relationship between you and another user</li>
								<li>Any information, instruction, advice, or service provided by a Creator, Tasker, or other third party on the Service.</li>
							</ul>
							<p>By agreeing to this, you include all parties relating to Pocketask, such as: Pocketask’s officers, directors, shareholders, employees, parents, subsidiaries, other affiliates, successors, assignees, agents, representatives, licensors, and independent contractors (“Pocketask Parties”).</p>
							<p>Pocketask and the Pocketask Parties are not liable for any damage, suits, claims, and/or controversies that have arisen or may arise from the above mentioned occurrences.</p>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='Intellectual Property' eventKey={1} img={Light}/>
						<CardContent eventKey={1} >
							<ol>
								<li>Limited License To Use The Service</li>
								<ul>
									<li>You are not allowed to use Pocketask for commerical use.</li>
									<li>Your license to use Pocketask is limited, revocable, non-exclusive, and non-transferable. </li>
									<li>You agree not to (a) use the Service for any purpose other than as allowed by this Agreement, or (b) copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, or otherwise exploit the Service or any portion of the Service, except as allowed by this Agreement. </li>
									<li>No licenses or rights are granted to you except for the permissions and rights granted in this Agreement.</li>
								</ul>
								<li>Proprietary Rights </li>
								<ul>
									<li>
										The Content below is owned by Pocketask or its licensors:
										<ul>
											<li>Software, computer code, HTML, APIs, design, artwork, graphics, icons, photos, video, animations, sounds, musical compositions, audio-visual effects, objects, concepts, formats, effects, methods of operation and documentation</li>
											<li>The selection and arrangement of those items (“Content”)</li>
											<li>The trademarks, service marks, trade dress, and logos contained therein (“Marks”)</li>
										</ul>
									</li>
									<li>All other materials used on the Service are the property of their respective owners.</li>
									<li>You acknowledge and agree that the Content and Marks are protected by copyrights, patents, and other laws. Pocketask reserves all rights to intellectual property in connection with the Content and Marks. </li>
									<li>You will not obtain any ownership in the Content, Marks, or the Service through this Agreement or otherwise. All rights to the Content not expressly granted in this Agreement are reserved to their respective owners.</li>
									<li>Except as expressly authorized by this Agreement or on the Service, you may not copy, reproduce, distribute, republish, download, perform, display, post, transmit, exploit, create derivative works, or otherwise use any of the Content or Marks in any form or by any means, without the prior written authorization of Pocketask or the respective owner. </li>
								</ul>
								<li>User Generated Content  </li>
								<ul>
									<li>User generated content (“UGC”) is defined as any content, material, data, and information that you create, upload to, or transmit through the Pocketask Service. This also includes information you have gotten on Pocketask from a third party social network (e.g., Facebook or Google) or other third party platform, including, but not limited to, text, images, stories, photos, suggestions, comments, reviews, questionnaires, and survey answers.</li>
									<li>By posting any UGC in connection with the Pocketask Service, you are allowing Pocketask to use, modify, excerpt, adapt, store, translate, sub-license, create derivative works and compilations based upon, publicly perform, publicly display, reproduce, and distribute such UGC. Pocketask can use this information on whole or in part on any platform run by Pocketask. Pocketask can use this information for, but not limited to, promotional, marketing, research, trade, and commercial purposes. You will not be notified if Pocketask uses UGC, and such use does not require permission or payment of any sort.</li>
									<li>Pocketask may remove any UGC at any time.</li>
									<li>UGC is not confidential and will not be returned to you.</li>
									<li>If the UGC that you create and publish includes your name, likeness, image, and/or voice (collectively, “Image”), you acknowledge and agree that Pocketask has the right to use your Image as part of the Service in accordance with the statement above.</li>
									<li>If the UGC you publish on the Service features the Image of any person other than yourself, you confirm that you have received permission from the relevant person for the use of their Image by Pocketask in accordance with the terms of this Agreement. </li>
									<li>You must reimburse Pocketask for any losses in accordance with the “Indemnification” section of this Agreement.</li>
									<li>Please note that not all aspects of the Service allow the posting of UGC and Pocketask can limit the UGC that you post in connection with the Service. Pocketask also reserves the right (but not the obligation) to remove and permanently delete any UGC from the Service with or without notice for any reason.</li>
									<li>You authorize that: (a) you own all rights to your UGC and are allowed to grant access to it, and (b) your UGC does not violate the privacy rights, publicity rights, intellectual property rights, contract rights, or any other rights of any person or entity. You agree to pay for all fees owed to any person or entity due to the use of any UGC posted by you or through the Service.</li>
									<li>You agree not to share, post, or otherwise disseminate any UGC that is, in Pocketask’s sole discretion, offensive, demeaning, defamatory, or otherwise inappropriate. This includes, but is not limited to: content that directly or indirectly threatens an individual or group, promotes self-injury or suicide, is bullying or harassing, facilitates criminal or other illegal activity, promotes sexual violence or exploitation, or is hate speech.</li>
								</ul>
								<li>User Feedback </li>
								<ul>
									<li>Pocketask does not accept the following: submissions of information, suggestions, proposals, ideas, or concepts (collectively, “User Feedback”). Therefore, any similarity between an unsolicited submission and any parts of the Pocketask Service would be purely coincidental.</li>
									<li>However, on occasion, we may request feedback from our users in the form of a survey or similar submission. All User Feedback provided by you to Pocketask, whether solicited or not, will become the sole property of Pocketask and may be used, copied, sublicensed, adapted, transmitted, distributed, publicly performed, published, displayed, or deleted as Pocketask sees fit. You agree that you are not entitled to any compensation, credit, or notice whatsoever in connection with your User Feedback or Pocketask’s use thereof.</li>
								</ul>
								<li>Copyright Complaints  </li>
								<ul>
									<li>Pocketask respects the intellectual property rights of others and expects its users to do the same.</li>
									<li>We will investigate notices of alleged copyright infringement.</li>
									<li>We will also take appropriate actions under the Digital Millennium Copyright Act (“DMCA”) and other intellectual property laws. </li>
									<br />
									<li>
										If you believe that your content has been copied in a way that constitutes copyright infringement, please provide us with the following information:
										<ul>
											<li>A physical or electronic signature of the copyright owner or a person authorized to act on their behalf</li>
											<li>Identification of the copyrighted work claimed to have been infringed</li>
											<li>Identification of the material that is infringing upon the copyrighted work that is to be removed, and information to locate the material</li>
											<li>Your contact information, including your address, telephone number, and an email address</li>
											<li>A statement by you that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner or the law</li>
											<li>A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
										</ul>
									</li>
								</ul>
								<p>We reserve the right to remove content alleged to be infringing without prior notice and at our sole discretion. In appropriate circumstances, Pocketask will also terminate a Registered User’s account if the Registered User is determined to be a repeat infringer. Our designated copyright agent is:</p>
								<p>Pocketask, LLC</p>
								<p>Attn: Copyright Agent</p>
								<p>[DMCA registered agent address]</p>
								<br />
								<p>E-mail: [DMCA registered agent email]</p>
							</ol>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Consent to Electronic Communication' eventKey={1} img={Mail}/>
						<CardContent eventKey={1} >
							<ul>
								<li>By using the Service, you agree to allow Pocketask to communicate with you electronically.</li>
								<li>You also agree to check your Pocketask account, alerts, and messages, and the e-mail account you give to Pocketask to stay informed about important notices and information about your account.</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term left-sided'>
						<CardTitle title='General Provisions' eventKey={1} img={Settings}/>
						<CardContent eventKey={1} >
							<ul>
								<li>This Agreement includes everything you must agree to in order to use Pocketask. If any part of this Agreement becomes invalid, that part will be interpreted by law to reflect its original meaning as closely as possible, and the remainder of this Agreement will remain in full force and effect.</li>
								<li>No delay or failure to take action under this Agreement means that Pocketask must surrender any part of it.</li>
								<li>This Agreement may not be transferred by you, only by Pocketask.</li>
							</ul>
						</CardContent>
					</Card>
				</Accordion>
				<Accordion>
					<Card className='term right-sided'>
						<CardTitle title='Contacting Us' eventKey={1} img={Phone}/>
						<CardContent eventKey={1} >
							<p>If you have any questions about this Agreement, the Service, or the practices of Pocketask, or to report a violation of this Agreement, please contact us at team@mypocketask.com.</p>
						</CardContent>
					</Card>
				</Accordion>
			</div>
		</section>
		<section>
			<div className='container center-text'>
				<a href={TermsAndConditionsDoc}>Click here to find the Terms and Conditions document</a>
			</div>
		</section>
		</Fragment>
	)
}
// ------ components ---------

function CardTitle({ title, chevron = 'chevron-down', eventKey, img }) {
	return (
		<Accordion.Toggle as={Card.Header} eventKey={eventKey}>
			<div className='flex'>
				<img alt="" src={img} className="img" />
				<h2>{title}</h2>
				<HiChevronDown className={chevron} />
			</div>
		</Accordion.Toggle>
	)
}

function CardContent({ children, eventKey }) {
	return (
		<Accordion.Collapse eventKey={eventKey}>
			<Card.Body>
				{children}
			</Card.Body>
		</Accordion.Collapse>
	)
}



export default TermsAndConditions
