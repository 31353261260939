import React from 'react'
import { Link } from 'react-router-dom'
import Baby from '../assets/cil_baby-carriage.png'
import Car from '../assets/cil_car-alt.png'
import Dog from '../assets/cil_dog.png'
import Paw from '../assets/cil_paw.png'
import Pencil from '../assets/cil_pencil.png'
import Plant from '../assets/cil_plant.png'
import Create1 from '../assets/create-1.png'
import Create2 from '../assets/create-2.png'

const Create = () => {
    return (
        <div className="container container-task login-section">
            <div className="row sec-title">
                <div className="col-12">
                <h2>Let us help you find a teenage helping hand today by creating your personalized <span>pocketask</span> today</h2>
                    <div className="sec-sub-title">(all under 18s require parental consent)</div>
                </div>
            </div>
            <div className="row task-container">
                <div className="col-md-6 d-flex align-items-center">
                    <h3 className="task-list-title">Step 1: <br/> <span>Pick a task.</span></h3>
                </div>
                <div className="col-md-6">
                    <div className="task-list">
                        <div className="single-task">
                            <Link to="/create/babysitting">
                                <img src={Baby} alt="Hire a teenager for Babysitting"/>
                                <p>Babysitting</p>
                            </Link>
                        </div>
                        <div className="single-task">
                            <Link to="/create/yardwork">
                                <img src={Plant} alt="Hire a teenager for Yard Work"/>
                                <p>Yard Work</p>
                            </Link>
                        </div>
                    </div>
                    <div className="task-list middle-task-list">
                        <div className="single-task">
                            <Link to="/create/dogwalking">
                                <img src={Dog} alt="Hire a teenager for Dogwalking"/>
                                <p>Dogwalking</p>
                            </Link>
                        </div>
                        <div className="single-task">
                            <Link to="/create/pickup">
                                <img src={Car} alt="Hire a teenager for Errands"/>
                                <p>Errands</p>
                            </Link>
                        </div>
                    </div>
                    <div className="task-list">
                        <div className="single-task">
                            <Link to="/create/tutoring">
                                <img src={Pencil} alt="Hire a teenager for Tutoring"/>
                                <p>Tutoring</p>
                            </Link>
                        </div>
                        <div className="single-task">
                            <Link to="/create/petsitting">
                                <img src={Paw} alt="Hire a teenager for Petsitting"/>
                                <p>Petsitting</p>
                            </Link>
                        </div>                        
                    </div>
                </div>
            </div>
            <div className="row task-feature-row task-2-container task-container">
                <div className="col-md-7 d-flex align-items-center task-2-img">
                    <img src={Create1} alt="Create a simple task to hire teenagers in your vacinity"/>
                </div>
                <div className="col-md-5 d-flex align-items-center task-2-text">
                    <h3 className="task-list-title">Step 2: <br/> <span>Quickly fill out all the task information.</span></h3>
                </div>
            </div>
            <div className="row task-feature-row task-container">
                <div className="col-md-7 d-flex align-items-center">
                    <h3 className="task-list-title">Step 3: <br/> <span>We will send out your task to trustworthy teenagers ready to help you save time in return for some pocket-money.</span></h3>
                </div>
                <div className="col-md-5 d-flex align-items-center">
                    <img src={Create2} alt="Share your task to find a trustworthy employee"/>
                </div>
            </div>
        </div>
    )
}

export default Create
