import React from 'react'
import Hero from '../assets/about-bg.jpg'
import Nassouh from '../assets/image1.png'
import Marie from '../assets/image3.jpg'
import Aneesh from '../assets/image6.jpg'

const About = () => {
    return (
        <>
        <section className="about-area blue-overlay section-padding" style={{backgroundImage: 'url(' + Hero + ')'}}>
        {/* blue-overlay */}
            <div className="container">
                <div className="col-md-8">
                    <h1><span>Our Story</span></h1>
                    <p>
                    Pocketask is a platform that connects teenagers with members of their community who need help with tasks such as babysitting, dog walking, running errands, and more! We created Pocketask because we witnessed firsthand how difficult it is to earn pocket money as a teen and to find trustworthy helpers as an adult. We include unique features such as an individualized trust algorithm, customized task templates, and a one-click emergency contact with the sole aim of making our platform as easy and safe to use as possible.
                    </p>
                    <p>
                    -Marie<br/>
                    CEO @Pocketask
                    </p>
                </div>
            </div>
        </section>
        <section className="about-area section-padding youtube-section">
            <iframe className="youtube-frame" src="https://www.youtube.com/embed/1kpn3CmaWaE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </section>
        <section className="team-area section-padding">
            <div className="container">
                <div className="row sec-title">
                    <h2><span>Our Team</span></h2>
                </div>
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-4">
                        <div className="team-profile">
                            <img src={Marie} alt="Marie-Liesse Gouilliard"/>
                            <h4>Marie-Liesse Gouilliard</h4>
                            <h5>Co-Founder</h5>
                            <p>Bachelor of Computer science and Economics at the University of Chicago</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="team-profile">
                            <img src={Nassouh} alt="Nassouh Kabbani"/>
                            <h4>Nassouh Kabbani</h4>
                            <h5>Co-Founder</h5>
                            <p>Bachelor of Mathematics with Specialisation in Economics at the University of Chicago</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-lg-2"></div> */}
                    <div className="col-lg-12">
                        <div className="team-profile">
                            <img src={Aneesh} alt="Aneesh Bafna"/>
                            <h4>Aneesh Bafna</h4>
                            <h5>Head of Finance</h5>
                            <p>Bachelor of Economics and Public Policy at the University of Chicago</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default About
