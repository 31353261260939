
const TermsAndConditionsPage = () => {
    return (
        <section className="privacy-section">
            <div>
                <h2>Terms and Conditions</h2>
                <p>Last Updated: June 28, 2021</p>
                <p><strong>
                    IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO A BINDING ARBITRATION AGREEMENT
                    AND A WAIVER OF CLASS ACTION RIGHTS. PLEASE READ SECTION XV BEFORE DOWNLOADING
                    OR USING OUR SERVICE.
                </strong></p>
                <p>
                    Welcome to Pocketask! Pocketask’s mission is to empower teenagers and parents by
                    streamlining their ability to form connections so parents can find trustworthy help to save time
                    on daily chores and tasks and teenagers can earn pocket money locally within a secure setting.
                    These Terms govern your use of Pocketask and our mobile applications (the “App”), websites,
                    and any other on-line technologies, features, services, or software we offer (together, including
                    the App, our “Service”). This Terms of Service document (the “Agreement” or the “Terms”) is a
                    legal agreement between you and Pocketask, LLC (“Pocketask,” “we,” or “us”) and it provides
                    the terms and conditions under which people living in the United States can use Pocketask and
                    our Service.
                </p>
                <p>
                    <strong>Because this is a legal agreement, it’s very important that you read it carefully before using the Service.</strong>
                    By using or accessing the Service, even if you don’t register as a user of the Service
                    (“Registered User”), you consent to this Agreement and to our <a href='/privacy'>Privacy Policy</a>, which is
                    incorporated into this Agreement. You also represent and warrant that you have the right,
                    authority, and capacity to enter into these Terms and you commit to follow and comply with all
                    of the terms and conditions in this Agreement. If you are under 18, you may only use the
                    Service with the approval of your parent or guardian.
                </p>
                <p>
                    We have the right to change or revise this Agreement at any time. Unless we say otherwise, any
                    changes or revisions will be effective right away or, where required by law, 30 days after they
                    are published. If we make changes, we may notify you by providing a link to the new Agreement
                    before you use the Service or by sending notice by some other method. We will also show the
                    date of the most recent changes at the top of the page. If you keep using the Service after the
                    date these changes become effective, you are accepting the new terms and conditions in the
                    Agreement. If you don’t agree with any change or revision, your only option is to stop using the
                    Service.
                </p>
                <p>
                    If there is anything in this Agreement or the Privacy Policy that you don’t agree with or can’t or
                    won’t follow, you should not use the Service.
                </p>
                <p>
                    Pocketask has the right, in its sole discretion, to immediately end or suspend your access to all
                    or part of the Service, to remove your profile and/or any content posted by or about you from
                    the Service, and/or to terminate your account, without telling you first for any reason, including
                    if you violate any provision of this Agreement, are not eligible to use the Service, are not
                    suitable for participation as a Registered User, or have misused or misappropriated Service
                    content.
                </p>
                <h3>I. What Pocketask Provides.</h3>
                <p>
                    Pocketask’s Service connects teenagers who want to earn pocket money (“Taskers”) with people
                    who need help with services (“Creators”), such as babysitting, tutoring, yardwork, pet care, and
                    other basic chores (“Tasks”). We may, although we are not obligated to, perform a limited
                    review of Creator and Tasker profiles, run adult Registered Users through public sex offender
                    3
                    registries, use a third party to conduct identify verifications, and/or provide educational
                    materials on safety. We charge some fees for the Service, which we explain in Section V.C.
                </p>
                <h3>II. What Pocketask Doesn’t Provide.</h3>
                <p>
                    We offer the Service as a neutral venue to help Creators and Taskers connect and coordinate.
                    Except where we specifically say so in this Agreement, in any other Pocketask documents, or on
                    the Service itself, we don’t do any of the following things:
                </p>
                <ul>
                    <li>
                        <strong>
                            We don’t employ any Taskers and are not responsible for the conduct, whether online
                            or offline, of any Tasker, Creator, or other user of the App or Service.
                        </strong>
                        Creators are responsible for complying with all applicable employment and other laws in connection
                        with any employment relationship they establish.
                    </li>
                    <li>
                        We aren’t obligated to do any screening of a Tasker or Creator although we may conduct
                        an initial, limited review of Creator and Tasker profiles; run adult Registered Users
                        through sex offender registries; and/or use a third party to conduct identify verifications.
                        We may also facilitate optional Background Checks (as described in Section VIII, below)
                        on Creators and Taskers that are conducted by a third party. Except where we specifically
                        say so in this Agreement (and only to the extent we specify), we don’t otherwise screen
                        Creators or Taskers and the limited screening we do shouldn’t be taken as complete,
                        accurate, up-to-date, or conclusive of the Tasker’s or Creator’s suitability. You should
                        always exercise caution and use your independent judgment before engaging a Tasker,
                        taking a Task from a Creator, or otherwise interacting with users via the Pocketask
                        Service.
                    </li>
                    <li>
                        Tasker and Creator content is primarily user-generated content or “UGC”. We don’t
                        control or check UGC for accuracy, and we don’t assume and expressly disclaim any
                        responsibility for the accuracy or reliability of any information provided by Taskers or
                        Creators on or off the Service. We don’t assume and expressly disclaim any liability that
                        may result from the use of information provided on the Service.
                    </li>
                    <li>
                        We don’t refer or recommend Taskers or Creators and we don’t make any
                        representations or promises about whether a Tasker is the right fit for a Task, about a
                        Tasker’s reliability, timeliness, or ability, or about the honesty and integrity,
                        responsibility, or actions of Taskers or Creators. Pocketask does not endorse any reviews
                        that may be available via the Pocketask Service and makes no commitments that any
                        such reviews are accurate or legitimate.
                    </li>
                    <li>
                        We are not an employment agency. We don’t secure or procure employees for any
                        Creators, and we don’t secure or procure employment opportunities for any Taskers.
                    </li>
                    <li>
                        We don’t control, monitor, supervise, or oversee the quality, timing, hours, pay, legality,
                        or any other aspect of Tasks created by Creators and/or delivered by Taskers. We don’t
                        require Taskers to accept or work any jobs or deliver any services and we don’t require
                        Creators to accept Tasks from potential Taskers.
                    </li>
                    <li>
                        We might offer Registered Users the opportunity to verify certain information such as
                        their email address or cell phone number. If we indicate that certain information has
                        been verified, it means that the Registered User has complied with the process we have
                        established for verifying that information. We don’t, however, guarantee or represent,
                        warrant, or promise that the information is accurate or that it actually belongs to the
                        Registered User who supplied it.
                    </li>
                </ul>
                <h3>III. Creator Responsibilities.</h3>
                <p>
                    Creators are solely responsible for interviewing, vetting, verifying information provided by, and
                    choosing an appropriate Tasker for themselves or their family. Each Creator is responsible for
                    complying with all applicable employment and other laws in connection with any employment
                    relationship they establish, including verifying the age of the Tasker they choose as well as that
                    Tasker’s eligibility to work in the U.S
                </p>
                <h3>IV. Eligibility</h3>
                <p>
                    We provide the Service so Taskers can make pocket money and Creators can get help with Tasks.
                    In exchange for requesting to use, registering to use, and/or using the Service, we require you to
                    comply with the requirements in this Section.
                </p>
                <p>You cannot use Pocketask or the Service if:</p>
                <ul>
                    <li>
                        You are under 14 years old. If you are not 14 or older, do not register to use the Service.
                    </li>
                    <li>
                        You are under 18 years old and do not have the approval of your parent or guardian. If
                        you are under 18 and do not have the approval of your parent or guardian, do not
                        register to use the Service.
                        <ul>
                            <li><strong>If we find out that you do not meet the age-related requirements for using the
                                Service, we will terminate your account immediately.</strong></li>
                        </ul>
                    </li>
                    <li>
                        We have previously disabled your account for violations of this Agreement or any of our
                        policies.
                    </li>
                    <li>
                        <p>You are under 18 years old and do not have the approval of your parent or guardian.</p>
                        <p>
                            <strong>
                                If you are under 18 and do not have the approval of your parent or guardian, do not
                                register to use the Service.
                            </strong>
                        </p>
                    </li>
                    <li>
                        We have previously disabled your account for violations of this Agreement or any of our
                        policies.
                    </li>
                    <li>
                        You are a Tasker and you have ever been:
                        <ul>
                            <li>
                                the subject of a conviction, arrest, charge, complaint, restraining order, or any
                                other legal action involving:
                                <ul>
                                    <li>
                                        any criminal offense involving violence; physical, sexual, or emotional
                                        abuse or neglect; theft; or drugs; or any offense, violation, or unlawful act that involves endangering the
                                        safety of others, fraud, dishonesty, or reckless or negligent conduct.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                registered, or are currently required to register, as a sex offender with any
                                government entity.
                            </li>
                        </ul>
                    </li>
                    <li>
                        You are a Creator and you or someone living in your house has ever been:
                        <ul>
                            <li>
                                the subject of a conviction, arrest, charge, complaint, restraining order, or any
                                other legal action involving:
                            </li>
                            <li>
                                any criminal offense involving violence; physical, sexual, or emotional
                                abuse or neglect; theft; or drugs; or
                            </li>
                            <li>
                                any offense, violation, or unlawful act that involves endangering the
                                safety of others, fraud, dishonesty, or reckless or negligent conduct.
                            </li>
                        </ul>
                    </li>
                    <li>
                        registered, or currently required to register, as a sex offender with any
                        government entity.
                    </li>
                </ul>
                <p>If you are a Tasker, you must be:</p>
                <ul>
                    <li>
                        Between 14 and 23 years old.
                    </li>
                    <li>
                        Permitted to legally work within the United States
                    </li>
                </ul>
                <h3>V. How the Service Works and Fees</h3>
                <h4>A. The Trusted Network</h4>
                <p>
                    After a Creator or Tasker registers for the Service, they can request to “follow” people to create
                    a “Trusted Network.” Creators can choose whether to send Task requests only to Taskers in their
                    Trusted Networks or to the local community. Taskers can choose whether to see and accept
                    Tasks from Creators in their Trusted Networks or in the local community.
                </p>
                <h4>B. Creating a Booking, Performing a Task, and Payment</h4>
                <p>
                    Creators and Taskers enter into agreements with each other when they agree to the details of a
                    particular Task and create a “Booking.” A Creator initiates the process by creating a Task, which
                    appears on the feeds of Taskers in the Creator’s Trusted Network. A Booking is created when a
                    Tasker accepts the Task, and the Creator accepts that Tasker. A Tasker is not obligated to accept
                    a Creator’s request and a Creator is not obligated to accept a Tasker. Either party may, at their
                    discretion, decline for any reason. In the event a Booking is not made, the Task can go back to
                    the Creator’s Trusted Network feed.
                </p>
                <p>
                    Once a Booking is completed, Creators and Taskers agree to honor the price and other terms of
                    that Booking, as acknowledged in the Booking confirmation. As soon as the Tasker indicates that
                    a Task is complete, the Creator will be prompted for payment. Payments are made through a
                    third-party payment processor (“Payment Processor”). Payments go to the Tasker’s on-line
                    wallet as “Pocketask Cash” and can be withdrawn by the Tasker pursuant to the Payment
                    Processor’s verification requirements.
                </p>
                <p>
                    The Task transaction is between the Tasker and the Creator and Pocketask’s sole role is to
                    facilitate the transaction. The Tasker, not Pocketask, is responsible for performing the Task. The
                    Creator, not Pocketask, is responsible for payment for the Task.
                </p>
                <h4>C. Fees</h4>
                <ol>
                    <li>
                        Fees. We charge a service fee (“Service Fee”) for the Pocketask Service, which is
                        calculated as a percentage of the amount a Creator agrees to pay a Tasker for a
                        particular Task. The Service Fee is included in the amount Creators are charged for a
                        Booking and deducted from the payment a Tasker will receive. It is collected for each
                        Booking. Our Service Fees are described here [link]. The Payment Processor may also
                        charge a processing fee, which would be included in the total amount Creators are
                        charged for a Booking. Where required by law, the amount charged will also be inclusive
                        of applicable taxes.
                    </li>
                    <li>
                        Third-Party Payment Processor. You agree to be bound by the Payment Processor’s
                        terms of service and privacy policy. We are not responsible for the Payment Processor’s
                        services. The Payment Processor, and not us, is responsible for properly transacting
                        payments through its platform, and you agree to release and hold us harmless from any
                        errors, negligence, or misconduct of the Payment Processor.
                    </li>
                    <li>
                        Payment Disputes; Payment Outside of the Service. Any payment disputes are between
                        the Creator and Tasker and Pocketask has no obligation to mediate or facilitate any
                        resolution. Further, Pocketask has no responsibility or liability with respect to any tips,
                        bonuses, or other payments made outside of the Service
                    </li>
                    <li>
                        Payment Holds. If you are a Tasker, we reserve the right to issue a hold on amounts
                        otherwise payable to you if there is a reasonable suspicion of fraudulent activity
                        involving your account(s) or for other similarly compelling reason involving protection of
                        Pocketask, the Pocketask community, or third-party rights. We may also recommend that
                        Payment Processors restrict your access to funds in your account under the same
                        circumstances.
                    </li>
                    <li>
                        5. Taxes. Except for taxes on Pocketask’s own income and gross receipts or where we are
                        otherwise required to collect taxes, you acknowledge that you are solely responsible to
                        pay any applicable taxes that arise as a result of your purchase, provision, or use of Tasks
                        via the Pocketask Service. This includes, without limitation, any form of sales tax, VAT, or
                        income tax on fees paid or received by you through the Pocketask Service.
                    </li>
                </ol>
                <h4>D. Reviews</h4>
                <p>
                    The Pocketask Service may provide the ability to leave public and/or private reviews of
                    Registered Users. You acknowledge that even private reviews may be shared with third parties
                    in accordance with applicable law and our Privacy Policy and that Pocketask has no obligation to
                    preserve or indefinitely store any reviews. If you are a Tasker, we have no obligation to provide
                    you with the content of any reviews about you, whether before or after termination of your
                    account for the Pocketask Service. We will have no liability to you for any deletion, disclosure,
                    loss, or modification of these reviews. We reserve the right to screen, edit, or remove reviews
                    from the Pocketask Service at any time. We have no obligation to remove any reviews.
                </p>
                <h3>VI. Our Rules of Conduct.</h3>
                <p>If you use the Service, you must:</p>
                <ul>
                    <li>Create only one account (your own);</li>
                    <li>Use the same name you use in everyday life;</li>
                    <li>Provide and maintain true, accurate, and current information about yourself, your skills,
                        your family, and/or your job needs;
                    </li>
                    <li>Use the Service only to find or manage jobs relating to basic chores;</li>
                    <li>Follow all applicable local, state, and federal laws and regulations;</li>
                    <li>Fulfill the commitments you make to other Registered Users, including paying and
                        receiving payment through the Service, communicating clearly and promptly with other
                        Registered Users, and being present and/or available at the time you agree upon with
                        your Creator or Tasker, as the case may be;</li>
                    <li>As a Tasker, provide timely, high-quality services to Creators, only offer and provide
                        services for which you have the necessary skills and expertise, and perform the Tasks
                        safely; and
                    </li>
                    <li>
                        As a Creator, only use Taskers for age-appropriate and safe chores and provide an
                        appropriate and safe environment for Taskers to undertake the Tasks.
                    </li>
                </ul>
                <p>You also agree not to use (or allow or enable others to use) the Service to engage in any of the
                    following conduct:
                </p>
                <ul>
                    <li>
                        Create a false identity for the purpose of misleading others;
                    </li>
                    <li>
                        Do, share, or promote anything that is illegal or unlawful, misleading, discriminatory, or
                        fraudulent;
                    </li>
                    <li>Contact anyone who has asked not to be contacted; “stalk” or otherwise harass anyone;</li>
                    <li>Make any statements or take any actions that are: libelous or defamatory; vulgar,
                        indecent, or obscene; pornographic or sexually explicit; racially, culturally, or ethnically
                        offensive; harmful, harassing, intimidating, threatening, or abusive; or impersonating or
                        appearing to impersonate someone else;
                    </li>
                    <li>Post reviews about Taskers and Creators that aren’t based on your personal experience,
                        that are intentionally inaccurate or misleading, or that violate this Agreement;
                    </li>
                    <li>
                        Affect Pocketask adversely or reflect negatively on us, our goodwill, name, or reputation
                        or cause duress, distress, or discomfort to us or anyone else, or discourage any person,
                        firm, or enterprise from using all or any portion, features, or functions of the Service, or
                        from advertising, linking, or becoming a supplier to us in connection with the Service;
                    </li>
                    <li>
                        Access or collect data or information, including email addresses or other contact
                        information, from the Service using automated means (without our prior permission) or
                        attempt to access data or information you do not have permission to access;
                    </li>
                    <li>
                        Send or result in the transmission of junk e-mail, chain letters, duplicative or unsolicited
                        messages, surveys, contests, pyramid schemes, or so-called “spamming” and “phishing”;
                    </li>
                    <li>
                        Unless approved by us, use the Service for commercial or business purposes, including,
                        without limitation, advertising, marketing, or offering goods or services, whether or not
                        for financial or any other form of compensation or through linking with any other
                        website or web pages;
                    </li>
                    <li>
                        Use cheats, exploits, automation software, bots, hacks, mods, or any unauthorized
                        third-party software designed to modify or interfere with the Service;
                    </li>
                    <li>
                        Transmit, distribute, or upload programs or material that contain malicious code, such as
                        viruses, timebombs, cancelbots, worms, trojan horses, spyware, or other potentially
                        harmful programs or other material or information;
                    </li>
                    <li>
                        Violate any laws, regulations (including, without limitation, laws regarding the
                        transmission of technical data or software exported from the United States), judicial or
                        governmental orders or any treaties, or violate or infringe upon any intellectual property
                        rights, rights of publicity or privacy, or any other rights of ours or of any other person,
                        firm, or enterprise;
                    </li>
                    <li>
                        Gain unauthorized access to the Service, other users’ accounts, names, passwords,
                        personally identifiable information, or other computers, websites, or pages, connected
                        or linked to the Service;Î
                    </li>
                    <li>
                        Disrupt, impair, alter, or interfere with the use, features, functions, operation, or
                        maintenance of the Service or the rights of use and enjoyment of the Service by any
                        other person, firm, or enterprise; or
                    </li>
                    <li>
                        Use the Service in any manner which violates or is inconsistent with the terms and
                        conditions of this Agreement.
                    </li>
                </ul>
                <p>
                    We can remove or restrict access to content that violates these provisions and can remove or
                    restrict access to your content or information.
                </p>
                <p>
                    Remember that you alone are responsible for all activities, acts, and omissions that occur in,
                    from, through, or under your account. You must not share your password, give access to your
                    account to others, use your account on behalf of someone else, or transfer your account to
                    anyone else
                </p>
                <h3>VII. Identity and Sex Offender Registry Checks.</h3>
                <p>
                    By registering to use the Service, you authorize Pocketask and acknowledge that, for purposes
                    of promoting the safety and integrity of the Service, Pocketask reserves the right, but not the
                    obligation, to run adult Registered Users through sex offender registries (“Offender Registries”).
                </p>
                <p>
                    Further, by registering to use the Service and, if applicable to you as an adult Tasker, subject to
                    your additional authorization, you authorize Pocketask and acknowledge that, for purposes of
                    promoting the safety and integrity of the Service, Pocketask reserves the right, but not the
                    obligation, to also use third-party identification verification companies to verify adult Registered
                    Users’ identities (“Identity Verification”).
                </p>
                <p>
                    You understand and agree that Pocketask may, in its sole discretion, review and rely on
                    information from the Offender Registries and Identity Verification in deciding whether to
                    suspend or terminate a Registered User. You agree that Pocketask is not responsible or liable in
                    any way in the event that any information in Offender Registries or from Identity Verifications is
                    not accurate, timely, or complete.
                </p>
                <p>
                    <strong>
                        BY AGREEING TO THESE TERMS, BY USING THE SERVICE, AND, IF APPLICABLE TO YOU AS AN
                        ADULT TASKER, SUBJECT TO YOUR ADDITIONAL AUTHORIZATION, YOU AGREE TO ALLOW
                        POCKETASK TO PERFORM THESE CHECKS FOR THE PURPOSES DESCRIBED ABOVE. IF YOU DO
                        NOT WANT THESE CHECKS TO BE PERFORMED, YOU SHOULD NOT USE POCKETASK.
                    </strong>
                </p>
                <h3>VIII. Optional Background Checks</h3>
                <h4>A. Optional Background Checks</h4>
                <p>
                    Pocketask offers Registered Users access to third-party consumer reporting agencies that
                    provide various background check services (collectively, “Optional Background Checks”).
                    Optional Background Checks are available for purchase by Creators and Taskers and require
                    payment of a separate fee and consent of a parent or guardian if the Registered User is a minor.
                    Optional Background Checks are regulated by the Fair Credit Reporting Act ("FCRA"), and the
                    reports resulting from these services may be considered "consumer reports" under FCRA.
                    Consumer reports may contain information on your character, general reputation, personal
                    characteristics, and mode of living, including but not limited to consumer credit, criminal
                    history, workers’ compensation, driving, employment, military, civil, and educational data and
                    reports.
                </p>
                <p>
                    Pocketask will receive a copy of each Optional Background Check. See Section VIII.C below for
                    information regarding Pocketask’s use of these Optional Background Checks. You are
                    responsible for making sure that the email address you provide to Pocketask is correct and
                    appropriate given that sensitive information will be sent to it.
                    If you have ordered an Optional Background Check on yourself through Pocketask, we may
                    indicate in your profile that you have completed that check. While you may choose to share the
                    results with another Registered User, Pocketask will not do so.
                    We don’t provide and are not responsible or liable in any manner for, any background checks,
                    including Optional Background Checks. We don’t endorse or make any representations or
                    warranties regarding the reliability, accuracy, timeliness, or completeness of information in any
                    background checks, including Optional Background Checks. We do not independently verify
                    information in the Optional Background Checks.
                </p>
                <p>
                    Be aware of the following limitations in all background checks, including Optional Background
                    Checks: Except as otherwise expressly provided in this Agreement or through the Service,
                    Pocketask does not automatically run background checks on all Registered Users. Background
                    checks may vary by type, breadth, and depth. Records not available to third-party background
                    check agencies will not be included in the results. Not all arrest logs and records, conviction
                    and correction records, and sex offender registries are available in all jurisdictions. In many
                    jurisdictions there is a delay before arrest logs and records, conviction and correction records,
                    and sex offender registries are included in background checks. Juvenile records and offenses
                    for minors may not appear in the public record and are therefore not included in the results.
                    Dismissed cases, arrests not resulting in convictions, arrests, or convictions in foreign
                    countries, and nolle prosequi (abandonment of prosecution) may not be reported. Traffic
                    violations are not included unless a jurisdiction reports them as criminal offenses. In the
                    jurisdictions where traffic violations are reported as criminal offenses, such traffic violations
                    may be included in the results as misdemeanors or felonies.
                </p>
                <h4>B. FCRA Responsibilities of Users of Optional Background Checks</h4>
                <p>
                    The use of any Optional Background Check reports is governed by the FCRA and certain state
                    laws. If you request access to an Optional Background Check, you are considered an end user of
                    the check, and must agree to a required End User Certification before the check will be
                    11
                    processed. This End User Certification requires you to certify that you will comply with the FCRA
                    and any applicable state laws and summarizes key legal obligations. A summary of your
                    responsibilities in using the information contained in the Optional Background Check can be
                    found at <a href='http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know'>http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know</a>.
                    If there is negative data in an Optional Background Check you receive, and you
                    choose to take "adverse action" (i.e., if you choose to decline a Tasker for a Task) on the basis of
                    this negative data, you must agree to take certain procedural steps, which can be found at <a href='http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know'>http://www.ftc.gov/tips-advice/business-center/guidance/using-consumer-reports-what-employers-need-know</a>.
                    These include notifying the Tasker who is the subject of the Optional
                    Background Check of your decision to take adverse action based on information contained in
                    the report and the Tasker’s right to contest the accuracy or completeness of the report. If the
                    Tasker contests the report with the consumer reporting agency, you are required to suspend the
                    hiring process while the agency researches the accuracy and completeness of the report.
                </p>
                <h4>C. We May Review Optional Background Checks That You Order</h4>
                <p>
                    <strong>
                        If you choose to order an Optional Background Check on yourself, and subject to your
                        authorization, you acknowledge and agree that Pocketask may review and use any Optional
                        Background Checks you have ordered or authorized about yourself for the purpose of
                        protecting the safety and integrity of our App and its users, which may, in the case of certain
                        Taskers, be considered an employment purpose pursuant to the FCRA. Pocketask reserves the
                        right to terminate your account based on the information contained in an Optional
                        Background Check, even if such information was subsequently dismissed.
                    </strong>
                </p>
                <p>
                    If we terminate your account or access to the Service on the basis of information in an Optional
                    Background Check, we will notify you and provide you the name and contact information of the
                    consumer reporting agency that created the report. We will also provide you a copy of the
                    report unless the consumer reporting agency has already provided you a copy or access to it.
                    You hereby represent, understand, and expressly agree that Pocketask does not have control
                    over or assume any responsibility for the quality, accuracy, or reliability of the information
                    included in these Optional Background Checks. Furthermore, any inaccuracies in the report
                    must be addressed with the consumer reporting agency that issued it and not with Pocketask.
                </p>
                <h3>IX. Intellectual Property Provisions.</h3>
                <h4>A. Limited License To Use The Service.</h4>
                <p>
                    Subject to your compliance with this Agreement, Pocketask grants you a limited, revocable,
                    non-exclusive, non-transferable license to use and access the Service, only for your personal,
                    non-commercial use.
                </p>
                <p>
                    You agree not to (a) use the Service for any use or purpose other than as expressly allowed in
                    this Agreement, or (b) copy, adapt, modify, prepare derivative works based upon, distribute,
                    license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, or
                    otherwise exploit the Service or any portion of the Service, except as expressly allowed in this
                    12
                    Agreement. No licenses or rights are granted to you by implication or otherwise under any
                    intellectual property rights owned or controlled by Pocketask or its licensors, except for the
                    permissions and rights expressly granted in this Agreement.
                </p>
                <h4>B. Proprietary Rights.</h4>
                <p>
                    All content and materials comprising the Service, including without limitation, software,
                    computer code, HTML, APIs, design, artwork, graphics, icons, photos, video, animations,
                    sounds, musical compositions, audio-visual effects, objects, concepts, formats, effects, methods
                    of operation and documentation, and the selection and arrangement of those items (“Content”)
                    and the trademarks, service marks, trade dress, and logos contained therein (“Marks”) are
                    owned by Pocketask or its licensors. All other trademarks, service marks, trade names, and
                    logos used on the Service, with or without attribution, are the property of their respective
                    owners.
                </p>
                <p>
                    You acknowledge and agree that the Content and Marks are protected by copyrights,
                    trademarks, service marks, patents, trade secrets, and/or other proprietary rights and laws of
                    the United States and similar laws of other jurisdictions. Pocketask reserves all rights, including
                    without limitation, all intellectual property rights, or other proprietary rights, in connection with
                    the Content and Marks.
                </p>
                <p>
                    You will not obtain any ownership interest in the Content, Marks, or the Service through this
                    Agreement or otherwise. All rights to the Content not expressly granted in this Agreement are
                    reserved to their respective owners. Except as expressly authorized by this Agreement or on the
                    Service, you may not copy, reproduce, distribute, republish, download, perform, display, post,
                    transmit, exploit, create derivative works, or otherwise use any of the Content or Marks in any
                    form or by any means, without the prior written authorization of Pocketask or the respective
                    owner, which may be granted in its sole discretion.
                </p>
                <h3>C. User Generated Content.</h3>
                <p>
                    User generated content (“UGC”) is defined as any content, material, data, and information that
                    you create, upload to, or transmit through the Service, or that you have authorized Pocketask to
                    extract from any profile you have through a third party social network (e.g., Facebook or
                    Google) or other third party platform (“Third Party Service”), including, without limitation, text,
                    images, stories, photos, suggestions, comments, reviews, questionnaires, and survey answers.
                </p>
                <p>
                    By posting any UGC on, through, or in connection with the Service, you hereby grant to
                    Pocketask a non-exclusive, perpetual, worldwide, irrevocable, royalty-free, and transferable
                    license to use, modify, excerpt, adapt, store, translate, sub-license, create derivative works and
                    compilations based upon, publicly perform, publicly display, reproduce, and distribute such
                    UGC, in whole or in part, on, through, or in connection with the Service or in connection with
                    any commercialization, distribution, or syndication thereof on and through all media formats
                    now known or hereafter devised, for any and all purposes including, but not limited to,
                    13
                    promotional, marketing, research, trade, and commercial purposes. Pocketask’s use of such
                    UGC will not require any further notice to you and such use will be without the requirement of
                    any permission from or payment to you or to any other person or entity. Pocketask may remove
                    any UGC at its sole discretion at any time. UGC is not confidential and will not be returned to
                    you.
                </p>
                <p>
                    If UGC that you create and publish reproduces your name, likeness, image, and/or voice
                    (collectively, “Image”), you acknowledge and agree that Pocketask has the right to use your
                    Image as part of the Service pursuant to the broad license stated above. If the UGC you publish
                    on the Service features the Image of any person other than yourself, you represent and warrant
                    that you have received permission from the relevant person for the use of that person’s Image
                    by Pocketask in accordance with the terms of this Agreement, and you indemnify Pocketask for
                    any losses which Pocketask may incur pursuant to the Indemnification provision of this
                    Agreement.
                </p>
                <p>
                    Please note that not all aspects of the Service allow the posting of UGC and Pocketask reserves
                    the right to limit the storage capacity of UGC that you post on, through, or in connection with
                    the Service. Pocketask also reserves the right (but not the obligation) to remove and
                    permanently delete any UGC from the Service with or without notice for any reason.
                </p>
                <p>
                    You represent and warrant that: (a) you own all rights to your UGC and have the authority to
                    grant the license set forth in this Section, and (b) your UGC does not violate the privacy rights,
                    publicity rights, intellectual property rights, contract rights, or any other rights of any person or
                    entity. You agree to pay for all royalties, fees, and any other monies owing any person or entity
                    by reason of the use of any UGC posted by you on or through the Service.
                </p>
                <p>
                    You agree not to share, post, or otherwise disseminate any UGC that is, in Pocketask’s sole
                    discretion, offensive, demeaning, defamatory, or otherwise inappropriate including, but not
                    limited to: content that directly or indirectly threatens an individual or group, promotes
                    self-injury or suicide, is bullying or harassing, facilitates criminal or other illegal activity,
                    promotes sexual violence or exploitation, or is hate speech.
                </p>
                <h4>D. User Feedback.</h4>
                <p>
                    In general, Pocketask does not accept, via the Service or otherwise, unsolicited submissions
                    including, without limitation, submissions of information, suggestions, proposals, ideas, or
                    concepts (collectively, “User Feedback”). Therefore, any similarity between an unsolicited
                    submission and any elements of the Service would be purely coincidental. However, on
                    occasion, we may request feedback from our users in the form of a survey or similar submission.
                    All User Feedback provided by you to Pocketask, whether solicited or not, and all rights
                    contained therein, will become the sole property of Pocketask and may be used, copied,
                    sublicensed, adapted, transmitted, distributed, publicly performed, published, displayed, or
                    deleted as Pocketask sees fit. You agree that you are not entitled to any compensation, credit,
                    or notice whatsoever in connection with your User Feedback or Pocketask’s use thereof.
                </p>
                <h4>E. Copyright Complaints.</h4>
                <p>
                    Pocketask respects the intellectual property rights of others and expects users of the Service to
                    do the same. We will investigate notices of alleged copyright infringement that comply with
                    applicable law and are properly provided to us. We will also take appropriate actions under the
                    Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws with
                    respect to any alleged or actual infringement.
                </p>
                <p>
                    If you believe that your content has been copied in a way that constitutes copyright
                    infringement, please provide us with the following information: (i) a physical or electronic
                    signature of the copyright owner or a person authorized to act on their behalf; (ii) identification
                    of the copyrighted work claimed to have been infringed; (iii) identification of the material that is
                    claimed to be infringing or to be the subject of infringing activity and that is to be removed or
                    access to which is to be disabled, and information reasonably sufficient to permit us to locate
                    the material; (iv) your contact information, including your address, telephone number, and an
                    email address; (v) a statement by you that you have a good faith belief that use of the material
                    in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
                    (vi) a statement that the information in the notification is accurate, and, under penalty of
                    perjury, that you are authorized to act on behalf of the copyright owner.
                </p>
                <p>
                    We reserve the right to remove content alleged to be infringing without prior notice and at our
                    sole discretion. In appropriate circumstances, Pocketask will also terminate a Registered User’s
                    account if the Registered User is determined to be a repeat infringer. Our designated copyright
                    agent for notice of alleged copyright infringement appearing on the Service is:
                </p>
                <p>Pocketask, LLC</p>
                <p>Attn: Copyright Agent</p>
                <p>1212 East 54th Street</p>
                <p>Unit 3</p>
                <p>Chicago, IL 60615</p>
                <p>E-mail: <a href='mailto:nkabbani@mypocketask.com'>nkabbani@mypocketask.com</a></p>
                <h3>X. Third Party and External Sites.</h3>
                <h4>A. App Store Terms</h4>
                <p>
                    The App may be available for download from one or more platforms, such as Apple iTunes or
                    Google Play (“App Stores”). Your download, installation, access to or use of the App is also
                    bound by the terms and conditions and privacy policies of the applicable App Store (“App Store
                    Terms”). If there is any conflict between these Terms and the App Store Terms, then these
                    Terms will prevail.
                </p>
                <p>
                    The App is independent of and is not associated, affiliated, sponsored, endorsed, or in any way
                    linked to any App Store. You and Pocketask acknowledge that these Terms are entered into
                    between you and Pocketask only, and not with any App Store, and that Pocketask, not the App
                    Store, is solely responsible for the App and the content thereof to the extent specified in these
                    Terms.
                </p>
                <p>
                    You and Pocketask acknowledge that Pocketask, not the relevant App Store, is responsible for
                    addressing any claims of that you or any third party has relating to the App or your possession
                    and/or use of the App, including, but not limited to: (i) any claim that the App fails to conform
                    to any applicable legal or regulatory requirement; and (ii) claims arising under consumer
                    protection or similar legislation.
                </p>
                <p>
                    You and we acknowledge and agree that the relevant App Store, and that App Store’s
                    subsidiaries, are third-party beneficiaries of these Terms, and that, upon your acceptance of
                    these Terms, that App Store will have the right (and will be deemed to have accepted the right)
                    to enforce these Terms against you as a third-party beneficiary thereof.
                </p>
                <h4>B. Access To The Service.</h4>
                <p>
                    We may allow you to use or access the Service through a social network (e.g., Facebook or
                    Google) or other Third-Party Service. If so, you may be required to have an account with the
                    Third-Party Service through which you connect to the Service. You agree to comply with the
                    Third-Party Service’s terms of use as well as this Agreement. You also agree that Pocketask has
                    no responsibility or liability for any act, error, or omission of any Third-Party Service.
                </p>
                <p>
                    The collection and use of your information, whether sent to us by a Third-Party Service or
                    otherwise provided to Pocketask, is subject to our Privacy Policy. You agree that Pocketask may
                    use your information and other data related to your use of the Service as provided in our
                    Privacy Policy.
                </p>
                <h4>C. Links and Third-Party Websites.</h4>
                <p>
                    The Service may contain links to third-party websites or resources. You acknowledge and agree
                    that we are not responsible or liable for: (i) the availability or accuracy of such websites or
                    resources; or (ii) the content, products, or services on or available from such websites or
                    resources. Links to such websites or resources do not imply any endorsement by Pocketask of
                    such websites or resources or the content, products, or services available from such websites or
                    resources. You acknowledge sole responsibility for and assume all risk arising from your use of
                    any such websites or resources.
                </p>
                <p>
                    From time to time, during your use of the Service, you may communicate with, receive
                    communications from, be re-directed to, interact with, or participate in or use the services or
                    obtain goods and services of or from, third parties such as our advertisers, sponsors,
                    promotional partners (collectively, the “Advertisers”). All such communication, interaction, and
                    participation is between you and such Advertisers and we will not be responsible or liable to
                    you in any way in connection with these activities or transactions (including, without limitation,
                    any representations, warranties, covenants, contracts, or other terms or conditions that may
                    exist between you and the Advertiser or any goods or services you may purchase or obtain from
                    any Advertiser).
                </p>
                <h3>XI. User Disputes.</h3>
                <p>
                    You are solely responsible for your interaction with other users of the Service and other parties
                    that you interact with through the Service. Pocketask hereby disclaims any and all liability to you
                    or any third party relating to your use of the Service. Pocketask reserves the right, but has no
                    obligation, to manage disputes between you and other users of the Service.
                </p>
                <h3>XII. Export Controls.</h3>
                <p>
                    Software available in connection with the App or the Service (the “Software”) is further subject
                    to United States export controls. No Software may be downloaded from the App or the Service
                    or otherwise exported or re-exported in violation of U.S. export laws. Downloading or using the
                    Software is at your sole risk.
                </p>
                <h3>XIII. Release of Liability for Conduct and Disputes</h3>
                <p>
                    <strong>
                        By using the Service, you hereby represent, understand, and expressly agree to release and
                        hold Pocketask (and Pocketask’s officers, directors, shareholders, employees, parents,
                        subsidiaries, other affiliates, successors, assignees, agents, representatives, licensors, and
                        independent contractors (the “Pocketask Parties”)) harmless from any claim or controversy
                        that may arise out of:
                    </strong>
                    <ul>
                        <li>
                            the actions or inactions of, or the relationship between, you and any Creator, Tasker, or
                            other user(s) of the Service; and
                        </li>
                        <li>
                            any information, instruction, advice, or services created by any Creator, Tasker, or
                            other third party that appeared on, or was communicated through, the Service.
                        </li>
                    </ul>
                    <p>
                        Pocketask and the Pocketask Parties expressly disclaim any liability whatsoever for any
                        damage, suits, claims, and/or controversies that have arisen or may arise, whether known or
                        unknown, from the above.
                    </p>
                </p>
                <h3>XIV. Disclaimers; Limitations; Waivers; Indemnification.</h3>
                <h4>A. Disclaimer of Warranty.</h4>
                <p>
                    Your access to and use of the Service or any Content is at your own risk. You understand and
                    agree that the Service is provided to you on an “AS IS” and “AS AVAILABLE” basis. Without
                    limiting the foregoing, POCKETASK DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED, OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. We make
                    no warranty and disclaim all responsibility and liability for the completeness, accuracy,
                    availability, timeliness, security, or reliability of the Service or any Content. Pocketask will not be
                    responsible or liable for any harm to your computer system, mobile device, loss of data, or
                    other harm that results from your access to or use of the Service or any Content. You also agree
                    that Pocketask has no responsibility or liability for the deletion of, or the failure to store or to
                    transmit, any Content and other communications maintained by the Service. We make no
                    warranty that the Service will meet your requirements or be available on an uninterrupted,
                    secure, or error-free basis. No advice or information, whether oral or written, obtained from
                    Pocketask or through the Service, will create any warranty not expressly made herein.
                </p>
                <h3>B. Assumption of Risk.</h3>
                <p>
                    While Pocketask takes certain steps to help promote the safety of the Service, finding jobs and
                    help through an online platform like ours comes with certain inherent risks. We don’t provide
                    any training, supervision, or monitoring of Creators or Taskers, and we can’t guarantee that all
                    of your interactions with other Registered Users, site visitors, their families, or others associated
                    with them, will always be 100% safe and respectful. You agree to assume all risks when using
                    the Service, including but not limited to injury, illness, death, and all other risks associated with
                    any online or offline interactions with users of the Service. You also agree not to rely solely on
                    steps Pocketask may take to vet or screen Creators or Taskers and/or their Content, or otherwise
                    to promote the safety of the Service. You further agree to take all necessary precautions,
                    including those set forth in this Agreement, when interacting with other site visitors, Registered
                    Users, their families, and others associated with them.
                </p>
                <h3>C. Limitation of Liability.</h3>
                <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, POCKETASK AND THE POCKETASK
                    PARTIES WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF
                    18
                    PROFITS, DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR
                    ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (B) ANY CONDUCT OR
                    CONTENT OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, ANY
                    DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (C) ANY
                    CONTENT OBTAINED FROM THE SERVICE; AND (D) UNAUTHORIZED ACCESS, USE, OR
                    ALTERATION OF YOUR TRANSMISSIONS OR UGC, WHETHER BASED ON WARRANTY, CONTRACT,
                    TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, WHETHER OR NOT POCKETASK
                    HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF A REMEDY SET
                    FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                </p>
                <h4>D. Indemnification.</h4>
                <p>
                    Upon Pocketask’s request, you agree to defend, indemnify, and hold harmless Pocketask and the
                    Pocketask Parties from all liabilities, claims, and expenses, including attorneys’ fees, that arise
                    from or relate to a breach of this Agreement for which you are responsible or in connection
                    with your distribution of any UGC on or through the Service. Without limiting the generality of
                    the foregoing, you agree to indemnify and hold Pocketask, the Pocketask Parties, and the
                    Pocketask licensors, contractors, vendors, and content providers harmless for any improper or
                    illegal use of your account, including the illegal or improper use of your account by someone to
                    whom you have given permission to use your account. You agree that you will be personally
                    responsible for your use of the Service and for all your communication and activity on the
                    Service, including any UGC you contribute, and that you will indemnify and hold harmless
                    Pocketask, the Pocketask Parties, and Pocketask licensors, contractors, vendors, and content
                    providers from any liability or damages arising from your conduct on the Service, including any
                    UGC you contribute.
                </p>
                <p>
                    Pocketask, the Pocketask Parties, and Pocketask licensors, contractors, vendors, and content
                    providers reserve the right, at their own expense, to assume the exclusive defense and control
                    of any matter otherwise subject to indemnification by you. In that event, you will have no
                    further obligation to provide indemnification to Pocketask, the Pocketask Parties, and/or the
                    Pocketask licensors, contractors, vendors, and content providers in that matter.
                </p>
                <h3>XV. Agreement to Arbitrate</h3>
                <p>
                    This Section XV is referred to in this Agreement as the “Arbitration Agreement.” Unless you
                    opt-out by complying with the opt-out procedures in Section XV.F below, you agree that all
                    disputes or claims that have arisen or may arise between you and Pocketask, whether relating
                    to these Terms (including any alleged breach thereof), the Service, or otherwise, will be
                    resolved exclusively through final and binding arbitration, rather than through a court, in
                    accordance with the terms of this Arbitration Agreement, except you may assert individual
                    claims in small claims court, if your claims qualify. Your rights will be determined by a neutral
                    arbitrator, not a judge or jury. The Federal Arbitration Act governs the interpretation and
                    enforcement of this Arbitration Agreement
                </p>
                <h4>A. Prohibition of Class and Representative Actions and Non-Individualized Relief</h4>
                <p>
                    <strong>
                        YOU AND POCKETASK AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER
                        ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                        PURPORTED CLASS OR REPRESENTATIVE ARBITRATION, ACTION, OR PROCEEDING. UNLESS
                        BOTH YOU AND POCKETASK EXPRESSLY AGREE OTHERWISE, THE ARBITRATOR MAY NOT
                        CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AGAINST POCKETASK
                        AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE,
                        OR CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY,
                        INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
                        RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT
                        PARTY’S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER POCKETASK
                        USERS.</strong>If any court or arbitrator determines that the class action waiver set forth in this
                    paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class
                    basis, then the arbitration provision set forth above shall be deemed null and void in its entirety
                    and the parties shall be deemed to have not agreed to arbitrate disputes.
                </p>
                <h4>B. Pre-Arbitration Dispute Resolution</h4>
                <p>
                    Pocketask is always interested in resolving any disputes in a friendly and efficient way. Before
                    you start arbitration, we encourage you to contact us to explain your complaint, as we may be
                    able to resolve it without the need for arbitration. You may contact us at <a href='team@mypocketask.com'>team@mypocketask.com.</a>
                </p>
                <h4>C. Arbitration Procedures</h4>
                <p>
                    If we can’t resolve a claim informally, any claim either of us asserts will be resolved <strong>only by
                        binding arbitration and not in courts of general jurisdiction.</strong> Arbitration will be conducted by
                    one neutral arbitrator in accordance with the rules of JAMS that are in effect at the time the
                    arbitration is initiated (collectively referred to as the “JAMS Rules”), as modified by this
                    Arbitration Agreement, and excluding the JAMS Class Action Procedures. For information on
                    JAMS, please visit its website, <a href='https://www.jamsadr.com/'>https://www.jamsadr.com/</a>. Information about JAMS’s Rules and
                    fees for consumer disputes can be found at the JAMS consumer arbitration page,
                    <a href='https://www.jamsadr.com/rules-comprehensive-arbitration/'>https://www.jamsadr.com/rules-comprehensive-arbitration/</a>. If there is any inconsistency
                    between the JAMS Rules and this Arbitration Agreement, the terms of this Arbitration
                    Agreement will control unless the arbitrator determines that the application of the inconsistent
                    Arbitration Agreement terms would not result in a fundamentally fair arbitration. The arbitrator
                    must also follow the provisions of the Terms as a court would, including without limitation, the
                    limitation of liability provisions in Section XIV. Although arbitration proceedings are usually
                    simpler and more streamlined than trials and other judicial proceedings, the arbitrator can
                    award the same damages and relief on an individual basis that a court can award to an
                    individual under the Terms and applicable law. Decisions by the arbitrator are enforceable in
                    court and may be overturned by a court only for very limited reasons.
                </p>
                <p>
                    To start an arbitration against Pocketask, you must write a demand for arbitration that includes
                    a description of the dispute and the amount of damages you wish to recover. You can find a
                    copy of a Demand for Arbitration at <a href='www.jamsadr.com'>www.jamsadr.com</a>. You should send two copies of the
                    Demand, plus the appropriate filing fee, to JAMS at 71 South Wacker Drive, Suite 2400, Chicago
                    IL 60606, and send one copy to Pocketask, Attn: Legal Department, 1212 East 54th Street, Unit 3,
                    Chicago, IL 60615. For more information, see the JAMS arbitration rules and forms,
                    <a href='https://www.jamsadr.com/rules-download/'>https://www.jamsadr.com/rules-download/</a>. You may represent yourself in the arbitration or be
                    represented by an attorney or another representative. Once we receive your arbitration claim,
                    we may assert any counterclaims we may have against you.
                    The arbitration shall be held in the county in which you reside or at another mutually agreed
                    location. If the value of the relief sought is $10,000 or less, you or Pocketask can choose to have
                    the arbitration done by telephone or based solely on written submissions. Whatever is chosen
                    will be binding on you and Pocketask subject to the arbitrator’s discretion to require an
                    in-person hearing if the circumstances warrant. Attendance at any in-person hearing may be
                    made by telephone by you and/or Pocketask unless the arbitrator requires otherwise.
                    The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority
                    to resolve all claims relating to or arising out of this Agreement, or the breach thereof, whether
                    sounding in contract, tort, or otherwise and all disputes arising out of or relating to the
                    interpretation, applicability, enforceability, or formation of this Agreement, including, but not
                    limited to any claim that all or any part of the Agreement is void or voidable, or whether a claim
                    is subject to arbitration. The arbitrator shall be empowered to grant whatever relief would be
                    available in a court under law or in equity. The arbitrator’s award must be written and binding
                    on the parties and may be entered as a judgment in any court of competent jurisdiction.
                    The arbitrator will decide the substance of all claims in accordance with the laws of the State of
                    Illinois, including recognized principles of equity, and will honor all claims of privilege recognized
                    by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different
                    Pocketask users but is bound by rulings in prior arbitrations involving the same Pocketask user
                    to the extent required by applicable law.
                </p>
                <h4>D. Costs of Arbitration</h4>
                <p>
                    Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”) will
                    be governed by the JAMS Rules, unless otherwise provided in this Agreement to Arbitrate. In
                    certain instances, if you demonstrate to the arbitrator that you are economically unable to pay
                    your portion of the Arbitration Fees or if the arbitrator otherwise determines for any reason
                    that you should not be required to pay your portion of the Arbitration Fees, Pocketask will pay
                    your portion of such fees. In addition, if you demonstrate to the arbitrator that the costs of
                    arbitration will be prohibitive as compared to the costs of litigation, Pocketask will pay as much
                    of the Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being
                    cost-prohibitive. Each party will be responsible for all other fees it incurs in connection with the
                    arbitration, including without limitation, all attorney fees. In the event the arbitrator determines
                    the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse Pocketask for
                    all fees associated with the arbitration paid by Pocketask on your behalf that you otherwise
                    would be obligated to pay under the JAMS rules.
                </p>
                <h4>E. Confidentiality</h4>
                <p>
                    All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator,
                    will be strictly confidential for the benefit of all parties.
                </p>
                <h4>F. Opt-Out Procedure</h4>
                <p>
                    You can choose to reject this Arbitration Agreement by mailing us a written opt-out notice
                    (“Opt-Out Notice”) in accordance with the terms of this Section. The Opt-Out Notice must be
                    postmarked no later than 30 days after the date you use the Service for the first time. You must
                    mail the Opt-Out Notice to Pocketask, Attn: Legal Department, 1212 East 54th Street, Unit 3,
                    Chicago, IL 60615. The Opt-Out Notice must state that you do not agree to the Arbitration
                    Agreement and must include your name, address, phone number, and the email address used
                    to log in to the Pocketask account to which the opt-out applies. You must sign the Opt-Out
                    Notice for it to be effective. This procedure is the only way you can opt out of the Arbitration
                    Agreement. If you opt out of the Arbitration Agreement, Pocketask will also not be bound by
                    these arbitration provisions. All other terms of these Terms will continue to apply. Opting out of
                    the Arbitration Agreement has no effect on any previous, other, or future arbitration
                    agreements that you may have with us.
                </p>
                <h4>G. Future Changes to this Arbitration Agreement</h4>
                <p>
                    Notwithstanding any provision in these Terms to the contrary, you agree that if Pocketask makes
                    any change to this Arbitration Agreement (other than a change to any notice address or website
                    link provided herein) in the future, that change will not be effective until at least 60 days from
                    the date of posting and will not apply to any claim that was filed in a legal proceeding against
                    Pocketask prior to the effective date of the change. Moreover, if we seek to terminate this
                    Arbitration Agreement from these Terms, such termination will not be effective until 30 days
                    after the version of these Terms not containing the Arbitration Agreement is posted to the Site
                    and will not be effective as to any claim that was filed in a legal proceeding against Pocketask
                    prior to the effective date of removal.
                </p>
                <h4>H. Governing Law and Jurisdiction</h4>
                <p>
                    These Terms, and all claims relating to or arising out of this contract, or the breach thereof,
                    whether sounding in contract, tort, or otherwise, will be governed by the laws of the State of
                    Illinois, including Illinois’s statutes of limitations governing your claim, without giving effect to
                    its principles of conflicts of law, provided that the Federal Arbitration Act will govern the
                    interpretation and enforcement of Section XV, the Arbitration Agreement.
                    Unless you and Pocketask agree otherwise, in the event that the Arbitration Agreement is found
                    not to apply to you or to a particular claim or dispute (except for small-claims court actions),
                    either as a result of your decision to opt-out of the Arbitration Agreement or as a result of a
                    decision by the arbitrator or a court order, you agree that any claim or dispute that has arisen or
                    may arise between you and Pocketask must be resolved exclusively by a state or federal court
                    located in the State of Illinois.
                </p>
                <p>
                    You and Pocketask agree to submit to the personal jurisdiction of the courts located within the
                    State of Illinois for the purpose of litigating all such claims or disputes.
                </p>
                <h3>XVI. Consent to Electronic Communication</h3>
                <p>
                    By using the Service, you agree to allow Pocketask to communicate with you electronically, and
                    you consent to electronic delivery of notices, documents, or products from Pocketask via the
                    mobile application, online messaging platform, or e-mail. You also agree to check your
                    Pocketask account, alerts, and messages, and the e-mail account you give to Pocketask on a
                    reasonably regular basis to stay informed about important notices and information about your
                    account
                </p>
                <h3>XVII. General Provisions</h3>
                <ol>
                    <li>
                        This Agreement states your entire agreement with Pocketask regarding the use of
                        the Service. If any portion of this Agreement is held invalid or unenforceable,
                        that portion will be construed in accordance with applicable law as nearly as
                        possible to reflect the original intention of the parties, and the remainder of this
                        Agreement will remain in full force and effect.
                    </li>
                    <li>
                        No delay or failure to act under this Agreement will constitute any waiver by
                        Pocketask of any provision of this Agreement.
                    </li>
                    <li>
                        This Agreement, and any rights and licenses granted hereunder, may not be
                        transferred or assigned by you, but may be assigned by Pocketask without
                        restriction.
                    </li>
                </ol>
                <h3>XVIII. Contacting Us.</h3>
                <p>
                    If you have any questions about this Agreement, the Service, or the practices of Pocketask, or to
                    report a violation of this Agreement, please contact us at <a href='team@mypocketask.com'>team@mypocketask.com</a>.
                </p>
            </div>
        </section>
    )
}

export default TermsAndConditionsPage