import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Logo from '../assets/logo.png'

const Header = () => {
    const [hamburgerActive, setHamburgerActive] = useState(false);

    const activateHamburger = () => {
        if(hamburgerActive) {
            setHamburgerActive(false);
        } else {
            setHamburgerActive(true);
        }
    }

    return (
        <header>
            <div className="header-logo">
                <img alt="" src={Logo} className='logo-img'/>
                <Link to="/"><h2 className="logo">Pocketask</h2></Link>
            </div>
            <ul className={"main-menu " + (hamburgerActive ? 'open ' : 'menu-close')}>
                <li><Link onClick={() => setHamburgerActive(false)} to="/">Home</Link></li>
                {/* <li><Link onClick={() => setHamburgerActive(false)} to="/create">Create a task</Link></li> */}
                <li><Link onClick={() => setHamburgerActive(false)} to="/about">About</Link></li>
                <li><Link onClick={() => setHamburgerActive(false)} to="/app">App</Link></li>
                {/* <li><Link onClick={() => setHamburgerActive(false)} to="/faq">FAQ</Link></li> */}
                <li><Link onClick={() => setHamburgerActive(false)} to="/safety">Safety</Link></li>
                {/* <li><Link onClick={() => setHamburgerActive(false)} to="/contact">Contact Us</Link></li> */}
                {/* {localStorage.getItem('user') === null &&
                <li><Link onClick={() => setHamburgerActive(false)} to="/account">Sign In</Link></li>
                } */}
                {localStorage.getItem('user') != null &&
                    <li><Link onClick={() => setHamburgerActive(false)} to="/account">Account</Link></li>
                }
                <li><Link onClick={() => setHamburgerActive(false)} to="/volunteer">Volunteer</Link></li>
            </ul>
            <div className="hamburger">
                <div className="menu-btn" onClick={() => activateHamburger()}>
                    <div className={"burger-line line-1 " + (hamburgerActive ? 'open' : '')}></div>
                    <div className={"burger-line line-2 " + (hamburgerActive ? 'open' : '')}></div>
                    <div className={"burger-line line-3 " + (hamburgerActive ? 'open' : '')}></div>
                </div>
            </div>
        </header>
        // <header>
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-md-3">
        //                 <Link to="/"><h2 className="logo">Pocketask</h2></Link>
        //             </div>
        //             <div className="col-md-9">
        //                 <ul className="main-menu">
        //                     <li><Link to="/">Home</Link></li>
        //                     <li><Link to="/create">Create a task</Link></li>
        //                     <li><Link to="/about">About</Link></li>
        //                     <li><Link to="/app">App</Link></li>
        //                     <li><Link to="/contact">Contact Us</Link></li>
        //                     {localStorage.getItem('user') === null &&
        //                         <li><Link to="/account">Sign In</Link></li>
        //                     }
        //                     {localStorage.getItem('user') != null &&
        //                         <li><Link to="/account">Account</Link></li>
        //                     }
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        // </header>
    )
}

export default Header
