import axios from 'axios';
import React, { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { api } from '../api';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Spinner from 'react-bootstrap/Spinner'

const Activate = ({match}) => {
    const [error, setError] = useState('');
    const [isActivated, setActivated] = useState(false);
    const token = match.params.token;
    const activate = (e) => {
        e.preventDefault();
        axios.post(api + '/activate', {
            token: token
        })
        .then(function (res) {
            setActivated(true);
            setTimeout(function(){window.location.href = '/login'}, 3000);
        })
        .catch(function (e) {
            setError(e.response.data.error);
        });
    }
    if(localStorage.getItem('login')){
        window.location.href = '/account';
        return (
            <div className="spinner-container">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div className="everything fp-container">
            <Header />
            {!isActivated && (
                <div className="container login-section">
                    <div className="row sec-title">
                        <h2>Activate your Account</h2>
                    </div>
                    <div className="row">
                        <div className="login-area">
                            <Form onSubmit={activate}>
                                <button className="login-submit activate-submit" type="submit">
                                    Activate account
                                </button>
                            </Form>
                            {error !== "" && (
                                <Alert variant={"danger"}>{error}</Alert>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {isActivated && (
                <h2 className="center-h2">Account has been activated. Redirecting to Login...</h2>
            )}
            <Footer />
        </div>
    )
}

export default Activate
