import axios from 'axios'
import { PlatformApi } from '../api'



const isLoggedIn = async (setUser) => {
    let token = await localStorage.getItem("api_token")
    if (token) {
        let request = await axios.get(`${PlatformApi}/volunteering/profile`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': token
            },
        })
        if (request.data) {
            try {
                setUser({ ...request.data.data, ok: true })
            } catch (err) {
                window.location.reload()
            }
        }
        return request.data
    }
}

const setToken = async (token) => {
    try {
        console.log("Setting item")
        localStorage.setItem("api_token", token)
    }
    catch (err) {
        console.log("Error setting token: ", err)
    }
    finally {
        console.log("Item set")
    }

}

const deleteToken = async () => {
    try {
        console.log("removing token")
        localStorage.setItem("api_token", "")
    } catch (err) {
        console.log("Error: ", err)
    } finally {
        console.log("Token removed")
    }
}


const supervisorTasks = async (verified,page,tasks,setTasks) => {
    let token = localStorage.getItem("api_token")
    if (token) {
        await axios.post(`${PlatformApi}/tasks/supervisor_tasks`, {
            pageNumber: page,
            verified: verified,
        },{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-AUTH-TOKEN': token
            },
        }).then(res => {
            if (res.data.status){
                if (tasks && tasks.length > 0){
                    setTasks(tasks.concat(res.data.data))
                } else {
                    setTasks(res.data.data)
                }
            }
        })
    }
}

const errors = {
    NETWORK_ERROR: { ok: false, code: 3001, message: "Network error, check your internet connection" },
    CREDENTIALS_ERROR: { ok: false, code: 3002, message: "Incorrect user or password \n if you feel they're correct please verify your email" }
}

const getNotifications = (setNotifications) => {
    let token = localStorage.getItem("api_token")
    return axios.post(`${PlatformApi}/profile/notifications`, {
        request: 0,
        search: '',
        status: [5]
    }, {
        headers: {
            'X-AUTH-TOKEN': token
        }
    }).then(res => {
        var notifications = []
        if (res.data.data.notifications) {
            res.data.data.notifications.forEach((item,i) => {
                notifications[i] = item
                switch (item.type) {
                    case 0:
                        notifications[i]['title'] = item.senderUsername + ' has trusted you.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 1:
                        notifications[i]['title'] = ''
                        notifications[i]['image'] = ''
                        notifications[i]['username'] = ''
                        break;
                    case 2:
                        notifications[i]['title'] = item.senderUsername + ' accepted your friend request.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 6:
                        notifications[i]['title'] = item.senderUsername + ' has accepted your trust request.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 7:
                        notifications[i]['title'] = item.senderUsername + ' has declined your trust request.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 9:
                        notifications[i]['title'] = item.senderUsername + ' has accepted your ' + item.extra.taskTitle + ' task!'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 13:
                        notifications[i]['title'] = item.senderUsername + ' has canceled your ' + item.extra.taskTitle + ' task.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 14:
                        notifications[i]['title'] = item.senderUsername + ' has started your ' + item.extra.taskTitle + ' task.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 20:
                        notifications[i]['title'] = item.senderUsername + ' has reviewed you. Press here to see it.'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 28:
                        notifications[i]['title'] = 'Reminder that ' + item.senderUsername + ' has accepted your ' + item.extra.taskTitle + ' task!'
                        notifications[i]['image'] = item.image
                        notifications[i]['username'] = '@' + item.senderUsername
                        break;
                    case 30:
                        notifications[i]['title'] = 'Need help with housework, or looking after your kids or pets - post a task now to save time.'
                        notifications[i]['image'] = ''
                        notifications[i]['username'] = ''
                        break;
                    case 31:
                        notifications[i]['title'] = 'Reminder that your ' + item.extra.taskTitle + '  task has ended.'
                        notifications[i]['image'] = ''
                        notifications[i]['username'] = ''
                        break;
                    default:
                        notifications[i]['title'] = ''
                        notifications[i]['image'] = ''
                        notifications[i]['username'] = ''
                        break;

                }
            })
            setNotifications(notifications)
        }
    }).catch(err => err)
}


const getSupervisorTaskers = (setStudents) => {
    return axios.post(`${PlatformApi}/tasks/supervisor_taskers`, {
        pageNumber: 0
    }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        console.log("students: ", res)
        setStudents(res.data.data)
    })
        .catch(err => {
            console.log("err students: ", err)
            return err
        })
}


const confirmVolunteeringHours = (taskId,intervalIndex) => {
    console.log("confirmVolunteeringHours")
    return axios.post(`${PlatformApi}/volunteering/confirm_volunteering_hours`, {
        task_id: taskId,
        intervalIndex: intervalIndex
    }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        window.location.reload();
        return { ...res, ok: true }
    }).catch(err => {
        return { ...err, ok: false }
    })
}

const unconfirmVolunteeringHours = (taskId) => {
    console.log("unconfirmVolunteeringHours")
    return axios.post(`${PlatformApi}/volunteering/confirm_volunteering_hours`, {
        task_id: taskId,
        confirm_hours: 0
    }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        window.location.reload();
        console.log({ ...res, ok: true })
        return { ...res, ok: true }
    }).catch(err => {
        console.log({ ...err, ok: false })
        return { ...err, ok: false }
    })
}

const editProfile = (values) => {
    return axios.post(`${PlatformApi}/volunteering/edit_profile`, {
        ...values
    }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        window.location.reload();
        console.log({ ...res, ok: true })
        return { ...res, ok: true }
    }).catch(err => {
        console.log({ ...err, ok: false })
        return { ...err, ok: false }
    })
}

const taskAccept = (taskId) => {
    return axios.post(`${PlatformApi}/task/accept`, {
        taskId
    }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        // window.location.href = window.location.href;
        console.log({ ...res, ok: true })
        return { ...res, ok: true }
    }).catch(err => {
        console.log({ ...err, ok: false })
        return { ...err, ok: false }
    })
}

const taskDecline = (taskId) => {
    return axios.post(`${PlatformApi}/task/decline`, {
        taskId
    }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        window.location.reload();
        console.log({ ...res, ok: true })
        return { ...res, ok: true }
    }).catch(err => {
        console.log({ ...err, ok: false })
        return { ...err, ok: false }
    })
}


function getTask(setTask, taskId) {
    axios.post(`${PlatformApi}/task/task_details`, { taskId }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        setTask(res.data.data)
        console.log("data: ", res)
    }).catch(err => {
        console.log('some error happened', err)
    })
}


function acceptTasker(taskId) {
    axios.post(`${PlatformApi}/task/accept`, { taskId }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        window.location.reload()
    }).catch(err => {
        console.log('some error happened', err)
    })
}

function finishTask(taskId) {
    axios.post(`${PlatformApi}/task/finish_task`, { taskId }, {
        headers: {
            "X-AUTH-TOKEN": localStorage.getItem("api_token")
        }
    }).then(res => {
        console.log("Response: ", res)
         window.location.reload()
    }).catch(err => {
        console.log('some error happened', err)
    })
}
const notifications = [
    {
        title: "Jhon Doe has accepted the volunteering task that your school posted",
        date: '12/09/2021',
        task: {
            title: 'Babysitting',
            description: 'Take care of our child',
            startDate: 'January 19th 2021',
            endDate: 'January 20th 2021',
            startTime: '21:00',
            endTime: '10:00am',
            daysRepeat: 'M, T, W'
        }
    },
    {
        title: "Valentin Nuñez has accepted the volunteering task that your school posted",
        date: '12/09/2021',
        task: {
            title: 'Babysitting',
            description: 'Take care of our child',
            startDate: 'January 19th 2021',
            endDate: 'January 20th 2021',
            startTime: '21:00',
            endTime: '10:00am',
            daysRepeat: 'M, T, W'
        }
    },
    {
        title: "Sariah has accepted the volunteering task that your school posted",
        date: '12/09/2021',
        task: {
            title: 'Babysitting',
            description: 'Take care of our child',
            startDate: 'January 19th 2021',
            endDate: 'January 20th 2021',
            startTime: '21:00',
            endTime: '10:00am',
            daysRepeat: 'M, T, W'
        }
    }
]

const students = [
    {
        id: 1,
        name: 'Jhon Doe',
        grade: "10",
        volunteerTasks: 22,
        volunteerHours: 187,
    },
    {
        id: 2,
        name: 'Valentin Nuñez',
        grade: "11",
        volunteerTasks: 45,
        volunteerHours: 190,
    },
    {
        id: 3,
        name: 'Sariah Doorman',
        grade: "9",
        volunteerTasks: 20,
        volunteerHours: 190,
    },
    {
        id: 4,
        name: 'Michael Linn',
        grade: "12",
        volunteerTasks: 22,
        volunteerHours: 190,
    },
    {
        id: 5,
        name: 'Santiago Sanchez',
        grade: "12",
        volunteerTasks: 24,
        volunteerHours: 130,
    },
    {
        id: 6,
        name: 'Jhon Doe',
        grade: "10",
        volunteerTasks: 29,
        volunteerHours: 147,
    },

]

export {
    isLoggedIn,
    setToken,
    deleteToken,
    errors,
    students,
    notifications,
    supervisorTasks,
    getNotifications,
    getSupervisorTaskers,
    confirmVolunteeringHours,
    unconfirmVolunteeringHours,
    editProfile,
    taskAccept,
    taskDecline,
    getTask,
    acceptTasker,
    finishTask
}