import axios from 'axios';
import React, {useEffect, useState} from 'react';
import { AiOutlineMail } from "react-icons/ai";

import { Bar } from 'react-chartjs-2';
import {api} from "../../api";



const Admin = () => {

    const [usersDayData, setUsersDayData] = useState({});
    const [usersMonthData, setUsersMonthData] = useState({});

    const [contactsDayData, setContactsDayData] = useState({});
    const [contactsMonthData, setContactsMonthData] = useState({});

    useEffect(() => {
        axios.get(api + '/graphs/users/day')
            .then(function (res) {
                setUsersDayData(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(api + '/graphs/users/month')
            .then(function (res) {
                setUsersMonthData(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });


        axios.get(api + '/graphs/contacts/day')
            .then(function (res) {
                setContactsDayData(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        axios.get(api + '/graphs/contacts/month')
            .then(function (res) {
                setContactsMonthData(res.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
   
    function backgroundBlue(e) {
        e.target.style.background = '#1688dc';
        e.target.style.color = 'white';
    }

    function backgroundWhite(e) {
        e.target.style.color = 'black';
        e.target.style.background = 'white';
    }

    function goToMail(e) {
        window.location.href = '/admin/mail';
    }



    if(localStorage.getItem('login') && localStorage.getItem('admin')){
        return (
            <>



            <div className="login-section admin-map" style={{display: 'flex'}}>
                <div className="admin-item admin-mail" onMouseEnter={backgroundBlue} onMouseLeave={backgroundWhite} onClick={goToMail}>
                    <AiOutlineMail />
                </div>
            </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"float-right mr-5"}>
                            <a href={api + "/graphs/download/contacts"} target={"_blank"} rel="noreferrer">
                            <button type={"button"} className={"btn btn-primary"}>Download sign ups</button>
                            </a>
                        </div>
                        <h2 className={"pl-4 mt-5"}>Statistics</h2>

                    </div>
                </div>


                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"admin-chart"}>
                            <h5>Sign ups/day</h5>
                            <Bar
                                data={{
                                    labels: contactsDayData.labels,

                                    datasets: [{

                                        label: '# of sign ups',
                                        data: contactsDayData.values,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)'
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)'
                                        ],
                                        borderWidth: 1
                                    }]
                                }}
                                width={100}
                                height={100}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                    } }}
                            />
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"admin-chart"}>
                            <h5>Sign ups/month</h5>
                            <Bar
                                data={{
                                    labels: contactsMonthData.labels,
                                    datasets: [{
                                        label: '# of sign ups',
                                        data: contactsMonthData.values,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)'
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)'
                                        ],
                                        borderWidth: 1
                                    }]
                                }}
                                width={100}
                                height={100}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                    } }}
                            />
                        </div>
                    </div>
                </div>

                <div className={"row mt-5"}>
                    <div className={"col-12 col-md-6"}>
                        <div className={"admin-chart"}>
                            <h5>User accounts/day</h5>
                            <Bar
                                data={{
                                    labels: usersDayData.labels,
                                    datasets: [{
                                        label: '# of user accounts',
                                        data: usersDayData.values,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)'
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)'
                                        ],
                                        borderWidth: 1
                                    }]
                                }}
                                width={100}
                                height={100}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                    } }}
                            />
                        </div>
                    </div>
                    <div className={"col-12 col-md-6"}>
                        <div className={"admin-chart"}>
                            <h5>User accounts/month</h5>
                            <Bar
                                data={{
                                    labels: usersMonthData.labels,
                                    datasets: [{
                                        label: '# of user accounts',
                                        data: usersMonthData.values,
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.2)',
                                            'rgba(54, 162, 235, 0.2)',
                                            'rgba(255, 206, 86, 0.2)',
                                            'rgba(75, 192, 192, 0.2)',
                                            'rgba(153, 102, 255, 0.2)',
                                            'rgba(255, 159, 64, 0.2)'
                                        ],
                                        borderColor: [
                                            'rgba(255, 99, 132, 1)',
                                            'rgba(54, 162, 235, 1)',
                                            'rgba(255, 206, 86, 1)',
                                            'rgba(75, 192, 192, 1)',
                                            'rgba(153, 102, 255, 1)',
                                            'rgba(255, 159, 64, 1)'
                                        ],
                                        borderWidth: 1
                                    }]
                                }}
                                width={100}
                                height={100}
                                options={{
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true
                                            }
                                        }]
                                    } }}
                            />
                        </div>
                    </div>
                </div>

            </>
        )
    } else {
        return (
            <div className="container login-section not-found-section">
              <h1>Page Not Found</h1>
            </div>
        )
    }

    // return (
    //     <div className="login-section admin-map" style={{display: 'flex'}}>
    //         <div className="admin-item admin-mail" onMouseEnter={backgroundBlue} onMouseLeave={backgroundWhite} onClick={goToMail}>
    //             <AiOutlineMail />
    //         </div>

    //     </div>
        
    // )
}

export default Admin
